import React, {useEffect, useState}                     from "react";
import {SubmitHandler, useForm}                         from "react-hook-form";
import {useTranslation}                                 from "react-i18next";
import {WrongPhoneNumberInApp}                          from "components/WrongPhoneNumberInApp";
import {Props}                                          from "components/SSNRegistration";
import {CloseRegistrationInApp}                         from "components/CloseRegistrationInApp";
import {TextBlock, BaseInput, InputPhone, InApp}        from "shared/ui";
import {Button}                                         from "shared/v12ui";
import {useAppSelector}                                 from "shared/model";
import {selectMethodType, selectRegistrationInfo}       from "pages/registration/model/registration";
import {RegistrationMethodType, useSendEgovSmsMutation} from "services/registration";
import {SsnExistInApp}                                  from "../SsnExistInApp";
import {NewPageLayout}                                  from "entities/general";
import styles                                           from "./styles.module.scss";


interface Inputs {
  ssn: string;
  phone: string;
}

export default function SSNRegistration({
  text,
  navSubtitle,
  onConfirmClick = () => {
  },
  onSetData,
  hasTryAgain = false,
}: Props) {
  const {t} = useTranslation();
  const methodType = useAppSelector(selectMethodType);
  const registrationInfo = useAppSelector(selectRegistrationInfo);

  const [sendSmsEgov, {data, isSuccess, isLoading}] = useSendEgovSmsMutation();
  const [wrongNumberInApp, setWrongNumberInApp] = useState(false);
  const [ssnExistInApp, setSsnExistInApp] = useState(false);
  const [ssnBlockedInApp, setSsnBlockedInApp] = useState(false);

  const {register, watch, setError, trigger, formState: {errors, isValid}} = useForm<Inputs>({
    defaultValues: {
      ssn:   registrationInfo?.ssn ?? "",
      phone: registrationInfo?.phone?.slice(2, registrationInfo?.phone?.length) ?? "",
    },
  });

  const onSubmit: SubmitHandler<Inputs> = (data) => sendSmsEgov({ssn: data.ssn, phone: data.phone});

  const onHandleSubmit = () => {
    const data = {
      ssn:   watch("ssn"),
      phone: watch("phone").replace(/\D/g, ""),
    };
    onSubmit(data);
  }

  const handleCancel = () => {
    setSsnBlockedInApp(false);
  }

  useEffect(() => {
    if (hasTryAgain) {
      onHandleSubmit();
      onConfirmClick();
    }
  }, [hasTryAgain]);

  useEffect(() => {
    if (isSuccess && data && data.content) {
      onSetData && onSetData(data?.content.request_id);
      onConfirmClick();
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (data && data.errors) {
      if (data.errors.profile === "another_phone") {
        return setWrongNumberInApp(true);
      }
      if (data.errors.profile === "ssn_exists") {
        return setSsnExistInApp(true);
      }
      if (data.errors.profile === "ssn_blocked") {
        return setSsnBlockedInApp(true);
      }
    }
    if (data?.errors) {
      for (const [fieldName, message] of Object.entries(data.errors)) {
        setError(fieldName as any, {
          type: "server",
          message,
        });
      }
    }
  }, [data?.errors]);

  useEffect(() => {
    if (methodType !== RegistrationMethodType.VERIFICATION) return;
    if (registrationInfo?.phone && registrationInfo.phone) {
      trigger("ssn");
      trigger("phone");
    }
  }, [methodType, registrationInfo]);

  return <>
    <NewPageLayout
      headerTitle={methodType === RegistrationMethodType.VERIFICATION ? t("page.verification.header") : t("page.registration.header")}
      headerSubtitle={navSubtitle}
      headerRightIcon={<CloseRegistrationInApp />}
      footer={<Button
        text={t("common.next")}
        disabled={!isValid}
        loading={isLoading}
        onClick={onHandleSubmit}
      />}
    >
      <div className={styles.content}>
        <BaseInput
          label={t("page.registration.egov.form.ssn_label")}
          maxLength={12}
          max={12}
          type="tel"
          error={!!errors.ssn}
          labelIcon="asterix"
          disabled={methodType === RegistrationMethodType.VERIFICATION}
          placeholder={t("page.registration.egov.form.ssn_placeholder")}
          subtext={errors.ssn ? errors.ssn.message : t("page.registration.egov.form.ssn_subtext")}
          {...register("ssn", {required: true, minLength: 12, maxLength: 12})}
        />

        <InputPhone
          label={t("page.registration.egov.form.phone_label")}
          labelIcon="asterix"
          icon={<div className={styles.phone_prefix + " mgr-2"}>
            <img src={"/images/country_kz_2.svg"} alt="" />
            <div>{"+7"}</div>
          </div>}
          placeholder="000 000-00-00"
          mask={"000 000-00-00"}
          error={!!errors.phone}
          subtext={errors.phone && errors.phone.message}
          type="tel"
          {...register("phone", {
            required:  true,
            minLength: methodType === RegistrationMethodType.VERIFICATION ? undefined : 13,
          })}
        />

        <TextBlock description={text} />
      </div>
    </NewPageLayout>

    <WrongPhoneNumberInApp open={wrongNumberInApp} onClose={() => setWrongNumberInApp(false)} />

    <SsnExistInApp
      ssn={watch("ssn")}
      open={ssnExistInApp}
      onClose={() => setSsnExistInApp(false)}
    />

    <InApp
      open={ssnBlockedInApp}
      image={"/illustrations/no-conversation-3.svg"}
      headerText={t("in_app.ssn_blocked.title")}
      headerSubtext={t("in_app.ssn_blocked.description")}
      onClose={handleCancel}
    >
      <Button hasSpace text={t("in_app.ssn_blocked.button")} onClick={handleCancel} />
    </InApp>
  </>
}