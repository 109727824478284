import "shared/config/i18n";

import React                     from "react";
import ReactDOM                  from "react-dom/client";
import App                       from "./App";
import {BrowserRouter as Router} from "react-router-dom";
import {Provider}                from "react-redux";
import {store}                   from "../store/store";
import "../styles/index.scss";
import ResizeObserver            from "resize-observer-polyfill";
import {AbortController}         from "abort-controller";
import "intl-pluralrules";
import "core-js/stable";
import "regenerator-runtime/runtime";
import "whatwg-fetch";
import "core-js/web/url";
import "core-js/web/url-search-params";
import "shared/config/sentry";


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

if (!window.ResizeObserver) {
  window.ResizeObserver = ResizeObserver;
}

if (!window.AbortController) {
  //@ts-ignore
  window.AbortController = AbortController;
}

root.render(
  <React.StrictMode>
    <Router>
      <Provider store={store}>
        <App />
      </Provider>
    </Router>
  </React.StrictMode>,
);
