import React                                                              from "react";
import {nativeMethod, setActionForNative, useNativeHandler}               from "shared/lib";
import {NativeActions, selectAppPlatform, useAppDispatch, useAppSelector} from "shared/model";
import {
  selectCameraPermission,
  selectGalleryPermission,
  selectGeolocationPermission,
  selectPushPermission,
  setCameraPermission,
  setGalleryPermission,
  setGeolocationPermission,
  setPushPermission,
}                                                                         from "shared/native/permissions";


export default function useNativePermission() {
  const dispatch = useAppDispatch();
  const cameraPermission = useAppSelector(selectCameraPermission);
  const galleryPermission = useAppSelector(selectGalleryPermission);
  const geolocationPermission = useAppSelector(selectGeolocationPermission);
  const pushPermission = useAppSelector(selectPushPermission);
  const platform = useAppSelector(selectAppPlatform);

  React.useEffect(() => {
    if (cameraPermission === null) {
      if (platform === "android") {
        nativeMethod().checkCameraPermission().then((res) => {
          dispatch(setCameraPermission(res.has_permissions));
        }).catch((err) => console.warn(err));
      } else {
        setActionForNative(NativeActions.HAS_PERMISSIONS_CAMERA);
      }
    }
  }, [cameraPermission, platform]);

  React.useEffect(() => {
    if (galleryPermission === null) {
      if (platform === "android") {
        nativeMethod().checkGalleryPermission().then((res) => {
          dispatch(setGalleryPermission(res.has_permissions));
        }).catch((err) => console.warn(err))
      } else {
        setActionForNative(NativeActions.HAS_PERMISSIONS_GALLERY);
      }
    }
  }, [galleryPermission, platform]);

  React.useEffect(() => {
    if (geolocationPermission === null) {
      if (platform === "android") {
        nativeMethod().checkGeoPermission().then((res) => {
          dispatch(setGeolocationPermission(res.has_permissions));
        }).catch((err) => console.warn(err));
      } else {
        setActionForNative(NativeActions.HAS_PERMISSIONS_GEO);
      }
    }
  }, [geolocationPermission, platform]);

  React.useEffect(() => {
    if (pushPermission === null) {
      if (platform === "android") {
        nativeMethod().checkPushPermission().then((res) => {
          dispatch(setPushPermission(res.has_permissions));
        }).catch((err) => console.warn(err))
      } else {
        setActionForNative(NativeActions.HAS_PERMISSIONS_PUSH);
      }
    }
  }, [pushPermission, platform]);

  useNativeHandler<{ has_permissions: boolean }>(null, NativeActions.HAS_PERMISSIONS_CAMERA, data => {
    if (data) {
      return dispatch(setCameraPermission(data.has_permissions));
    }
  }, platform === "android");

  useNativeHandler<{ has_permissions: boolean }>(null, NativeActions.HAS_PERMISSIONS_GALLERY, data => {
    if (data) {
      return dispatch(setGalleryPermission(data.has_permissions));
    }
  }, platform === "android");

  useNativeHandler<{ has_permissions: boolean }>(null, NativeActions.HAS_PERMISSIONS_GEO, data => {
    if (data) {
      return dispatch(setGeolocationPermission(data.has_permissions));
    }
  }, platform === "android");

  useNativeHandler<{ has_permissions: boolean }>(null, NativeActions.HAS_PERMISSIONS_PUSH, (data) => {
    if (data) {
      return dispatch(setPushPermission(data.has_permissions));
    }
  }, platform === "android");
};