import {api, BaseResponse} from "shared/api/api";
import {CategoryListItem}  from "entities/categoryListItem/model/categoryListItem";
import {MainPageTabType}   from "../../../shared/model/types/main/mainPageType";


interface CategoryItem {
  service: {
    items: CategoryListItem[];
    has_more: boolean;
    title: string;
    digest: string;
  };
  ad_disabled: boolean;
}

const categoriesApi = api.injectEndpoints({
  endpoints: (build) => ({
    fetchCategories: build.mutation<CategoryItem | undefined, {
      page: number,
      category_id: number,
      tab: MainPageTabType
    }>({
      query:             ({...params}) => ({
        url: "app/main/service",
        method: "PUT",
        body: params,
      }),
      transformResponse: (response: BaseResponse<CategoryItem>) => response ? response.content : undefined,
    }),
  }),
});

export const {useFetchCategoriesMutation} = categoriesApi;
