import React                         from "react";
import {useNavigate}                 from "react-router-dom";
import {InApp}                       from "shared/ui";
import {Button}                      from "shared/v12ui";
import {useAppDispatch}              from "shared/model";
import {setRegistrationType, setSsn} from "pages/registration/model/registration";
import {useTranslation}              from "react-i18next";
import {RegistrationType}            from "services/registration";


interface Props {
  open: boolean;
  onClose: () => void;
  ssn: string;
}

export default function SsnExistInApp({open, ssn, onClose}: Props) {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleTransferProfile = () => {
    dispatch(setSsn(ssn));
    navigate("/transfer");
    dispatch(setRegistrationType(RegistrationType.TRANSFER));
    onClose();
  }

  return <InApp
    image="/illustrations/searching-6.svg"
    headerText={t("in_app.ssn_exist.title")}
    headerSubtext={t("in_app.ssn_exist.description")}
    open={open}
    onClose={onClose}
  >
    <Button
      hasSpace
      text={t("in_app.ssn_exist.button")}
      onClick={handleTransferProfile}
    />
  </InApp>
}