import React            from "react";
import {Icon}           from "../../v12ui";
import {FaqItem}        from "./model/faqItem";
import {SkeletonLayout} from "../../../entities/skeleton";
import cn               from "classnames";
import styles           from "./NewAccordion.module.scss";


interface Props {
  faq?: FaqItem[];
  isLoading?: boolean;
}

export default function NewAccordion({faq, isLoading}: Props) {
  const contentRef = React.useRef<HTMLDivElement | null>(null);

  const [state, setState] = React.useState<number>();
  const [contentHeight, setContentHeight] = React.useState("");

  const handleClickTitle = (event: React.MouseEvent<HTMLLIElement>, value: number) => {
    const contentBlock = Array.from(event.currentTarget.children).find((child) => child.tagName === "DIV") as HTMLDivElement | undefined;


    if (state === value) {
      setState(undefined);
      setContentHeight("0px");
    } else {
      setState(value)
      setContentHeight(`${contentBlock?.scrollHeight}px`);
    }
  };

  return <>
    {isLoading
      ? <SkeletonLayout borderRadius={16} height={300} />

      : <ul className={styles.accordion_wrapper}>
        {faq?.map((item, index) => {
          return <li key={index} onClick={(event) => handleClickTitle(event, index)} className={styles.title_holder}>
            <span className={styles.title}>{item.title}</span>
            <Icon
              className={cn(styles.toggle_button, {[styles.toggle_button_open]: state === index})}>keyboard_arrow_down</Icon>

            <div
              ref={contentRef}
              className={cn(styles.content)}
              style={{maxHeight: state === index ? contentHeight : "0px"}}
            >
              {item.text}
            </div>
          </li>
        })}
      </ul>}
  </>
};