import { LinkData } from "shared/native/generateLink/generateNativeLink";
import { LabelItem } from "../../label/model/labelItemType";

export enum PanelItemType {
  Order = "order",
  Bids = "bids",
  Create_Order = "create_order",
  Create_Vacancy = "create_vacancy",
  Create_Guarantee = "create_guarantee",
  Pay_Guarantee = "pay",
  Stories = "stories",
  Vacancy = "vacancy",
  SpecialistsList = "specialistsList",
  Guarantee = "guarantee",
}

export interface ActionPanelItem {
  type: PanelItemType;
  title?: string;
  subtitle?: string;
  digest?: string;
  data?: LinkData;
  icon?: string;
  labels?: LabelItem[];
  stories?: {
    id: number;
    title: string;
    image: string;
    is_viewed: boolean;
    stories: {
      id: number;
      image: string;
      is_viewed: boolean;
    }[];
  }[];
}