import React, {useEffect, useState}                              from "react";
import {useNavigate}                                             from "react-router-dom";
import {useTranslation}                                          from "react-i18next";
import {useGetSubscribeGiftMutation, useUnsubscribeInitMutation} from "services/specialists/tariffs";
import {InApp}                                                   from "shared/ui";
import {Button, NewButtonColor, NewButtonType}                   from "shared/v12ui";
import {StatePageLayout}                                         from "entities/general";
import {
  NativeActions,
  useAppDispatch,
  useAppSelector,
  addNotification,
  selectAutoRenewalCancelInfo,
  selectPurchaseIsLoading,
}                                                                from "shared/model";
import {useNativeHandler, setActionForNative}                    from "shared/lib";
import styles                                                    from "./styles.module.scss";


const i18n_prefix = "page.state.auto_renewal_cancel.";
const AutoRenewalCancel = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const purchaseInProgress = useAppSelector(selectPurchaseIsLoading);

  const cancelRenewalInfo = useAppSelector(selectAutoRenewalCancelInfo);

  const [getSubscribeGift, {
    isLoading: getSubscribeGiftIsLoading,
    isSuccess: getSubscribeGiftIsSuccess,
  }] = useGetSubscribeGiftMutation();
  const [unsubscribeInit, {
    isLoading: unsubscribeInitIsLoading,
    isSuccess: unsubscribeInitIsSuccess,
  }] = useUnsubscribeInitMutation();

  const [openGiftInApp, setOpenGiftInApp] = useState(false);
  const handleCancelClick = () => {
    setActionForNative(NativeActions.ANALYTICS, {
      event:      "specialist other",
      properties: {
        "autorenewal": "nazhal net, ya peredumal",
      },
    });
    navigate("/tariffs");
  }

  const handleBackClick = () => navigate("/tariffs");

  const renderList = cancelRenewalInfo?.list?.map((item, index) => ({
    id:        index,
    icon:      "cancel",
    iconColor: "#FF1507",
    text:      item,
  }));

  const handleOpenInApp = () => {
    setOpenGiftInApp(true);
  }

  const handleCancelAutoRenewalClick = () => {
    setActionForNative(NativeActions.ANALYTICS, {
      event:      "specialist other",
      properties: {
        "autorenewal": "nazhal otmenit avtoprodlenie",
      },
    });

    if (cancelRenewalInfo?.has_gift) {
      return handleOpenInApp();
    }
    if (cancelRenewalInfo?.id) {
      return unsubscribeInit(cancelRenewalInfo?.id);
    }
  }

  const handleGetGiftClick = () => {
    if (cancelRenewalInfo?.id) {
      getSubscribeGift(cancelRenewalInfo?.id);
    }
  }

  const handleUnsubscribeInAppClick = () => {
    if (cancelRenewalInfo?.id) {
      unsubscribeInit(cancelRenewalInfo?.id);
    }
  }

  useEffect(() => {
    if (getSubscribeGiftIsSuccess) {
      navigate("/tariffs");
    }
  }, [getSubscribeGiftIsSuccess, navigate]);

  useEffect(() => {
    if (unsubscribeInitIsSuccess) {
      navigate("/tariffs");
      dispatch(addNotification({icon: "cancel", text: t(`${i18n_prefix}notification`)}));
    }
  }, [unsubscribeInitIsSuccess, navigate, dispatch, t]);

  useEffect(() => {
    setActionForNative(NativeActions.ANALYTICS, {
      event:      "specialist other",
      properties: {
        "autorenewal": "otmena avtoprodlenia otkril",
      },
    });
  }, []);

  useNativeHandler(null, NativeActions.BACK_TAP, () => {
    if (purchaseInProgress) return;
    if (openGiftInApp) {
      return setOpenGiftInApp(false);
    }
    return navigate("/tariffs");
  });

  return <StatePageLayout
    title={t(`${i18n_prefix}header`)}
    img="/illustrations/no-conversation-3.svg"
    list={renderList ?? []}
    onClose={handleBackClick}
  >
    <div className={styles.buttons_holder}>
      <Button
        text={t(`${i18n_prefix}buttons.cancel`)}
        buttonType={NewButtonType.WRAPPED}
        buttonColor={NewButtonColor.GRAY}
        onClick={handleCancelClick}
        disabled={unsubscribeInitIsLoading}
      />

      <Button
        text={t(`${i18n_prefix}buttons.submit`)}
        buttonColor={NewButtonColor.ERROR}
        onClick={handleCancelAutoRenewalClick}
        loading={unsubscribeInitIsLoading}
      />
    </div>

    <InApp
      image="/illustrations/discount-coupons-2.svg"
      headerText={t(`${i18n_prefix}confirm_cancel.title`)}
      headerSubtext={cancelRenewalInfo?.gift_text ?? ""}
      open={openGiftInApp}
      onClose={() => setOpenGiftInApp(false)}
    >
      <div className={styles.buttons_holder}>
        <Button
          buttonType={NewButtonType.WRAPPED}
          buttonColor={NewButtonColor.GRAY}
          disabled={getSubscribeGiftIsLoading}
          loading={unsubscribeInitIsLoading}
          text={t(`${i18n_prefix}confirm_cancel.buttons.submit`)}
          onClick={handleUnsubscribeInAppClick}
        />

        <Button
          hasSpace
          onClick={handleGetGiftClick}
          disabled={unsubscribeInitIsLoading}
          loading={getSubscribeGiftIsLoading}
          text={t(`${i18n_prefix}confirm_cancel.buttons.cancel`)}
        />
      </div>
    </InApp>
  </StatePageLayout>
};

export default AutoRenewalCancel;