import React, {useEffect, useState}                          from "react";
import {useParams}                                           from "react-router-dom";
import {ReviewFilterTypes, ReviewsOverview, ReviewsListItem} from "services/reviews/types";
import {useGetClientReviewsMutation}                         from "services/reviews";
import {ReviewsList}                                         from "entities/customers/reviewsList";
import {useSetHeaders, useSkeletonThrottling}                from "shared/lib";


export default function ClientReviews() {
  const {id: paramId} = useParams();
  const id = paramId ? parseInt(paramId) : null;

  const [filters, setFilters] = useState<ReviewFilterTypes>(ReviewFilterTypes.DATE_DESC);
  const [page, setPage] = useState(1);

  const [reviewsList, setReviewsList] = useState<ReviewsListItem[]>([]);
  const [overviews, setOverviews] = useState<ReviewsOverview>();

  const [getSpecialistReviews, {data, isLoading}] = useGetClientReviewsMutation();

  const {extendedLoading} = useSkeletonThrottling({isLoading});
  const {isSuccess} = useSetHeaders();

  useEffect(() => {
    if (!isSuccess) return;
    if (!id) return;
    getSpecialistReviews({client_id: id, page, filter: filters});
  }, [id, filters, page, getSpecialistReviews, isSuccess]);

  useEffect(() => {
    if (!data?.reviews_overview) return;
    if (data?.reviews_overview) {
      setOverviews(data.reviews_overview);
    }
  }, [data?.reviews_overview]);

  useEffect(() => {
    if (data?.reviews) {
      setReviewsList(prev => ([...prev, ...data?.reviews]));
    }
  }, [data?.reviews, data?.has_more]);

  useEffect(() => {
    if (filters) {
      setReviewsList([]);
    }
  }, [filters]);

  return <ReviewsList
    mode="client"
    id={id}
    page={page}
    reviews={reviewsList}
    overview={overviews}
    isLoading={extendedLoading}
    onSetPage={setPage}
    filters={filters}
    setFilters={setFilters}
  />
}