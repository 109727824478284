import {api, BaseResponse, RequestMethod} from "shared/api/api";
import {Nullable}                         from "shared/model";


interface FreezeTariffInfo {
  purchase_id: number,
  days: number,
  method: string,
  with_bonus: boolean,
  card_id: Nullable<number>,
}

interface TariffFreezeInfo {
  info: {
    card_id: number;
    days: number;
    days_left: number;
    freeze_at: string;
    method: string;
    price: Nullable<number>;
    with_bonus: boolean;
  }
}

const tariffsPaymentCardApi = api.injectEndpoints({
  endpoints: (build) => ({
    fetchFreezeTariffInfo: build.mutation<BaseResponse<TariffFreezeInfo>, number>({
      query: (id) => ({
        url:    "pro/balance/purchase/freeze/info",
        method: RequestMethod.PUT,
        body:   {purchase_id: id},
      }),
    }),

    freezeTariff:   build.mutation<BaseResponse<{}>, FreezeTariffInfo>({
      query: ({...params}) => ({
        url:    "pro/balance/purchase/freeze",
        method: RequestMethod.PUT,
        body:   params,
      }),
    }),

    unfreezeTariff: build.mutation<BaseResponse<{}>, number>({
      query: (id) => ({
        url:    "pro/balance/purchase/unfreeze",
        method: RequestMethod.PUT,
        body:   {purchase_id: id},
      }),
    }),

    freezeTariffInit:        build.mutation<BaseResponse<{ payment_id: string }>, { id: number, days: number }>({
      query: ({...params}) => ({
        url:    "pro/balance/purchase/freeze/init",
        method: RequestMethod.PUT,
        body:   params,
      }),
    }),
  }),
});

export const {
  useFetchFreezeTariffInfoMutation,
  useFreezeTariffMutation,
  useUnfreezeTariffMutation,
  useFreezeTariffInitMutation,
} = tariffsPaymentCardApi;