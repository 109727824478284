import React                                 from "react";
import {Icon, NewButtonColor, NewButtonType} from "shared/v12ui";
import {TabVariant}                          from "./tabVariant";
import cn                                    from "classnames";
import styles                                from "./styles.module.scss";
import ButtonLoading                         from "../../v12ui/button/ui/ButtonLoading";


interface Props {
  children?: React.ReactNode;
  activeTab: boolean;
  icon?: string | React.ReactNode;
  rightIcon?: string | React.ReactNode;
  onClick: () => void;
  variant?: TabVariant,
  isLoading?: boolean,
}

const ICON_SIZE = 16;
export default function ElementTabs({
  children,
  onClick,
  activeTab,
  icon,
  rightIcon,
  variant = TabVariant.PRIMARY,
  isLoading = true,
}: Props) {

  return <div
    className={cn(styles.tab,
      {[styles.tab_active]: activeTab},
      {[styles.secondary]: variant === TabVariant.SECONDARY},
      {[styles.is_loading]: isLoading}
    )}
    onClick={onClick}
  >
    {isLoading && <ButtonLoading buttonType={NewButtonType.DEFAULT} buttonColor={NewButtonColor.GRAY} />}
    <div className={cn(styles.text, {[styles.text_active]: activeTab}, {[styles.is_loading]: isLoading})}>
      {icon && <Icon className={children ? "mgr-2" : ""} size={ICON_SIZE}>{icon}</Icon>}
      {children}
      {rightIcon && <Icon className="mgl-2" size={ICON_SIZE}>{rightIcon}</Icon>}
    </div>
  </div>
}