import * as Sentry from '@sentry/react';

if (window.location.hostname !== 'localhost') {
  Sentry.init({
    environment: process.env.REACT_APP_ENVIRONMENT,
    dsn: 'https://3b3d73ef8d7f5d2a06f913c636d2e865@o4508674445410304.ingest.de.sentry.io/4508675778084944',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
