import React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { SuggestResponseItem } from '@yandex/ymaps3-types/imperative/suggest';
import { LngLat, YMapLocationRequest } from '@yandex/ymaps3-types';
import { AddressesItem, setCurrentCity, useLazyFetchCurrentCityQuery } from 'entities/order';
import { selectGeolocationPermission, toggleGeoInApp } from 'shared/native/permissions';
import { Button, Icon, Input, NewList } from 'shared/v12ui';
import { setActionForNative, useNativeHandler } from 'shared/lib';
import { NativeActions, useAppDispatch, useAppSelector } from 'shared/model';
import { formatAddressName, yaMapSearch, yaMapSuggest } from '../../model/yaMapHelpers';
import { useFetchAddressesHistoryQuery } from '../../api';
import styles from './SearchList.module.scss';
import { setOrderAddresses } from 'entities/order/model/slice/orderSlice';

interface Props {
  currentCity?: { id?: number; name?: string };
  searchInProcess: boolean;
  openSearch: boolean;
  onSetOpenSearch: React.Dispatch<React.SetStateAction<boolean>>;
  onSetAddressName: (value?: string) => void;
  suggestValue: string;
  onSetSuggestValue: React.Dispatch<React.SetStateAction<string>>;
  addresses: AddressesItem[];
  updatedIndex?: string;
  addressName?: string;
  onSetLocation: React.Dispatch<React.SetStateAction<YMapLocationRequest>>;
  onMapClose: () => void;
}

export default function SearchList({
  openSearch,
  onSetOpenSearch,
  suggestValue,
  addresses,
  onSetSuggestValue,
  addressName,
  updatedIndex,
  onSetLocation,
  onMapClose,
  searchInProcess,
  onSetAddressName,
  currentCity,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const hasGeoPermission = useAppSelector(selectGeolocationPermission);

  const { data: historyList } = useFetchAddressesHistoryQuery();
  const [getCurrentCity] = useLazyFetchCurrentCityQuery();

  const [suggests, setSuggests] = React.useState<SuggestResponseItem[]>([]);
  const [searchHeight, setSearchHeight] = React.useState<string>('calc(100% - 9.4rem - var(--sait))');

  const handleToggleSearch = () => {
    if (searchHeight === 'auto') {
      setSearchHeight('calc(100% - 9.4rem - var(--sait)');
      onSetOpenSearch(true);
    } else {
      setSearchHeight('auto');
      onSetOpenSearch(false);
    }
  };

  const handleOnOpenSearchClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    handleToggleSearch();
  };

  const handleGetGeolocationClick = () => {
    if (hasGeoPermission === false) {
      dispatch(toggleGeoInApp(true));
    }
    if (hasGeoPermission === true) {
      setActionForNative(NativeActions.GET_GEO_COORDS);
    }
  };

  const handleOnSuggestClick = (suggest: SuggestResponseItem) => {
    if (!suggest) return;
    const text = `${suggest.subtitle?.text} ${suggest.title.text}`;
    yaMapSearch({ text: text, limit: 1 })
      .then((res) => {
        onSetLocation({ center: res[0].geometry?.coordinates as LngLat, zoom: 17 });
      })
      .catch((error) => console.warn('ошибка search', error));
    handleToggleSearch();
  };

  const handleHistoryAddressClick = (coord: number[], value: string) => {
    yaMapSearch({ text: `${coord}`, limit: 1 }).then((res) => {
      const newAddresses = [
        ...addresses,
        {
          coord: res[0].geometry?.coordinates as number[],
          name: formatAddressName(value) ?? '',
          city_id: currentCity?.id,
          city_name: currentCity?.name,
          uuid: uuidv4(),
        },
      ];

      const newAddressWithUpdatedIndex = addresses.map((item) =>
        item.uuid === updatedIndex
          ? {
              ...item,
              coord: res[0].geometry?.coordinates as number[],
              name: formatAddressName(value) ?? '',
              city_id: currentCity?.id,
              city_name: currentCity?.name,
            }
          : item,
      );

      if (updatedIndex) {
        dispatch(setOrderAddresses(newAddressWithUpdatedIndex));
      } else {
        dispatch(setOrderAddresses(newAddresses));
      }
      // onSetAddress({
      //   coord:     res[0].geometry?.coordinates as number[],
      //   name:      formatAddressName(value) ?? "",
      //   city_id:   currentCity?.id,
      //   city_name: currentCity?.name,
      // });
      onMapClose();
    });
  };

  const handleSetAddressClick = () => {
    if (updatedIndex === undefined) {
      const searchValue = `${currentCity?.name} ${suggestValue}`;
      return yaMapSearch({ text: searchValue, limit: 1 }).then((res) => {
        const newAddresses = [
          ...addresses,
          {
            coord: res[0].geometry?.coordinates as number[],
            name: formatAddressName(suggestValue) ?? '',
            city_id: currentCity?.id,
            city_name: currentCity?.name,
            uuid: uuidv4(),
          },
        ];
        dispatch(setOrderAddresses(newAddresses));

        // onSetAddress({
        //   coord:     res[0].geometry?.coordinates as number[],
        //   name:      formatAddressName(suggestValue) ?? "",
        //   city_id:   currentCity?.id,
        //   city_name: currentCity?.name,
        // });
        onMapClose();
      });
    } else {
      const currentIndex = addresses?.findIndex((item) => item.uuid === updatedIndex);
      if (currentIndex !== -1) {
        const searchValue = `${currentCity?.name} ${suggestValue}`;
        return yaMapSearch({ text: searchValue, limit: 1 }).then((res) => {
          const newAddresses = addresses.map((item, index) =>
            currentIndex === index
              ? {
                  ...item,
                  coord: res[0].geometry?.coordinates as number[],
                  name: formatAddressName(suggestValue) ?? '',
                  city_id: currentCity?.id,
                  city_name: currentCity?.name,
                }
              : item,
          );
          dispatch(setOrderAddresses(newAddresses));

          // onUpdateAddress(currentIndex, {
          //   coord:     res[0].geometry?.coordinates as number[],
          //   name:      formatAddressName(suggestValue) ?? "",
          //   city_id:   currentCity?.id,
          //   city_name: currentCity?.name,
          // });
          onMapClose();
        });
      }
    }
  };

  const handleClearClick = () => {
    onSetAddressName(undefined);
    const newAddresses = addresses.map((item) => (item.uuid === updatedIndex ? { ...item, name: '' } : item));
    dispatch(setOrderAddresses(newAddresses));
  };

  const handleSearchPlace = (event: React.ChangeEvent<HTMLInputElement>) => {
    yaMapSuggest({ text: `город ${currentCity?.name} ${event.target.value}`, types: ['house'] })
      .then((response) => {
        setSuggests(response);
      })
      .catch((error) => console.warn('ошибка suggest', error));
    onSetSuggestValue(event.target.value);
  };

  const handleSetGeolocation = async (data: { longitude: number; latitude: number }) => {
    if ((!data.latitude && !data.latitude) || !data) return;
    setSearchHeight('auto');
    onSetOpenSearch(false);
    const currentGeo = [data.longitude, data.latitude];

    getCurrentCity({ longitude: data.longitude, latitude: data.latitude });

    try {
      const [cityData, searchResults] = await Promise.all([
        getCurrentCity({ longitude: data.longitude, latitude: data.latitude }),
        yaMapSearch({ text: `${currentGeo}`, limit: 1 }),
      ]);

      const city = cityData.data?.city;

      const coordinates = searchResults[0]?.geometry?.coordinates as LngLat;
      if (coordinates) {
        onSetLocation({ center: coordinates, zoom: 17 });
      }

      if (city) {
        dispatch(setCurrentCity({ id: city.id, name: city.name }));
      }
    } catch (error) {
      console.warn('ошибка search', error);
    }
  };

  React.useEffect(() => {
    if (addressName) {
      onSetSuggestValue(addressName);
      yaMapSuggest({ text: `${currentCity?.name} ${addressName}` })
        .then((response) => {
          setSuggests(response);
        })
        .catch((error) => console.warn('ошибка suggest', error));
    }
  }, [addressName]);

  React.useEffect(() => {
    if (openSearch) {
      setTimeout(() => {
        setActionForNative(NativeActions.INPUT_FOCUS, { name: 'address_name', element: 'input' });
      }, 300);
    }
  }, [openSearch]);

  useNativeHandler<{ longitude: number; latitude: number }>(null, NativeActions.GET_GEO_COORDS, (data) => {
    return handleSetGeolocation(data);
  });

  return (
    <div className={styles.search} style={{ height: searchHeight }}>
      <div className={styles.search_wrapper}>
        {!openSearch && (
          <div className={styles.geolocation_button} onClick={handleGetGeolocationClick}>
            <Icon iconColor='#828291' size={20}>
              navigation
            </Icon>
          </div>
        )}
        {!openSearch ? (
          <div className={styles.input_holder} onClick={handleOnOpenSearchClick}>
            <Input
              clearable={false}
              hasLoading={searchInProcess}
              icon='search'
              placeholder={t('widgets.map.search_placeholder')}
              value={suggestValue}
              readOnly={!openSearch}
              onChange={handleSearchPlace}
              hasSpace={false}
              subtext={
                !openSearch && !hasGeoPermission ? (
                  <div className={styles.permission_subtext}>
                    <span>{t('widgets.map.get_geo_permission')}</span>
                    <span onClick={handleGetGeolocationClick}>{t('widgets.map.resolve')}</span>
                  </div>
                ) : undefined
              }
            />
          </div>
        ) : (
          <Input
            name='address_name'
            clearable={suggestValue !== ''}
            onClearClick={handleClearClick}
            icon='search'
            placeholder={t('widgets.map.search_placeholder')}
            value={suggestValue}
            readOnly={!openSearch}
            onChange={handleSearchPlace}
            hasSpace={false}
            subtext={
              !openSearch && !hasGeoPermission ? (
                <div className={styles.permission_subtext}>
                  <span>{t('widgets.map.get_geo_permission')}</span>
                  <span onClick={handleGetGeolocationClick}>{t('widgets.map.resolve')}</span>
                </div>
              ) : undefined
            }
          />
        )}

        {openSearch && (
          <button type='button' className={styles.map_button} onClick={handleToggleSearch}>
            {t('widgets.map.map')}
          </button>
        )}
      </div>

      {!openSearch && (
        <div className={styles.select_address_holder}>
          <Button
            disabled={searchInProcess}
            type='button'
            onClick={handleSetAddressClick}
            text={t('common.select')}
            hasSpace
          />
        </div>
      )}

      {openSearch && (
        <ul className={styles.suggests_wrapper}>
          <li>
            <NewList
              hasSpace={false}
              icon='navigation'
              title={t('widgets.map.get_geo_title')}
              subtitle={t('widgets.map.get_geo_description')}
              onClick={handleGetGeolocationClick}
            />
          </li>
          {!suggestValue
            ? historyList?.addresses?.map((address, index) => {
                return (
                  <li key={index}>
                    <NewList
                      hasSpace={false}
                      icon='history'
                      title={address.address}
                      subtitle={address.city.name ? t('widgets.map.city', { city: address.city.name }) : undefined}
                      onClick={() => handleHistoryAddressClick([address.longitude, address.latitude], address.address)}
                    />
                  </li>
                );
              })
            : suggests.map((suggestItem, index) => {
                return (
                  <li key={index}>
                    <NewList
                      hasSpace={false}
                      icon='place'
                      title={formatAddressName(suggestItem.title.text) ?? ''}
                      subtitle={suggestItem.subtitle?.text ?? undefined}
                      onClick={() => handleOnSuggestClick(suggestItem)}
                    />
                  </li>
                );
              })}
        </ul>
      )}
    </div>
  );
}
