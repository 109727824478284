import React                   from "react";
import {Outlet, Route, Routes} from "react-router-dom";
import {useTranslation}        from "react-i18next";
import {OrderPageLayout}       from "entities/order";
import {OrderFirstStepPage}    from "pages/order/firstStep";
import {OrderSecondStepPage}   from "pages/order/secondStep";
import {OrderThirdStepPage}    from "pages/order/thirdStep";
import {
  Egov,
  FF,
  ManualRegistration,
  NotCitizen,
  Registration,
  TransferComplete,
  TransferProfile,
  WithDocument,
}                              from "pages/registration";
import {SpecialistReviews}     from "pages/reviews/Specialist";
import {ClientReviews}         from "pages/reviews/Client";
import {SelfReviews}           from "pages/reviews/Self";
import {PurchaseListPage}      from "pages/specialist/tariffs/PurchaseListPage";
import {PeriodsListPage}       from "pages/specialist/tariffs/PeriodsListPage";
import {TariffOptionsPage}     from "pages/specialist/tariffs/TariffOptionsPage";
import {PurchaseCatalogueList} from "pages/specialist/tariffs-catalogue/catalogueList";
import {PurchaseServicesList}  from "pages/specialist/tariffs-catalogue/servicesList";
import {AutoRenewalCancel}     from "pages/state";
import {Credits}               from "pages/specialist/сredits";
import {CreditsSchedule}       from "pages/specialist/credits-schedule";
import {CashLoans}             from "pages/specialist/loans";
import {Referral}              from "pages/customers/referral";
import {SpecialistProfile}     from "pages/specialist/profile";
import {ProfileAbout}          from "pages/specialist/profile-about";
import {PricesService}         from "pages/specialist/profile-prices-service";
import {PricesWork}            from "pages/specialist/profile-prices-work";
import {RemoveProfile}         from "pages/specialist/profile-remove";
import {ProfileMedia}          from "pages/specialist/profile-media";
import {ProfileMediaUpload}    from "pages/specialist/profile-media-upload";
import {ProfileAvatarChange}   from "pages/specialist/profile-avatar-change";
import {ClientProfile}         from "pages/client/profile";
import {SpecialistAppPrices}   from "pages/client/specialist-prices";
import {ProfilePrices}         from "pages/specialist/profile-prices";
import {MainPageLayout}        from "entities/main";
import {MainSearch}            from "pages/mainSearch";
import {ServicesPage}          from "pages/main/services";
import {VacancyPage}           from "pages/main/vacancy";
import {UltraPage}             from "pages/main/ultra";
import {CategoriesPage}        from "pages/categories";
import {MainPageTabType}       from "shared/model/types/main/mainPageType";


export interface RouteItem {
  path: string,
  element: JSX.Element,
  title?: string,
  children?: {
    path: string,
    element: JSX.Element,
  }[];
}

const routes: RouteItem[] = [
  {
    path:     "/",
    element:  <MainPageLayout />,
    children: [
      {
        path:    "",
        element: <ServicesPage />,
      },
      {
        path:    "/vacancy",
        element: <VacancyPage />,
      },
      {
        path:    "/ultra",
        element: <UltraPage />,
      },
    ],
  },
  {
    path:    "/categories-service/:id",
    element: <CategoriesPage categoryType={MainPageTabType.Service} />,
  },
  {
    path:    "/categories-vacancy/:id",
    element: <CategoriesPage categoryType={MainPageTabType.Vacancy} />,
  },
  {
    path:    "/categories-ultra/:id",
    element: <CategoriesPage categoryType={MainPageTabType.Ultra} />,
  },
  {
    path:     "/search/*",
    element:  <MainSearch />,
  },
  {
    path:     "order/*",
    element:  <OrderPageLayout />,
    children: [
      {
        path:    "",
        element: <OrderFirstStepPage />,
      },
      {
        path:    "second-step",
        element: <OrderSecondStepPage />,
      },
      {
        path:    "third-step",
        element: <OrderThirdStepPage />,
      },
    ],
  },
  {
    path:    "registration",
    element: <Registration />,
  },
  {
    path:    "freedom",
    element: <FF />,
  },
  {
    path:    "egov",
    element: <Egov />,
  },
  {
    path:    "transfer",
    element: <TransferProfile />,
  },
  {
    path:    "non-resident",
    element: <NotCitizen />,
  },
  {
    path:    "document",
    element: <WithDocument />,
  },
  {
    path:    "transfer-complete",
    element: <TransferComplete />,
  },
  {
    path:    "specialist-reviews/:id/:service_id?",
    element: <SpecialistReviews />,
  },
  {
    path:    "client-reviews/:id",
    element: <ClientReviews />,
  },
  {
    path:    "specialist-self-reviews/:service_id?",
    element: <SelfReviews />,
  },
  {
    path:     "tariffs/*",
    element:  <Outlet />,
    children: [
      {
        path:    "",
        element: <PurchaseListPage />,
      },
      {
        path:    "periods",
        element: <PeriodsListPage />,
      },
      {
        path:    "options/:period/:is_subscribe?",
        element: <TariffOptionsPage />,
      },
      {
        path:    "catalogue",
        element: <PurchaseCatalogueList />,
      },
      {
        path:    "catalogue/services",
        element: <PurchaseServicesList />,
      },
    ],
  },
  {
    path:     "state/*",
    element:  <Outlet />,
    children: [
      {
        path:    "auto-renewal-cancel",
        element: <AutoRenewalCancel />,
      },
    ],
  },
  {
    path:     "credits/*",
    element:  <Outlet />,
    children: [
      {
        path:    "",
        element: <Credits />,
      },
      {
        path:    "schedule/:id",
        element: <CreditsSchedule />,
      },
    ],
  },
  {
    path:    "specialist-cash-loans",
    element: <CashLoans />,
  },
  {
    path:    "referral",
    element: <Referral />,
  },
  {
    path:     "profile/*",
    element:  <Outlet />,
    children: [
      {
        path:    "*",
        element: <SpecialistProfile />,
      },
      {
        path:    ":id/*",
        element: <SpecialistProfile />,
      },
      {
        path:    "about",
        element: <ProfileAbout />,
      },
      {
        path:    "prices",
        element: <ProfilePrices />,
      },
      {
        path:    "prices-service/:id",
        element: <PricesService />,
      },
      {
        path:    "prices-work/:id",
        element: <PricesWork />,
      },
      {
        path:    "remove",
        element: <RemoveProfile />,
      },
      {
        path:    "media/:id?",
        element: <ProfileMedia />,
      },
      {
        path:    "media/add",
        element: <ProfileMediaUpload />,
      },
      {
        path:    "avatar-change",
        element: <ProfileAvatarChange />,
      },
    ],
  },
  {
    path:    "client-profile/:id",
    element: <ClientProfile />,
  },
  {
    path:    "specialist-prices/:id/:service_id?",
    element: <SpecialistAppPrices />,
  },
]

const RootRoutes = () => {
  const {t} = useTranslation();

  return <Routes>
    {routes.map((route) => (
      <Route key={route.path} path={route.path} element={route.element}>
        {!!route.children?.length && route.children.map((child) => (
          <Route key={child.path} path={child.path} element={child.element} />
        ))}
      </Route>
    ))}
    <Route path="/manual" element={
      <ManualRegistration
        navSubtitle={t("page.registration.manual_registration.header.description")}
        type="manual"
      />}
    />
  </Routes>
}
export default RootRoutes;