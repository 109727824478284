import React from 'react';
import { Icon, IconType } from '../../v12ui';
import { Label } from '../index';
import { ActionPanelItem, PanelItemType } from './model/actionPanelItem';
import { imageUrl } from '../../lib';
import { SkeletonLayout } from '../../../entities/skeleton';
import cn from 'classnames';
import styles from './ActionPanel.module.scss';

interface Props {
  items?: ActionPanelItem[];
  isLoading?: boolean;
  onActionCardClick: (item: ActionPanelItem) => void;
}

function ActionPanel({ items, isLoading, onActionCardClick }: Props) {
  return (
    <ul className={styles.action_panel_list}>
      {isLoading ? (
        <li className={styles.skeleton_item}>
          <SkeletonLayout height={75} theme='dark' containerClass={styles.skeleton} />
        </li>
      ) : (
        items?.map((item, index, array) => {
          return (
            <li
              key={index}
              className={cn(
                styles.panel_item,
                { [`${styles[item.type]}`]: item.type },
                { [styles.has_one_item]: array.length === 1 },
              )}
              onClick={() => onActionCardClick(item)}
            >
              {item.digest && (
                <div className={styles.image_item_holder}>
                  <img src={imageUrl(item.digest)} alt='' />
                </div>
              )}

              {item.icon &&
                ![PanelItemType.Create_Order, PanelItemType.Create_Vacancy, PanelItemType.Stories].includes(
                  item.type,
                ) && (
                  <Icon roundedIconColor='black' type={IconType.ROUNDED}>
                    {item.icon}
                  </Icon>
                )}

              <div
                className={cn(
                  styles.list_text_holder,
                  { [`${styles[item.type]}`]: item.type },
                  { [styles.has_one_item]: array.length === 1 },
                  { [styles.has_prefix]: !!item.digest },
                )}
              >
                {item.labels && <Label labels={item.labels} />}

                <span className={styles.list_text_title}>{item.title}</span>

                {item.subtitle && <span className={styles.list_text_subtitle}>{item.subtitle}</span>}
              </div>

              <Icon className={cn(styles.item_icon_suffix, { [`${styles[item.type]}`]: item.type })}>
                {![PanelItemType.Create_Order, PanelItemType.Create_Vacancy, PanelItemType.Stories].includes(item.type)
                  ? 'keyboard_arrow_right'
                  : item.icon}
              </Icon>
            </li>
          );
        })
      )}
    </ul>
  );
}

export default React.memo(ActionPanel);
