import {initReactI18next} from "react-i18next";
import i18n               from "i18next";
import ruTranslations     from "../../i18n/ru";
import kzTranslations     from "../../i18n/kk";

i18n
  .use(initReactI18next)
  .init({
    resources:     {
      ru: {
        translation: ruTranslations,
      },
      kk: {
        translation: kzTranslations,
      },
    },
    lng:           "ru",
    fallbackLng:   "ru",
    debug:         false,
    detection:     {
      order:  ["queryString", "cookie", "localStorage", "path", "subdomain"],
      caches: ["cookie"],
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;