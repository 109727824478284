export const main = {
  demo_login_request: 'Уже есть аккаунт? Войти',
  interest_suggest: 'Вас могут заинтересовать',
  interest_suggest_ultra: 'Популярные услуги',
  recent_views: 'Вы недавно смотрели',
  categories_list: 'Каталог услуг',
  categories_list_ultra: 'Все категории',
  faq: 'Помощь',
  faq_show_more: 'Показать все',
  promo_block: {
    ultra: {
      title: 'Не нашли услугу в Ultra?',
      description: 'Создайте заявку и получите предложения от частных мастеров',
      button: 'Создать',
    },
  },
};
