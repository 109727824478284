import React                                  from "react";
import {useSetHeaders, useSkeletonThrottling} from "shared/lib";
import {AppMode}                              from "shared/model";
import {useFetchInvitationInfoMutation}       from "../api";

export const useFetchInvitationInfo = () => {
  const {isSuccess, appMode} = useSetHeaders();

  const [fetchInvitationInfo, {data: invitationInfo, isLoading}] = useFetchInvitationInfoMutation();
  const {extendedLoading} = useSkeletonThrottling({isLoading});

  React.useEffect(() => {
    if (isSuccess && appMode) {
      fetchInvitationInfo({appMode: appMode as AppMode});
    }
  }, [isSuccess, appMode]);

  return {invitationInfo, extendedLoading}
}