import { api, BaseResponse } from 'shared/api/api';
import { NavigationTabItem } from 'shared/ui/navigationTabs/model/navigationTabItem';
import { MainPageTabType } from 'shared/model/types/main/mainPageType';
import { MainPageResponse } from '../model/mainPageResponse';

const mainLayoutApi = api.injectEndpoints({
  endpoints: (build) => ({
    fetchMainTabs: build.query<{ tabs: NavigationTabItem[] } | undefined, null>({
      query: () => ({
        url: 'app/main/tabs',
        method: 'PUT',
      }),
      transformResponse: (
        response: BaseResponse<{
          tabs: NavigationTabItem[];
        }>,
      ) => (response ? response.content : undefined),
    }),
    fetchCurrentMainPage: build.query<MainPageResponse | undefined, { tab: MainPageTabType; component?: boolean }>({
      query: ({ tab, component }) => ({
        url: 'app/main',
        method: 'PUT',
        body: { tab, component: component ? 'action_panel' : undefined },
      }),
      transformResponse: (response: BaseResponse<MainPageResponse>) => (response.status ? response.content : undefined),
      keepUnusedDataFor: 300,
    }),
  }),
});

export const { useFetchMainTabsQuery, useFetchCurrentMainPageQuery } = mainLayoutApi;
