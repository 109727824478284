import React, {useEffect, useState}              from "react";
import {useTranslation}                          from "react-i18next";
import {NewPageLayout}                           from "entities/general";
import {SkeletonLayout}                          from "entities/skeleton";
import {LoansPageType, InitialPage, StateScreen} from "entities/specialist/cashLoans";
import {
  Nullable,
  NativeActions,
  useAppSelector,
  selectAppPlatform,
}                                                from "shared/model";
import {
  useNativeHandler,
  setActionForNative,
  useSkeletonThrottling,
  useSetHeaders,
}                                                from "shared/lib";
import {Button, Icon}                            from "shared/v12ui";
import {
  useGetSupportProgramInfoMutation,
  useInitSupportProgramMutation,
  useSupportProgramIssueMutation,
}                                                from "./api";
import {loaderAnimation}                         from "app/styles/assets/lotties";
import Lottie                                    from "lottie-react";
import styles                                    from "./styles.module.scss";


const i18n_prefix = "page.customers.cash_loans."
export default function CashLoans() {
  const {t} = useTranslation();
  const platform = useAppSelector(selectAppPlatform);

  const {isSuccess} = useSetHeaders();
  const [fetchLoansInfo, {data, isLoading: loansInfoIsLoading}] = useGetSupportProgramInfoMutation();
  const [initCashLoan, {
    data:      initCashLoanResponse,
    isLoading: initCashLoanIsLoading,
  }] = useInitSupportProgramMutation();
  const [fetchCashLoanIssue, {
    data:      cashLoanIssueResponse,
    isLoading: cashLoansIssuesIsLoading,
  }] = useSupportProgramIssueMutation();

  const {extendedLoading} = useSkeletonThrottling({isLoading: loansInfoIsLoading});
  const [cardId, setCardId] = useState<Nullable<number>>(null);
  const [creditId, setCreditId] = useState<Nullable<number>>(null);
  const [pageState, setPageState] = useState<LoansPageType>(LoansPageType.INITIAL);
  const [responseMessage, setResponseMessage] = useState("");

  const handleInitClick = () => {
    setPageState(LoansPageType.LOADING);
    initCashLoan();
  };

  const handleOpenCardsClick = () => {
    setActionForNative(NativeActions.OPEN_CARDS, ({footer_text: data?.content?.details?.description}));
  }

  const handleOnCloseClick = () => setActionForNative(NativeActions.TERMINATE);

  const handleRefetchLoansInfo = () => {
    window.setTimeout(() => {
      fetchLoansInfo();
    }, 1000)
  }

  useEffect(() => {
    if (isSuccess) {
      fetchLoansInfo();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (data && !data?.content.details) {
      setPageState(LoansPageType.EMPTY_CATEGORIES);
    }
  }, [data, loansInfoIsLoading]);

  useEffect(() => {
    if (initCashLoanResponse) {
      if (initCashLoanResponse?.content.status === false) {
        setPageState(LoansPageType.DENIED);
        setResponseMessage(initCashLoanResponse.content.message);
      }
      if (initCashLoanResponse?.content.status === true) {
        setPageState(LoansPageType.SELECT_CARD);
        setResponseMessage(initCashLoanResponse.content.message);
        setCreditId(initCashLoanResponse.content.credit_id);
      }
    }
  }, [initCashLoanResponse]);

  useEffect(() => {
    if (cardId && creditId) {
      fetchCashLoanIssue({card_id: cardId, credit_id: creditId});
    }
  }, [cardId, creditId]);

  useEffect(() => {
    if (cashLoanIssueResponse && cashLoanIssueResponse.content.status) {
      setPageState(LoansPageType.SUCCESS);
      setResponseMessage(cashLoanIssueResponse.content.message);
    }
    if (cashLoanIssueResponse && !cashLoanIssueResponse.content.status) {
      setPageState(LoansPageType.ERROR);
      setResponseMessage(cashLoanIssueResponse.content.message);
    }
  }, [cashLoanIssueResponse]);

  useNativeHandler<{ card_id: number }>("tariffs", NativeActions.OPEN_CARDS, (data) => {
    return setCardId(data.card_id);
  });

  useNativeHandler(null, NativeActions.BACK_TAP, () => {
    if (platform === "android") {
      return handleOnCloseClick();
    }
  });

  useNativeHandler<{ is_changed: boolean }>("tariffs", NativeActions.OPEN_NEWSLINE_FILTERS, data => {
    if (data.is_changed) {
      return handleRefetchLoansInfo();
    }
  });

  const footer = pageState !== LoansPageType.INITIAL ? null : <div className={styles.footer}>
    {extendedLoading
      ? <div className={styles.footer_loading}>
        <SkeletonLayout height={62} />
        <SkeletonLayout height={53} borderRadius={50} />
      </div>
      : <>
        <div className={styles.priceHolder}>
          <span className={styles.price}>{data?.content?.details?.button.title ?? ""}</span>

          <span className={styles.text}>{data?.content?.details?.button.subtitle ?? ""}</span>
        </div>

        <Button text={t(`${i18n_prefix}button` ?? "")} onClick={handleInitClick} />
      </>}
  </div>

  const loadingTitle = initCashLoanIsLoading
    ? t(`${i18n_prefix}loading_screen.check.title`)
    : t(`${i18n_prefix}loading_screen.loan_process.title`);

  const loadingDescription = initCashLoanIsLoading
    ? t(`${i18n_prefix}loading_screen.check.description`)
    : t(`${i18n_prefix}loading_screen.loan_process.description`);

  return <NewPageLayout
    headerTitle={pageState === LoansPageType.INITIAL ? data?.content?.details?.title : ""}
    headerRightIcon={<Icon className="text black" onClick={handleOnCloseClick}>close</Icon>}
    footer={(!initCashLoanIsLoading && !cashLoansIssuesIsLoading && data?.content.details) ? footer : null}
    isLoading={extendedLoading}
  >
    {initCashLoanIsLoading || cashLoansIssuesIsLoading
      ? <LoadingScreen title={loadingTitle} description={loadingDescription} />
      : <>
        {pageState === LoansPageType.INITIAL && data?.content.details &&
          <InitialPage options={data?.content?.details?.options} isLoading={extendedLoading} />}

        <StateScreen
          state={pageState}
          message={responseMessage}
          onOpenCardClick={handleOpenCardsClick}
        />
      </>}
  </NewPageLayout>
};

function LoadingScreen({title, description}: { title: string; description: string }) {
  return <div className={styles.centered_content}>
    <div className={styles.loading}>
      <Lottie
        autoPlay
        animationData={loaderAnimation}
        style={{width: 40, height: 40, justifySelf: "center"}}
        loop
      />

      <div className={styles.text_block}>
        <span className={styles.title_text}>{title}</span>
        <span className={styles.description_text}>{description}</span>
      </div>
    </div>
  </div>
}