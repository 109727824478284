import React                                            from "react";
import {useTranslation}                                 from "react-i18next";
import {useNavigate}                                    from "react-router-dom";
import {GalleryMediaItem, GalleryWrapper}               from "shared/ui";
import {Button, NewButtonColor, NewButtonType}          from "shared/v12ui";
import {SpecialistProfilePhotosItem, Nullable, AppMode} from "shared/model";
import {SkeletonLayout}                                 from "entities/skeleton";
import styles                                           from "./MediaBlock.module.scss";


interface Props {
  media?: SpecialistProfilePhotosItem[];
  id: Nullable<number>;
  appMode: Nullable<string>;
  isViewMode?: boolean;
  isLoading?: boolean;
  children?: React.ReactNode;
  adDisabled?: boolean;
}

const i18n_prefix = "page.specialist.profile.media.";

function MediaBlock({
  media,
  id: specialistId,
  appMode,
  isViewMode = false,
  isLoading,
  children,
  adDisabled = false,
}: Props) {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const handleOpenMediaList = () => {
    if (specialistId) {
      return navigate(`/profile/media/${specialistId}`);
    }
    return navigate(`/profile/media`);
  };

  const buttonText = () => {
    if (appMode === AppMode.CLIENT) {
      return t(`${i18n_prefix}button.show_all`);
    }
    if (appMode === AppMode.SPECIALIST) {
      if (!media?.length) {
        return t(`${i18n_prefix}button.add`);
      }
      return t(`${i18n_prefix}button.update`);
    }
    return "";
  }

  return <div className={styles.wrapper}>
    {!adDisabled ? children : null}
    {isLoading
      ? <SkeletonLayout borderRadius={6} height={22} width={130} theme="dark" containerClass="mgb-3" />
      : <span className={styles.header}>{t(`${i18n_prefix}title`)}</span>}

    {isLoading
      ? <div className={styles.skeleton_media}>
        {Array.from({length: 4}, (_, i) => <SkeletonLayout key={i} height={140} />)}
      </div>
      : !media?.length
        ? <span className={styles.empty_text}>
          {appMode === AppMode.SPECIALIST && t(`${i18n_prefix}empty.specialist`)}
          {appMode === AppMode.CLIENT && t(`${i18n_prefix}empty.client`)}
      </span>

        : <GalleryWrapper>
          <ul className={styles.media_holder}>
            {media?.slice(0, 4).map((photo, index) => {
              return <li key={index}>
                <GalleryMediaItem
                  digest={photo.digest}
                  type={photo.type}
                  reviewInfo={{createdAt: photo.created_at, rate: photo.rate, price: photo.price}}
                  description={photo.description}
                  hasOpen={!((appMode === AppMode.SPECIALIST && !!specialistId) || isViewMode)}
                />
              </li>
            })}
          </ul>
        </GalleryWrapper>}

    {(appMode === AppMode.SPECIALIST && specialistId) || isViewMode || (!media?.length && appMode === AppMode.CLIENT)
      ? null
      : <>
        {isLoading
          ? <SkeletonLayout height={53} theme="dark" borderRadius={50} />

          : <Button
            text={buttonText()}
            buttonColor={NewButtonColor.GRAY}
            buttonType={NewButtonType.WRAPPED}
            onClick={handleOpenMediaList}
          />}
      </>}
  </div>
}

export default React.memo(MediaBlock);