import React                                                        from "react";
import {NativeActions}                                              from "../types/general/generalTypes";
import {useAppSelector}                                             from "../reduxHooks";
import {selectEntryPoint, selectTicketId, selectTicketIsDuplicated} from "../slices/queries";
import {setActionForNative}                                         from "../../lib";

export function useSetOrderAnalytics(step: string, analytics?: { [key: string]: unknown }) {
  const entryPoint = useAppSelector(selectEntryPoint);
  const ticketId = useAppSelector(selectTicketId);
  const isDuplicatedTicket = useAppSelector(selectTicketIsDuplicated);
  const [firstStepSkip, setFirstStepSkip] = React.useState(false);

  const category = analytics ? (analytics["category"] ?? "not set") : "not set";
  const section = analytics ? (analytics["section"] ?? "not set") : "not set";
  const subcategory = analytics ? (analytics["subcategory"] ?? "not set") : "not set";
  const currentEntryPoint = entryPoint ?? "";

  React.useEffect(() => {
    if (firstStepSkip) return;
    if (ticketId && !isDuplicatedTicket) return;

    if (analytics && typeof currentEntryPoint === "string") {
      if (step === "1") {
        setFirstStepSkip(true);
      }
      return setActionForNative(NativeActions.ANALYTICS, {
        event:      "service ticket creation opened",
        properties: {
          "entry point":    entryPoint ?? "",
          "step":           step,
          "section id":     section,
          "category id":    category,
          "subcategory id": subcategory,
        },
      });
    }
  }, [analytics, entryPoint, ticketId, isDuplicatedTicket, step, firstStepSkip]);
}