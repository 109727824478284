import React                                                  from "react";
import {useNavigate, useSearchParams}                         from "react-router-dom";
import {useTranslation}                                       from "react-i18next";
import {SkeletonLayout}                                       from "entities/skeleton";
import {NewPageLayout}                                        from "entities/general/newPageLayout";
import {LoadingScreen}                                        from "entities/general";
import {InApp}                                                from "shared/ui";
import {Icon, Button, NewButtonColor, NewButtonType, NewList} from "shared/v12ui";
import {
  useNativeHandler,
  useSetHeaders,
  setActionForNative,
  formatNumberWithSpaces,
  useSkeletonThrottling,
  useCheckPaymentStatus,
  useOpenPayments,
}                                                             from "shared/lib";
import {
  Nullable,
  NativeActions,
  useAppDispatch,
  useAppSelector,
  setIsMyAnketyOpened,
  selectIsMyAnketyOpened,
  addNotification,
  PurchaseType,
  selectEntryPoint,
  selectPurchaseIsLoading,
}                                                             from "shared/model";
import {
  useFetchPurchaseCatalogueListQuery,
  useRemoveCatalogueServiceMutation,
  useCatalogueServicesCartInitMutation,
}                                                             from "./api/purchaseCatalogueListApi";
import styles                                                 from "./PurchaseCatalogueList.module.scss";


const i18n_prefix = "page.specialist.purchase_catalogue.";
const PADDING_SIZE = 36;
const PurchaseCatalogueList = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const entryPoint = useAppSelector(selectEntryPoint);
  const myAnketyAnalyticsIsSend = useAppSelector(selectIsMyAnketyOpened);
  const purchaseInProgress = useAppSelector(selectPurchaseIsLoading);
  const [searchParams] = useSearchParams();
  const isDirect = searchParams.get("direct");

  const buttonHolderRef = React.useRef<HTMLDivElement>(null);

  const {isSuccess} = useSetHeaders({isRequired: isDirect === "true"});

  const {
    data:      purchaseCatalogue,
    isLoading: catalogueIsLoading,
    refetch:   refetchCatalogue,
  } = useFetchPurchaseCatalogueListQuery(null, {
    skip: isDirect === "true" && !isSuccess,
  });

  const [deleteService, {
    isLoading: deleteServiceLoading,
    isSuccess: deleteServiceIsSuccess,
  }] = useRemoveCatalogueServiceMutation();
  const [purchaseCatalogueCartInit, {
    data:      purchaseCatalogueCartInitResponse,
    isSuccess: purchaseCatalogueCartInitIsSuccess,
    isLoading: purchaseCatalogueCartInitIsLoading,
  }] = useCatalogueServicesCartInitMutation();

  const {extendedLoading} = useSkeletonThrottling({isLoading: catalogueIsLoading});
  const [openRemoveInApp, setOpenRemoveInApp] = React.useState(false);
  const [buttonIsDisabled, setButtonIsDisabled] = React.useState(false);
  const [toastPosition, setToastPosition] = React.useState(0);
  const [removeId, setRemoveId] = React.useState<Nullable<number>>(null);

  const handleBackClick = () => {
    if (isDirect === "true") {
      return setActionForNative(NativeActions.TERMINATE);
    }
    return navigate("/tariffs");
  };

  const handleNavigateToServices = () => navigate("services");

  const handleCloseRemoveInApp = () => setOpenRemoveInApp(false);

  const handleRemoveFromCategory = (id: number) => {
    setRemoveId(id);
    setOpenRemoveInApp(true);
  }

  const handleSetNotification = React.useCallback(() => {
    setButtonIsDisabled(true);
    dispatch(addNotification({icon: "validation", text: t(`${i18n_prefix}notification`)}));
  }, [dispatch, t]);

  const handleSetToastPosition = () => {
    if (buttonHolderRef && buttonHolderRef.current) {
      setToastPosition(buttonHolderRef.current.offsetHeight + PADDING_SIZE);
    }
  }

  const handleDeleteService = () => {
    if (!removeId) return;
    deleteService(removeId);
  };

  const handlePurchaseCatalogue = () => purchaseCatalogueCartInit();

  React.useEffect(() => {
    if (!purchaseCatalogueCartInitIsSuccess) return;
    if (purchaseCatalogueCartInitResponse?.content?.payment_id === null) {
      handleSetNotification();
      refetchCatalogue();
      dispatch(addNotification({icon: "validation", text: t("state.waiting_payment")}));
    }
  }, [purchaseCatalogueCartInitResponse, purchaseCatalogueCartInitIsSuccess, handleSetNotification]);

  React.useEffect(() => {
    if (deleteServiceIsSuccess) {
      setOpenRemoveInApp(false);
      refetchCatalogue();
      dispatch(addNotification({icon: "validation", text: t(`${i18n_prefix}delete_catalogue_item`)}));
    }
  }, [dispatch, deleteServiceIsSuccess, refetchCatalogue]);

  React.useLayoutEffect(() => {
    handleSetToastPosition();
  }, [catalogueIsLoading, refetchCatalogue, deleteServiceIsSuccess, purchaseCatalogue?.content.button.subtitle]);

  useOpenPayments({
    paymentId: purchaseCatalogueCartInitResponse?.content?.payment_id,
    type:      PurchaseType.CATALOGUE_INIT,
  });

  React.useEffect(() => {
    if (myAnketyAnalyticsIsSend) return;
    const analytics = purchaseCatalogue?.analytics?.["filter category"];
    const entry = isDirect && entryPoint ? entryPoint : "naimi://purchase";
    if (analytics && !myAnketyAnalyticsIsSend) {
      setActionForNative(NativeActions.ANALYTICS, {
        event:      "specialist my ankety in catalog opened",
        properties: {
          "entry point":     entry,
          "filter category": analytics,
        },
      });
      dispatch(setIsMyAnketyOpened(true));
    }
  }, [dispatch, entryPoint, myAnketyAnalyticsIsSend, purchaseCatalogue, isDirect]);

  const {paymentStatusLoading} = useCheckPaymentStatus({
    refetch:           refetchCatalogue,
    onSetNotification: handleSetNotification,
  });

  useNativeHandler(null, NativeActions.BACK_TAP, () => {
    if (purchaseInProgress) return;
    if (isDirect && entryPoint) {
      return setActionForNative(NativeActions.TERMINATE);
    }
    if (openRemoveInApp) {
      return setOpenRemoveInApp(false);
    }
    return navigate("/tariffs");
  });

  const footer = <div className={styles.footer}>
    {!extendedLoading && <div className={styles.footer_text}>{t(`${i18n_prefix}footer`)}</div>}

    <div ref={buttonHolderRef}>
      {extendedLoading
        ? <SkeletonLayout height={53} borderRadius={50} containerClass={styles.skeleton_button} theme="dark" />

        : <Button
          text={purchaseCatalogue?.content.button.title ?? ""}
          secondLine={purchaseCatalogue?.content.button.subtitle}
          disabled={catalogueIsLoading || purchaseCatalogue?.content.button.is_disabled || !purchaseCatalogue || buttonIsDisabled}
          loading={purchaseCatalogueCartInitIsLoading}
          onClick={handlePurchaseCatalogue}
        />}
    </div>
  </div>

  return <>
    {paymentStatusLoading && <LoadingScreen />}

    <NewPageLayout
      isLoading={extendedLoading}
      headerTitle={purchaseCatalogue?.content.title}
      headerSubtitle={purchaseCatalogue?.content.subtitle}
      headerLeftIcon={<Icon iconColor="#2B2B2B" onClick={handleBackClick}>keyboard_arrow_left</Icon>}
      footer={footer}
      // refetch={refetchCatalogue}
      toastPosition={toastPosition}
    >
      <>
        {extendedLoading
          ? <>{[...Array(3)].map((_, index) => <SkeletonLayout key={index} height={45} containerClass={"mgb-6"} />)}</>

          : purchaseCatalogue?.content?.services?.map((service) =>
            <NewList
              key={service.id}
              title={service.name}
              subtitle={service.description}
              subtitleError={service.color === "error"}
              controlIcon={<Icon
                onClick={() => handleRemoveFromCategory(service.id)}
                className="text gray">
                delete
              </Icon>}
            />)}

        {extendedLoading
          ? <SkeletonLayout height={68} />

          : <NewList
            title={t(`${i18n_prefix}add_category.title`)}
            subtitle={t(`${i18n_prefix}add_category.subtitle`)}
            controlIcon={<Icon iconColor="#2B2B2B">add</Icon>}
            onClick={handleNavigateToServices}
            link={purchaseCatalogue?.content.price_per_addition
              ? {name: t(`${i18n_prefix}add_category.description`, {price: `${formatNumberWithSpaces(purchaseCatalogue.content.price_per_addition)}`})}
              : undefined}
          />}
      </>
    </NewPageLayout>

    <InApp
      image="/illustrations/delete-4.svg"
      headerText={t(`${i18n_prefix}delete_category.title`)}
      headerSubtext={t(`${i18n_prefix}delete_category.subtitle`)}
      open={openRemoveInApp}
      onClose={handleCloseRemoveInApp}
    >
      <div className={styles.remove_buttons_holder}>
        <Button
          buttonType={NewButtonType.WRAPPED}
          buttonColor={NewButtonColor.GRAY}
          disabled={deleteServiceLoading}
          text={t(`${i18n_prefix}delete_category.buttons.cancel`)}
          onClick={handleCloseRemoveInApp}
        />

        <Button
          hasSpace
          loading={deleteServiceLoading}
          buttonColor={NewButtonColor.ERROR}
          text={t(`${i18n_prefix}delete_category.buttons.delete`)}
          onClick={handleDeleteService}
        />
      </div>
    </InApp>
  </>
};

export default PurchaseCatalogueList;