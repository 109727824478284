import React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { GalleryMediaItem, GalleryWrapper, Label, LabelItem } from 'shared/ui';
import { addNotification, removeNotification, useAppDispatch, useAppSelector } from 'shared/model';
import { ImageFromNativeItem, setOpenMediaTransfer } from 'entities/order';
import { useRemoveMediaMutation } from './api';
import TransferringMedia from './ui/TransferringMedia';
import { selectMoveToSecondStep, selectOrderMedia, setOrderMedia } from 'entities/order/model/slice/orderSlice';
import styles from './UploadMedia.module.scss';

interface Props {
  labels?: LabelItem[];
  description?: string;
  title?: string;
}

function UploadMedia({ title, description, labels }: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const timeoutListener = React.useRef<NodeJS.Timeout | null>(null);
  const mediaFromStore = useAppSelector(selectOrderMedia);
  const isMoveToSecondStep = useAppSelector(selectMoveToSecondStep);

  const [removeMedia] = useRemoveMediaMutation();

  const { control } = useForm<{ media: ImageFromNativeItem[] }>({
    defaultValues: {
      media: [],
    },
  });

  const { fields, replace, remove } = useFieldArray({ control, name: 'media' });

  const [removedMedia, setRemovedMedia] = React.useState<ImageFromNativeItem[]>([]);

  const onRemove = (media: ImageFromNativeItem, index: number) => {
    timeoutListener.current = setTimeout(() => {
      if (media.digest) {
        removeMedia({ type: media.type === 'video' ? 'video' : 'image', digest: media.digest });
      }
      remove(index);
      setRemovedMedia((prev) => prev.filter((item) => item.digest !== media.digest));
      if (timeoutListener.current) {
        clearTimeout(timeoutListener.current);
        timeoutListener.current = null;
      }
    }, 2750);
  };

  const handleRemoveMedia = (media: ImageFromNativeItem, index: number) => {
    setRemovedMedia((prev) => [...prev, { ...media, index }]);
    dispatch(addNotification({ text: 'Файл удалён. Отмена', type: 'remove', id: media.digest }));

    if (timeoutListener.current === null) {
      onRemove(media, index);
    } else {
      clearTimeout(timeoutListener.current);
      timeoutListener.current = null;
      const objectInProcess = removedMedia[0];
      const removedIndex = fields.findIndex((item) => item.digest === objectInProcess.digest);
      remove(removedIndex);
      onRemove(media, index);
      setRemovedMedia((prev) => prev.filter((item) => item.digest !== objectInProcess.digest));
    }
  };

  const handleRemoveToastClick = (id?: string) => {
    if (timeoutListener.current) {
      clearTimeout(timeoutListener.current);
      timeoutListener.current = null;
    }
    const removeObject = removedMedia.find((item) => item.digest === id);

    if (removeObject) {
      setRemovedMedia((prev) => prev.filter((item) => item !== removeObject));
    }
  };

  const handleToggleSelect = () => {
    dispatch(setOpenMediaTransfer(true));
    dispatch(removeNotification());

    if (timeoutListener.current) {
      clearTimeout(timeoutListener.current);
      timeoutListener.current = null;
      const removeObject = removedMedia[0];

      const newFields = fields.filter((item) => item.digest !== removeObject.digest);
      const currentFields = newFields.filter((item) => item.digest !== undefined);
      remove(0);
      dispatch(setOrderMedia([...currentFields]));
    } else {
      const currentFields = fields.filter((item) => item.digest !== undefined);
      dispatch(setOrderMedia([...currentFields]));
    }
    setRemovedMedia([]);
  };

  React.useEffect(() => {
    if (mediaFromStore) {
      replace(mediaFromStore);
    }
  }, [mediaFromStore]);

  React.useEffect(() => {
    if (isMoveToSecondStep) {
      dispatch(removeNotification());

      if (timeoutListener.current) {
        clearTimeout(timeoutListener.current);
        timeoutListener.current = null;
        const removeObject = removedMedia[0];

        const newFields = fields.filter((item) => item.digest !== removeObject.digest);
        const currentFields = newFields.filter((item) => item.digest !== undefined);
        remove(0);
        dispatch(setOrderMedia([...currentFields]));
      } else {
        const currentFields = fields.filter((item) => item.digest !== undefined);
        dispatch(setOrderMedia([...currentFields]));
      }
      setRemovedMedia([]);
    }
  }, [isMoveToSecondStep]);

  return (
    <div className={styles.layout}>
      <div className={styles.title_holder}>
        <span className={styles.title}>{title ?? t('features.upload_media.title')}</span>
        {labels && <Label labels={labels} />}
      </div>

      <GalleryWrapper>
        <ul className={styles.media_wrapper}>
          {(fields.length < 5 || removedMedia.length > 0) && (
            <li>
              <button type='button' className={styles.upload_button} onClick={handleToggleSelect}>
                <span className={styles.add_icon}></span>
              </button>
            </li>
          )}

          {!!fields.length &&
            fields
              .filter((item) => !removedMedia.some((removed) => item.digest === removed.digest))
              .map((field, fieldIndex) => {
                return (
                  <li key={field.id}>
                    <GalleryMediaItem width={109} size={96} digest={field.digest as string} type={field.type} />
                    <button
                      type='button'
                      onClick={() => handleRemoveMedia(field, fieldIndex)}
                      className={styles.remove_media}
                    >
                      delete
                    </button>
                  </li>
                );
              })}
        </ul>
      </GalleryWrapper>

      <TransferringMedia limit={fields?.length} onRemoveMediaToastClick={handleRemoveToastClick} />

      <span className={styles.description}>{description}</span>
    </div>
  );
}

export default UploadMedia;
