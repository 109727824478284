import {Nullable}                    from "shared/model";
import {BaseResponse, RequestMethod} from "shared/api";
import {api}                         from "shared/api/api";

interface LabelItem {
  title?: string;
  style?: string;
}

export interface PeriodItem {
  title: string;
  subtitle?: string;
  price: string;
  is_subscribe?: boolean;
  days: number;
  labels?: LabelItem[];
}

export interface PurchaseItem {
  id: number;
  title: string;
  subtitle?: string;
  description?: string;
  buttons?: string[];
  color?: string;
}

interface Periods {
  items: PeriodItem[],
}

export interface TariffSubscription {
  tariff_id: Nullable<number>;
  subscribe_id: Nullable<number>;
  title: string;
  subtitle?: string;
  labels?: LabelItem[];
  is_disabled: boolean;
  is_checked: boolean;
}

interface TariffPurchase {
  catalogue: {
    title: string;
    subtitle: string;
    icon?: string;
    color?: Nullable<string>;
  };
  items: PurchaseItem[];
  subscribe?: TariffSubscription;
  faq?: {
    button?: number;
    faqs: { title: string, text: string }[];
  }
}

export interface TariffOptionItem {
  title: string;
  subtitle?: string;
  price_text?: string;
  price: number;
  code: string;
  is_disabled?: boolean;
  is_checked?: boolean;
  items?: {
    title: string,
    price: number,
    number: number,
    price_text: string,
    is_checked: boolean
  }[];
  labels: LabelItem[];
}

export interface TariffOptions {
  title?: string;
  subtitle?: string;
  subscribe?: {
    title: string;
    subtitle?: string;
    labels?: LabelItem[];
    is_checked: boolean;
    is_disabled?: boolean;
  };
  options: TariffOptionItem[];
}

interface CartInitPayload {
  is_subscribe?: boolean;
  days: number;
  options: {
    code: string;
    number?: number;
  }[]
}

export interface EditTariffPayload {
  id: number;
  options?: {
    code: string;
    is_checked: boolean;
    number: number | undefined
  }[];
}

interface SubscribeDisableInfoResponse {
  list: string[];
  has_gift: boolean;
  gift_text: string;
}

const tariffsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTariffPeriods:        build.query<BaseResponse<Periods>, null>({
      query: () => ({
        url:    "pro/balance/tariff/periods",
        method: RequestMethod.PUT,
      }),
    }),
    getTariffPurchaseList:   build.query<BaseResponse<TariffPurchase>, null>({
      query:             () => ({
        url:    "pro/balance/tariff",
        method: RequestMethod.PUT,
      }),
      keepUnusedDataFor: 0,
    }),
    getTariffOptions:        build.query<BaseResponse<TariffOptions>, {
      days?: number,
      id?: number,
      is_subscribe?: boolean
    }>({
      query: ({...params}) => ({
        url:    "pro/balance/tariff/options",
        method: RequestMethod.PUT,
        body:   params,
      }),
    }),
    setCartInit:             build.mutation<BaseResponse<{ payment_id: string }>, CartInitPayload>({
      query: ({...params}) => ({
        url:    "pro/balance/tariff/cart/init",
        method: RequestMethod.PUT,
        body:   params,
      }),
    }),
    editCurrentTariff:       build.mutation<BaseResponse<{ payment_id: string }>, EditTariffPayload>({
      query: ({...params}) => ({
        url:    "pro/balance/tariff/options/save",
        method: RequestMethod.PUT,
        body:   params,
      }),
    }),
    subscribeInit:           build.mutation<BaseResponse<{ payment_id: string }>, { id: number, is_now?: boolean }>({
      query: ({...params}) => ({
        url:    "pro/balance/tariff/subscribe/init",
        method: RequestMethod.PUT,
        body:   params,
      }),
    }),
    getSubscribeDisableInfo: build.mutation<BaseResponse<SubscribeDisableInfoResponse>, number>({
      query: (id) => ({
        url:    "pro/balance/tariff/subscribe/disable/info",
        method: RequestMethod.PUT,
        body:   {id},
      }),
    }),
    getSubscribeGift:        build.mutation<BaseResponse<boolean>, number>({
      query: (id) => ({
        url:    "pro/balance/tariff/subscribe/gift",
        method: RequestMethod.PUT,
        body:   {id},
      }),
    }),
    unsubscribeInit:         build.mutation<BaseResponse<boolean>, number>({
      query: (id) => ({
        url:    "pro/balance/purchase/unsubscribe",
        method: RequestMethod.PUT,
        body:   {purchase_id: id},
      }),
    }),
  }),
});

export const {
  useGetTariffPeriodsQuery,
  useGetTariffPurchaseListQuery,
  useLazyGetTariffOptionsQuery,
  useSetCartInitMutation,
  useEditCurrentTariffMutation,
  useSubscribeInitMutation,
  useGetSubscribeDisableInfoMutation,
  useGetSubscribeGiftMutation,
  useUnsubscribeInitMutation,
} = tariffsApi;
