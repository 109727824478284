import React                                                                         from "react";
import i18n                                                                          from "i18next";
import {useTranslation}                                                              from "react-i18next";
import {Textarea}                                                                    from "shared/ui";
import {Tabs}                                                                        from "shared/v12ui";
import {setActionForNative, useToggleState}                                          from "shared/lib";
import {NativeActions, Nullable, selectTicketId, useAppDispatch, useAppSelector}     from "shared/model";
import {useSetOrderAnalytics}                                                        from "shared/model/analytics";
import {DateListType, PickOrderDate, getCurrentTime, getCurrentDate, getTomorrowDay} from "features/order/pickDate";
import {UploadMedia}                                                                 from "features/global/uploadMedia";
import {
  selectDescriptionError,
  selectOrderAnalytics,
  selectSuccessResponse,
  setDescriptionError,
} from 'entities/order';
import useNativePermission from './lib/useNativePermission';
import {
  selectOrderDateAndTime,
  selectOrderDateTab,
  selectOrderDescription,
  selectOrderFirstStepLabels,
  setOrderDate,
  setOrderDateTab,
  setOrderDescription,
  setOrderIsFast,
} from 'entities/order/model/slice/orderSlice';

export default function OrderFirstStepPage() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const dateTab = useAppSelector(selectOrderDateTab);
  const description = useAppSelector(selectOrderDescription);
  const labels = useAppSelector(selectOrderFirstStepLabels);
  const dateAndTime = useAppSelector(selectOrderDateAndTime);
  const descriptionError = useAppSelector(selectDescriptionError);
  const ticketId = useAppSelector(selectTicketId);
  const successResponse = useAppSelector(selectSuccessResponse);
  const analytics = useAppSelector(selectOrderAnalytics);

  useNativePermission();

  const [selectTime, toggleSelectTime] = useToggleState();
  const [dateList, setDateList] = React.useState<DateListType[]>([]);

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(setOrderDescription(event.target.value));
    dispatch(setDescriptionError(''));
  };

  useSetOrderAnalytics('1', analytics);

  const handleSetDateValue = (value?: Nullable<string | number>) => {
    if (!value) return;
    if (value === 'selectDate') {
      toggleSelectTime();
    }
    if (value === 'fast') {
      dispatch(setOrderIsFast(true));
    } else {
      dispatch(setOrderDate(value as string));
      dispatch(setOrderIsFast(false));
    }
    dispatch(setOrderDateTab(value as string));
  };

  React.useEffect(() => {
    if (!dateAndTime) return;
    const date = dateAndTime.date;
    const currentDate = new Date(date as string);
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');


    const dateString = `${day}.${month}`;

    setDateList((prev) =>
      prev.map((item) =>
        item.id === 'selectDate'
          ? {
              ...item,
              text: `${dateString} | ${dateAndTime.time}`,
            }
          : item,
      ),
    );
  }, [dateAndTime]);

  React.useEffect(() => {
    if (!ticketId && !successResponse) {
      setTimeout(() => {
        setActionForNative(NativeActions.INPUT_FOCUS, { name: 'description' });
      }, 300);
    }
  }, [ticketId, successResponse]);

  React.useEffect(() => {
    const confirmDate = dateAndTime?.date;
    const currentDate = new Date(confirmDate as string);
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();

    const dateString = `${day}.${month}`;

    const selectDateText =
      dateAndTime?.date && dateAndTime.time
        ? `${dateString} | ${dateAndTime?.time}`
        : t('page.order.date_list.select_date');

    setDateList([
      { id: 'fast', text: t('page.order.date_list.fast') },
      { id: getCurrentDate(), text: t('page.order.date_list.today') },
      { id: getTomorrowDay(), text: t('page.order.date_list.tomorrow') },
      { id: 'selectDate', text: selectDateText, rightIcon: 'keyboard_arrow_down' },
    ]);
  }, [t, i18n.language, dateAndTime?.date, dateAndTime?.time]);

  return (
    <>
      <Textarea
        isNew
        tabIndex={-1}
        name='description'
        isRequired
        hasSpace
        errorMessage={descriptionError}
        hasSymbolCounter={false}
        placeholder={t('page.order.placeholders.description')}
        label={t('page.order.labels.description')}
        hint={t('page.order.hints.description')}
        value={description}
        onChange={handleInputChange}
      />

      <UploadMedia description={t('page.order.hints.media')} labels={labels} />

      <Tabs
        header={t('page.order.labels.date_and_time')}
        list={dateList}
        currentTab={dateTab ?? getCurrentTime()}
        onSetCurrentTab={handleSetDateValue}
      />

      <PickOrderDate
        dateAndTime={dateAndTime}
        open={selectTime}
        onClose={toggleSelectTime}
        onUpdateDateList={setDateList}
      />
    </>
  );
}
