import React from 'react';
import styles from 'shared/styles/adFoxMain.module.scss';

export default function AdFoxService() {
  React.useEffect(() => {
    if (window.location.hostname === 'localhost') return;
    //@ts-ignore
    window.yaContextCb.push(() => {
      //@ts-ignore
      Ya.adfoxCode.create({
        ownerId: 11622371,
        containerId: 'adfox_1740721092043517',
        params: {
          p1: 'dgipx',
          p2: 'jkce',
        },
      });
    });
  }, []);

  return (
    <div className={styles.ad_fox_container} style={{ height: `calc(${window.innerWidth / 3}px)` }}>
      <div style={{ width: '100vw' }} id='adfox_1740721092043517'></div>
    </div>
  );
}
