import {BaseResponse, RequestMethod} from "shared/api";
import {api}                         from "shared/api/api";
import {AddressItemType}             from "entities/order";


const yaMapApi = api.injectEndpoints({
  endpoints: (build) => ({
    fetchAddressesHistory: build.query<{ addresses: AddressItemType[] } | undefined, void>({
      query:             () => ({
        url:    "app/order/addresses/history",
        method: RequestMethod.PUT,
      }),
      transformResponse: (response: BaseResponse<{
        addresses: AddressItemType[]
      }>) => response ? response.content : undefined,
    }),
  }),
});

export const {useFetchAddressesHistoryQuery} = yaMapApi;