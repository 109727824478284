import React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { Button, Icon, Input, InputType, NewButtonColor, NewButtonType, NewList, Tabs } from 'shared/v12ui';
import { CheckboxInput, InApp, InputPhone } from 'shared/ui';
import { setActionForNative, useNativeHandler, useToggleState } from 'shared/lib';
import { GeolocationRequest, selectGeolocationPermission, toggleGeoInApp } from 'shared/native/permissions';
import { NativeActions, selectIsDemo, useAppDispatch, useAppSelector } from 'shared/model';
import { useSetOrderAnalytics } from 'shared/model/analytics';
import {
  useLazyFetchCurrentCityQuery,
  selectCurrentCity,
  selectPhoneError,
  setCurrentCity,
  setPhoneError,
  updateStepCount,
  selectOrderAnalytics,
  AddressesItem,
} from 'entities/order';
import { formatAddressName, YaMap, yaMapSearch } from 'widgets/yaMap';
import { getCommunicationWayList } from './model/communicationList';
import styles from './ThirdStep.module.scss';
import { LngLat, YMapLocationRequest } from '@yandex/ymaps3-types';
import {
  selectOrderAddresses,
  selectOrderIsAllowed,
  selectOrderIsClientAssistantEnabled,
  selectOrderPhone,
  selectOrderThirdStepLabels,
  setOrderAddresses,
  setOrderIsAllowed,
  setOrderIsClientAssistantEnabled,
  setOrderPhone,
} from 'entities/order/model/slice/orderSlice';

const LOCATION: YMapLocationRequest = {
  center: [71.427552, 51.128064],
  zoom: 15,
};

export default function OrderThirdStepPage() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentCity = useAppSelector(selectCurrentCity);
  const addresses = useAppSelector(selectOrderAddresses);
  const phone = useAppSelector(selectOrderPhone);
  const labels = useAppSelector(selectOrderThirdStepLabels);
  const isAllowed = useAppSelector(selectOrderIsAllowed);
  const isClientAssistantEnabled = useAppSelector(selectOrderIsClientAssistantEnabled);
  const hasGeoPermission = useAppSelector(selectGeolocationPermission);
  const phoneError = useAppSelector(selectPhoneError);
  const isDemoClient = useAppSelector(selectIsDemo);
  const analytics = useAppSelector(selectOrderAnalytics);

  useSetOrderAnalytics('3', analytics);

  const [getCurrentCity] = useLazyFetchCurrentCityQuery();

  const [mapIsOpen, toggleMap] = useToggleState(false);
  const [addressesListInAppIsOpen, toggleAddressesListInApp] = useToggleState();
  const [addressName, setAddressName] = React.useState<string>();
  const [updatedIndex, setUpdatedIndex] = React.useState<string>();
  const [firstCoordIsGetting, setFirstCoordIsGetting] = React.useState(false);
  const [permissionGeoIsRequested, setPermissionGeoIsRequested] = React.useState(false);
  const [location, setLocation] = React.useState<YMapLocationRequest>(LOCATION);
  const [openClientAssistantInfoInAppIsOpen, toggleClientAssistantInfo] = useToggleState();

  const handleCloseMap = () => {
    setUpdatedIndex(undefined);
    toggleMap();
  };

  const onAddressesInputClick = () => {
    if (!addresses.length) {
      setUpdatedIndex(undefined);
      yaMapSearch({ text: `Казахстан, ${currentCity?.name}`, limit: 1 })
        .then((res) => {
          setLocation({ center: res[0].geometry?.coordinates as LngLat, zoom: 15 });
        })
        .catch((error) => console.warn('ошибка search', error));
      return toggleMap();
    }
    if (addresses.length > 1) {
      return toggleAddressesListInApp();
    }
    if (updatedIndex) {
      const currentAddress = addresses.find((item) => item.uuid === updatedIndex);

      dispatch(
        setCurrentCity({
          id: currentAddress?.city_id as number,
          name: currentAddress?.city_name as string,
        }),
      );

      yaMapSearch({ text: `Казахстан, ${currentAddress?.city_name} ${currentAddress?.name}`, limit: 1 })
        .then((res) => {
          setLocation({ center: res[0].geometry?.coordinates as LngLat, zoom: 15 });
        })
        .catch((error) => console.warn('ошибка search', error));
    } else {
      setAddressName(addresses[0].name);
      setUpdatedIndex(addresses[0].uuid);

      const currentAddress = addresses[0];

      dispatch(
        setCurrentCity({
          id: currentAddress?.city_id as number,
          name: currentAddress?.city_name as string,
        }),
      );

      yaMapSearch({ text: `Казахстан, ${currentAddress?.city_name} ${currentAddress?.name}`, limit: 1 })
        .then((res) => {
          setLocation({ center: res[0].geometry?.coordinates as LngLat, zoom: 15 });
        })
        .catch((error) => console.warn('ошибка search', error));
      toggleMap();
    }
  };

  const handleAddMoreClick = () => {
    toggleMap();
    setAddressName(undefined);
    setUpdatedIndex(undefined);
  };

  const handleSetCommunicationTabClick = (value: number) => {
    dispatch(setOrderIsAllowed(value === 1));
    dispatch(setPhoneError(''));
  };

  const handleGetGeoCoordsFromNative = () => {
    if (addresses.length > 0) return;
    setActionForNative(NativeActions.GET_GEO_COORDS);
  };

  const handleChangeIsClientAssistantEnabled = () => {
    dispatch(setOrderIsClientAssistantEnabled(!isClientAssistantEnabled));
  };

  const handlePaymentsInfoButtonClick = () => {
    dispatch(setOrderIsClientAssistantEnabled(true));
    toggleClientAssistantInfo();
  };

  const headerSubtext = (
    <div className={styles.client_assistant_info}>
      {Array.from({ length: 4 }).map((_, index) => {
        return <span key={index}>{t(`page.order.client_assistant_info.${index}`)}</span>;
      })}
    </div>
  );

  const handleOnUpdateAddress = (address: AddressesItem) => {
    setAddressName(address.name);
    toggleAddressesListInApp();
    toggleMap();
    setUpdatedIndex(address.uuid);

    const currentAddress = addresses.find((item) => item.uuid === address.uuid);

    yaMapSearch({ text: `Казахстан, ${currentAddress?.city_name} ${currentAddress?.name}`, limit: 1 })
      .then((res) => {
        setLocation({ center: res[0].geometry?.coordinates as LngLat, zoom: 15 });
      })
      .catch((error) => console.warn('ошибка search', error));

    dispatch(
      setCurrentCity({
        id: currentAddress?.city_id as number,
        name: currentAddress?.city_name as string,
      }),
    );
  };

  const handleRemoveAddressClick = (index: number) => {
    const newAddresses = addresses.filter((_, addressIndex) => addressIndex !== index);
    dispatch(setOrderAddresses(newAddresses));
  };

  const handleSetGeolocation = async (data: { longitude: number; latitude: number }) => {
    if (mapIsOpen) return;
    if ((!data.latitude && !data.latitude) || !data) return;

    const currentGeo = [data.longitude, data.latitude];
    try {
      const [cityData, searchResults] = await Promise.all([
        getCurrentCity({ longitude: data.longitude, latitude: data.latitude }),
        yaMapSearch({ text: `${currentGeo}`, limit: 1 }),
      ]);

      const city = cityData.data?.city;

      const addressesFromSearch = searchResults.map((res: any) => ({
        coord: res.geometry?.coordinates as number[],
        name: formatAddressName(res.properties.name) as string,
        city_id: city?.id,
        city_name: city?.name,
        uuid: uuidv4(),
      }));

      if (city) {
        dispatch(setCurrentCity({ id: city?.id, name: city?.name }));
      }

      if (updatedIndex === undefined) {
        dispatch(setOrderAddresses(addressesFromSearch));
      }
    } catch (error) {
      console.warn('ошибка search', error);
    }
  };

  const handleBackClick = () => {
    navigate('/order/second-step');
    dispatch(updateStepCount(1));
  };

  React.useEffect(() => {
    if (!!addresses.length) return;
    if (hasGeoPermission === false) {
      if (permissionGeoIsRequested) return;
      setPermissionGeoIsRequested(true);
      dispatch(toggleGeoInApp(true));
    }
    if (hasGeoPermission === true) {
      if (firstCoordIsGetting) return;
      setActionForNative(NativeActions.GET_GEO_COORDS);
    }
  }, [addresses, hasGeoPermission, firstCoordIsGetting, permissionGeoIsRequested]);

  useNativeHandler<{ longitude: number; latitude: number }>(null, NativeActions.GET_GEO_COORDS, (data) => {
    if (mapIsOpen) return;
    setFirstCoordIsGetting(true);
    return handleSetGeolocation(data);
  });

  useNativeHandler(null, NativeActions.BACK_TAP, () => {
    if (mapIsOpen) {
      return toggleMap();
    }
    if (addressesListInAppIsOpen) {
      return toggleAddressesListInApp();
    }
    return handleBackClick();
  });

  return (
    <>
      <Input
        inputType={InputType.MAP}
        label={t('page.order.labels.address')}
        icon='place'
        value={addresses?.map((item) => item.name).join(' → ') ?? ''}
        readOnly
        placeholder={t('page.order.placeholders.address', { city: currentCity?.name })}
        labels={labels}
        onClick={onAddressesInputClick}
        subtext={t('page.order.hints.address')}
        rightIcon={
          !!addresses.length && addresses.length < 5 ? (
            <Icon size={20} iconColor='#828291' className='mgl-3' onClick={handleAddMoreClick}>
              add
            </Icon>
          ) : undefined
        }
      />

      <Tabs
        isFullPosition
        header={t('page.order.labels.communication_way')}
        list={getCommunicationWayList(t)}
        currentTab={isAllowed ? 1 : 0}
        onSetCurrentTab={(value) => handleSetCommunicationTabClick(value as number)}
      />

      {isAllowed ? (
        <div className={styles.phone_holder}>
          <InputPhone
            icon={
              <div className={styles.phone_prefix + ' mgr-2'}>
                <img src={'/images/country_kz_2.svg'} alt='' />
                <div>{'+7'}</div>
              </div>
            }
            errorMessage={phoneError}
            placeholder='000 000-00-00'
            mask={'(000) 000-00-00'}
            disabled={!isDemoClient}
            type='tel'
            value={phone}
            name='phone'
            onChange={(event) => {
              dispatch(setOrderPhone(event.target.value));
              dispatch(setPhoneError(''));
            }}
          />
        </div>
      ) : null}

      {mapIsOpen && (
        <YaMap
          addresses={addresses}
          onClose={handleCloseMap}
          currentCity={currentCity}
          onSetAddressName={setAddressName}
          addressName={addressName}
          updatedIndex={updatedIndex}
          location={location}
          onSetLocation={setLocation}
        />
      )}

      {!isAllowed ? (
        <NewList
          hasSubtitleClick
          title={t('page.order.labels.client_assistant')}
          subtitle={t('page.order.placeholders.client_assistant')}
          labels={labels}
          onClick={handleChangeIsClientAssistantEnabled}
          controlIcon={
            <CheckboxInput
              isNew
              checked={isClientAssistantEnabled ?? false}
              onChange={handleChangeIsClientAssistantEnabled}
            />
          }
          onSubtitleClick={(event) => {
            event.stopPropagation();
            toggleClientAssistantInfo();
          }}
        />
      ) : null}

      <InApp
        open={openClientAssistantInfoInAppIsOpen}
        onClose={toggleClientAssistantInfo}
        image='/illustrations/listening-to-feedback-3.svg'
        headerText={t('page.order.labels.client_assistant')}
        headerSubtext={headerSubtext}
      >
        <Button
          hasSpace
          text={t('page.order.select_client_assistant_button')}
          onClick={handlePaymentsInfoButtonClick}
        />
      </InApp>

      <InApp open={addressesListInAppIsOpen} onClose={toggleAddressesListInApp}>
        <div className={styles.inApp_buttons}>
          {!!addresses.length && (
            <ul className={styles.addresses_holder}>
              {addresses.map((address, index) => {
                return (
                  <li key={index}>
                    <Input
                      readOnly
                      hasSpace={false}
                      value={addresses[index].name}
                      onClick={() => handleOnUpdateAddress(address)}
                      icon={<div className={styles.input_count}>{index + 1}</div>}
                      rightIcon={
                        <Icon
                          size={20}
                          iconColor='#FF1507'
                          className='mgl-3'
                          onClick={(event) => {
                            event.stopPropagation();
                            handleRemoveAddressClick(index);
                            // remove(index)
                          }}
                        >
                          delete
                        </Icon>
                      }
                    />
                  </li>
                );
              })}
            </ul>
          )}

          {addresses?.length < 5 && (
            <Button
              buttonType={NewButtonType.WRAPPED}
              buttonColor={NewButtonColor.GRAY}
              text={t('page.order.add_more_button')}
              onClick={() => {
                handleAddMoreClick();
                toggleAddressesListInApp();
              }}
              rightIcon={
                <Icon size={20} iconColor='#2B2B2B'>
                  add
                </Icon>
              }
              hasSpace
            />
          )}
        </div>
      </InApp>

      <GeolocationRequest onGetGeoCoords={handleGetGeoCoordsFromNative} onClose={toggleMap} />
    </>
  );
}
