import {BaseResponse} from "shared/api";
import {api}          from "shared/api/api";
import {Nullable}     from "../../../../shared/model";

export interface RegionItemType {
  id: number;
  name: string;
  region_id?: number;
  is_selected: boolean;
}

export interface CountryItemType {
  id: number;
  name: string;
  currency: string;
  phone_code: string;
  phone_mask: string;
  flag: string;
  is_selected: boolean;
}

interface CitySelectResponse {
  country: Nullable<{
    id: number;
    name: string;
    flag: string;
  }>;
  regions: RegionItemType[];
}

interface CountrySelectResponse {
  countries: CountryItemType[]
}

const citySelectApi = api.injectEndpoints({
  endpoints: (build) => ({
    fetchRegionList: build.query<CitySelectResponse | undefined, { id?: number }>({
      query:             ({id}) => `app/reference/region/list?city_id=${id}`,
      transformResponse: (response: BaseResponse<CitySelectResponse>) => response.status ? response.content : undefined,
    }),

    fetchCitiesByRegionList: build.query<{ cities: { id: number, name: string }[] } | undefined, {
      id?: number,
      query?: string
    }>({
      query:             ({
        id,
        query,
      }) => `app/reference/city/list${id ? `?region_id=${id}` : ""}${query ? `?query=${query}` : ""}`,
      transformResponse: (response: BaseResponse<{
        cities: { id: number, name: string }[]
      }>) => response.status ? response.content : undefined,
    }),

    fetchCountryList: build.query<CountrySelectResponse | undefined, void>({
      query:             () => "app/reference/country/list",
      transformResponse: (response: BaseResponse<CountrySelectResponse>) => response.status ? response.content : undefined,
    }),
  }),
});

export const {
  useFetchRegionListQuery,
  useLazyFetchCitiesByRegionListQuery,
  useLazyFetchCountryListQuery,
} = citySelectApi;