import {api, BaseResponse, RequestMethod} from "shared/api/api";

export enum CreditScheduleState {
  PAID = "paid",
  NOT_PAID = "not_paid",
  ERROR = "error",
}

interface SchedulePaymentItem {
  id: number;
  date: string;
  sum: number;
  state: CreditScheduleState;
}


interface CreditScheduleResponse {
  payments: SchedulePaymentItem[];
  buttons: string[];
  subtitle: string;
}

const creditsScheduleApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCreditPaymentsSchedule: build.query<BaseResponse<CreditScheduleResponse>, number>({
      query:             (id) => ({
        url:    "pro/balance/credit/payments/schedule",
        method: RequestMethod.PUT,
        body:   {credit_id: id},
      }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {useGetCreditPaymentsScheduleQuery} = creditsScheduleApi;