import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Label from '../label/Label';
import { imageUrl, setActionForNative } from '../../lib';
import { ServiceMediaListItem } from './model/serviceMediaListItem';
import { MainPageTabType } from '../../model/types/main/mainPageType';
import { ImageResizeMode, NativeActions, useAppDispatch } from 'shared/model';
import { handleCategoryPath } from 'pages/categories/model/handleCategoryPath';
import { setPathFromMain } from 'shared/model/slices/generals';
import cn from 'classnames';
import styles from './ServiceMediaItem.module.scss';

interface Props {
  width?: number;
  item?: ServiceMediaListItem;
  type?: MainPageTabType;
  isWork?: boolean;
}

export default function ServiceMediaItem({ item, width, type, isWork }: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const handleOnCreateOrderClick = () => {
    dispatch(setPathFromMain(location.pathname));
  };

  const onCategoryNavigateClick = (id?: number) => {
    if (item?.is_ultra) {
      return navigate('/ultra');
    }
    if (!isWork) {
      // setActionForNative(NativeActions.SHOW_NAVBAR, { show: false });
      return setActionForNative(NativeActions.OPEN_LINK, { link: `naimi://categories-${type}?id=${id}` });
      // return navigate(`/categories-${type}/${id}`);
    }
    const data = {
      service_id: item?.id,
      work_id: item?.work_id,
    };
    if (type) {
      return handleCategoryPath(type, data, item?.has_specialists ?? false, navigate, handleOnCreateOrderClick);
    }
  };

  const ultraTitle = (
    <p className={styles.list_item_text}>
      {item?.title.split(' ').map((word, index, arr) => {
        const ultraWord = index === arr.length - 1;
        const space = index < arr.length - 1 ? ' ' : '';
        return ultraWord ? (
          <span key={index} className={styles.last_word}>
            {word}
          </span>
        ) : (
          word + space
        );
      })}
    </p>
  );

  return (
    <li
      key={item?.id}
      className={styles.list_item}
      style={{ minWidth: width ? `${width}px` : '' }}
      onClick={() => onCategoryNavigateClick(item?.id)}
    >
      {item?.labels && <Label labels={item.labels} clasNames={styles.list_item_labels} />}
      {item?.digest && (
        <img src={imageUrl(item.digest, 96 * 3, width ? width * 3 : 130 * 3, ImageResizeMode.CROP)} alt={''} />
      )}
      {item?.is_ultra ? (
        ultraTitle
      ) : (
        <>
          {!item?.subtitle ? (
            <span className={styles.list_item_text}>{item?.title}</span>
          ) : (
            <div className={styles.list_with_subtitle}>
              <span>{item.title}</span>
              <span className={cn(styles.subtitle, { [styles.is_ultra]: type === MainPageTabType.Ultra })}>
                {item.subtitle}
              </span>
            </div>
          )}
        </>
      )}
    </li>
  );
}
