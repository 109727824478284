import {BaseResponse, RequestMethod} from "shared/api";
import {api}                         from "shared/api/api";
import {Nullable}                    from "shared/model";
import {RegistrationProfile}         from "pages/registration/model/registration";

export enum RegistrationType {
  FREEDOM = "freedom",
  EGOV = "egov",
  DOCUMENT = "document",
  NON_RESIDENT = "non-resident",
  TRANSFER = "transfer",
  MANUAL = "manual",
}

export enum GenderType {
  MALE = "male",
  FEMALE = "female",
  EMPTY = "",
}

export interface RegistrationMethodItem {
  type: RegistrationType;
  title: string;
  subtitle: string;
  is_active: boolean;
  labels: { title: string, style: string }[];
  step: number;
  uri_closing_webview?: string;
  webview_url?: string;
}

export enum RegistrationMethodType {
  REGISTRATION = "registration",
  VERIFICATION = "verification",
}

export interface RegistrationMethods {
  methods: RegistrationMethodItem[];
  url?: string;
  profile?: Nullable<RegistrationProfile>;
  type?: RegistrationMethodType;
}

export interface EgovSmsResponse {
  request_id: string;
}

export interface EgovCodeResponse {
  url: string;
}

interface BiometricRecognitionResponse {
  status: string;
}

interface NonResidentRequest {
  first_name: string;
  last_name: string;
  gender: GenderType;
  birthday: string;
  ssn: string;
}

const registrationApi = api.injectEndpoints({
  endpoints: (build) => ({
    getRegistrationMethods:         build.query<BaseResponse<RegistrationMethods>, { system_version: string }>({
      query:             ({...params}) => ({
        url:    "pro/verification/methods",
        method: RequestMethod.PUT,
        body:   params,
      }),
      transformResponse: (response: BaseResponse<RegistrationMethods>) => response,
    }),
    sendEgovSms:                    build.mutation<BaseResponse<EgovSmsResponse>, { ssn: string, phone: string }>({
      query:             ({...params}) => ({
        url:    "pro/verification/egov/sms/send",
        method: RequestMethod.PUT,
        body:   params,
      }),
      transformResponse: (response: BaseResponse<EgovSmsResponse>) => response,
    }),
    sendEgovCode:                   build.mutation<BaseResponse<EgovCodeResponse>, {
      code: string,
      request_id: string
    }>({
      query:             ({...params}) => ({
        url:    "pro/verification/egov/sms/code",
        method: RequestMethod.PUT,
        body:   params,
      }),
      transformResponse: (response: BaseResponse<EgovCodeResponse>) => response,
    }),
    biometricRecognition:           build.mutation<BaseResponse<BiometricRecognitionResponse>, {
      session: string,
      from: Nullable<RegistrationType>,
    }>({
      query:             ({...params}) => ({
        url:    "pro/verification/recognition",
        method: RequestMethod.PUT,
        body:   params,
      }),
      transformResponse: (response: BaseResponse<BiometricRecognitionResponse>) => response,
    }),
    sendNonResidentForm:            build.mutation<BaseResponse<boolean>, NonResidentRequest>({
      query:             ({...params}) => ({
        url:    "pro/verification/non-resident",
        method: RequestMethod.PUT,
        body:   params,
      }),
      transformResponse: (response: BaseResponse<boolean>) => response,
    }),
    uploadNonResidentPhotoDocument: build.mutation<boolean, { document_front: string }>({
      query:             ({...params}) => ({
        url:    "pro/verification/non-resident/document/upload",
        method: RequestMethod.PUT,
        body:   params,
      }),
      transformResponse: (response: BaseResponse<boolean>) => response.status,
    }),
    uploadNonResidentFacePhoto:     build.mutation<boolean, { photo: string }>({
      query:             ({...params}) => ({
        url:    "pro/verification/non-resident/photo/upload",
        method: RequestMethod.PUT,
        body:   params,
      }),
      transformResponse: (response: BaseResponse<boolean>) => response.status,
    }),
    transferPersonalData:           build.mutation<BaseResponse<{ url: string }>, { ssn: string }>({
      query:             ({...params}) => ({
        url:    "pro/verification/transfer",
        method: RequestMethod.PUT,
        body:   params,
      }),
      transformResponse: (response: BaseResponse<{ url: string }>) => response,
    }),
    documentUpload:                 build.mutation<BaseResponse<{ url: string }>, {
      document_front: string,
      system_version: string,
    }>({
      query: ({...params}) => ({
        url:    "pro/verification/document/upload",
        method: RequestMethod.PUT,
        body:   params,
      }),
    }),
    sendManualForm:                 build.mutation<BaseResponse<boolean>, NonResidentRequest>({
      query:             ({...params}) => ({
        url:    "pro/verification/manual",
        method: RequestMethod.PUT,
        body:   params,
      }),
      transformResponse: (response: BaseResponse<boolean>) => response,
    }),
    uploadManualPhoto:              build.mutation<BaseResponse<boolean>, { photo: string }>({
      query:             ({...params}) => ({
        url:    "pro/verification/manual/photo/upload",
        method: RequestMethod.PUT,
        body:   params,
      }),
      transformResponse: (response: BaseResponse<boolean>) => response,
    }),
    freedomApprove:                 build.mutation<BaseResponse<boolean>, void>({
      query: () => ({
        url:    "pro/verification/prefill/approve",
        method: RequestMethod.PUT,
      }),
    }),
  }),
});

export const {
  useFreedomApproveMutation,
  useUploadManualPhotoMutation,
  useSendManualFormMutation,
  useDocumentUploadMutation,
  useTransferPersonalDataMutation,
  useUploadNonResidentPhotoDocumentMutation,
  useSendNonResidentFormMutation,
  useLazyGetRegistrationMethodsQuery,
  useSendEgovSmsMutation,
  useSendEgovCodeMutation,
  useBiometricRecognitionMutation,
  useUploadNonResidentFacePhotoMutation,
} = registrationApi;