import React                          from "react";
import {useTranslation}               from "react-i18next";
import {SkeletonLayout}               from "entities/skeleton";
import {Icon}                         from "shared/v12ui";
import {formatRate}                   from "shared/lib";
import {useAppSelector, selectLocale} from "shared/model";
import {ClientProfileItem}            from "../../api/clientProfileApi";
import styles                         from "./InfoBlock.module.scss";


interface Props {
  loading: boolean;
  clientProfile?: ClientProfileItem;
}

const i18n_prefix = "page.client.profile."

function InfoBlock({loading, clientProfile}: Props) {
  const {t} = useTranslation();
  const locale = useAppSelector(selectLocale);

  const reviewsCount = locale === "ru"
    ? `(${t(`${i18n_prefix}rating.value.keyWithCount`, {count: clientProfile?.reviews_overview.nmb_reviews})})`
    : t(`${i18n_prefix}rating`, {count: clientProfile?.reviews_overview.nmb_reviews});

  return <div className={styles.profile_info}>
    {loading ? (
      <SkeletonLayout
        borderRadius={60}
        width={120}
        height={120}
        theme="dark"
        containerClass={styles.avatar_holder}
      />
    ) : (
      <div className={styles.avatar_holder}>
        <Icon className={styles.avatar}>user</Icon>
      </div>
    )}

    <div className={styles.name_holder}>
      {loading ? <>
          <SkeletonLayout theme="dark" width={180} height={28} borderRadius={6} />
          <div className={styles.skeleton_rating}>
            <SkeletonLayout height={17} borderRadius={6} theme="dark" />
            <SkeletonLayout height={17} borderRadius={6} theme="dark" />
          </div>
        </>
        : <>
          <span className={styles.name}>{clientProfile?.client.name}</span>
          <div className={styles.info_content_rating}>
            <Icon size={12} className="mgr-1">star_filled</Icon>

            {!clientProfile?.reviews_overview.nmb_reviews
              ? <span className={styles.newbie}>{t(`${i18n_prefix}newbie`)}</span>
              : <div className={styles.rating}>
                <span>{formatRate(clientProfile?.client?.rate)}</span>

                <span>{reviewsCount}</span>
              </div>}
          </div>
        </>}
    </div>
  </div>
}

export default React.memo(InfoBlock);