import {useFetchAppProfileMutation, useFetchProProfileMutation} from "shared/api";
import {Nullable, AppMode}                                      from "shared/model";

export const fetchProfile = ({appMode}: { appMode: Nullable<string> }) => {
  const [fetchSpecialistProfile, {data: profile, isLoading}] = (() => {
    const [fetchAppProfile, {data: appProfile, isLoading: appProfileIsLoading}] = useFetchAppProfileMutation();
    const [fetchProProfile, {data: proProfile, isLoading: proProfileIsLoading}] = useFetchProProfileMutation();

    if (appMode === AppMode.CLIENT) {
      return [fetchAppProfile, {data: appProfile, isLoading: appProfileIsLoading}];
    } else {
      return [fetchProProfile, {data: proProfile, isLoading: proProfileIsLoading}];
    }
  })();

  return {fetchSpecialistProfile, profile, isLoading}
}