import React from 'react';
import { Link } from 'react-router-dom';
import { Label, LabelItem } from '../index';
import { Button, NewButtonColor } from '../../v12ui';
import { NewButtonSize } from '../../v12ui/button/model/newButtonSize';
import { SkeletonLayout } from '../../../entities/skeleton';
import { getRandomNumberRange } from '../../lib';
import styles from './HeaderBlock.module.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  children?: React.ReactNode;
  description?: string;
  isLoading?: boolean;
}

export default function HeaderBlock({ title, isLoading, children, ...props }: Props) {
  const slots = React.Children.toArray(children).reduce((acc, child) => {
    if (React.isValidElement(child)) {
      if (child.type === HeaderBlock.Button) acc.button = child;
      if (child.type === HeaderBlock.Label) acc.label = child;
      if (child.type === HeaderBlock.Description) acc.description = child;
      if (child.type === HeaderBlock.Link) acc.link = child;
    }
    return acc;
  }, {} as { button?: React.ReactNode; label?: React.ReactNode; description?: React.ReactNode; link?: React.ReactNode });

  return (
    <>
      {isLoading ? (
        <SkeletonLayout theme={'dark'} borderRadius={6} height={21} width={getRandomNumberRange(70, 150)} />
      ) : (
        <div className={styles.header_block_wrapper} {...props}>
          <div className={styles.header_holder}>
            <span className={styles.header_title}>{title}</span>
            <div className={styles.header_children_margin}>{slots.label}</div>
            <div className={styles.header_children_margin}>{slots.button}</div>
          </div>
          {slots.description}
          {slots.link}
        </div>
      )}
    </>
  );
}

HeaderBlock.Button = function HeaderButton({
  text,
  onClick,
}: {
  text: string;
  onClick: (event: React.MouseEvent<Element, MouseEvent>) => void;
}) {
  return (
    <Button
      isRounded
      type='button'
      buttonColor={NewButtonColor.GRAY}
      buttonSize={NewButtonSize.Small}
      onClick={onClick}
      text={text}
    />
  );
};

HeaderBlock.Label = function HeaderBlockLabel({ labels }: { labels: LabelItem[] }) {
  return <Label labels={labels} />;
};

HeaderBlock.Description = function HeaderBlockDescription({ description }: { description: string }) {
  return (
    <div className={styles.description_holder}>
      <span className={styles.description_text}>{description}</span>
    </div>
  );
};

HeaderBlock.Link = function HeaderBlockLink({ linkText, linkTo }: { linkText: string; linkTo: string }) {
  return (
    <Link to={linkTo} className={styles.description_link}>
      {linkText}
    </Link>
  );
};
