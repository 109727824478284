import React from 'react';
import { createPortal } from 'react-dom';
import {
  useAppDispatch,
  useAppSelector,
  removeNotification,
  selectNotificationList,
  NativeActions,
  setApiSession,
  Nullable,
} from 'shared/model';
import { Icon, IconType } from 'shared/v12ui';
import { setActionForNative, useNativeHandler } from 'shared/lib';
import cn from 'classnames';
import styles from './styles.module.scss';

interface Props {
  bottomPosition?: number;
  onRemoveToastClick?: (id?: string) => void;
}

function Toast({ bottomPosition, onRemoveToastClick = () => {} }: Props) {
  const dispatch = useAppDispatch();
  const timeoutListener = React.useRef<NodeJS.Timeout>();
  const notificationMessages = useAppSelector(selectNotificationList);

  const handleRemoveNotification = React.useCallback(() => {
    if (notificationMessages?.type === 'demoLogin') {
      return setActionForNative(NativeActions.LOGIN);
    }
    return dispatch(removeNotification());
  }, [dispatch, notificationMessages?.type]);

  const handleRemoveToastClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation();
    onRemoveToastClick(notificationMessages?.id);
    handleRemoveNotification();
  };

  const handleCloseToastClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation();
    dispatch(removeNotification());
  };

  React.useEffect(() => {
    if (notificationMessages?.type === 'demoLogin') return;
    if (notificationMessages) {
      timeoutListener.current = setTimeout(() => {
        handleRemoveNotification();
      }, 2750);
    } else {
      clearTimeout(timeoutListener.current);
    }

    return () => {
      clearTimeout(timeoutListener.current);
    };
  }, [handleRemoveNotification, notificationMessages]);

  useNativeHandler<{ session: Nullable<string>; is_logged_in: boolean }>(null, NativeActions.LOGIN, (data) => {
    if (data.is_logged_in) {
      if (data.session) {
        dispatch(setApiSession(data.session));
      }
    }
  });

  return (
    <>
      {notificationMessages &&
        createPortal(
          <div
            onClick={handleRemoveNotification}
            style={{ bottom: `${bottomPosition}px` }}
            className={cn(styles.wrapper, {
              [styles.remove_type]: notificationMessages.type === 'remove',
              [styles.demo_login]: notificationMessages.type === 'demoLogin',
            })}
          >
            {(notificationMessages.type === 'default' || !notificationMessages.type) && notificationMessages?.icon && (
              <Icon size={16} className='mgr-2'>
                {notificationMessages?.icon ?? 'info'}
              </Icon>
            )}

            {notificationMessages.type === 'remove' && (
              <Icon type={IconType.ROUNDED} isRemoveToastIcon iconColor={'#FFFFFF'}>
                delete
              </Icon>
            )}

            {notificationMessages.type === 'demoLogin' && (
              <Icon type={IconType.ROUNDED} isRemoveToastIcon iconColor={'#FFFFFF'}>
                {notificationMessages.icon}
              </Icon>
            )}

            <div
              className={cn(styles.text, {
                [styles.remove_type]: notificationMessages.type === 'remove',
                [styles.demo_login]: notificationMessages.type === 'demoLogin',
              })}
            >
              {notificationMessages.type === 'demoLogin' &&
                notificationMessages.text.split(' ').map((item, index, array) => {
                  if (index + 1 === array.length) {
                    return (
                      <span
                        key={index}
                        style={{
                          textDecoration: 'underline',
                          fontWeight: 700,
                        }}
                      >
                        {item}
                      </span>
                    );
                  }
                  return (
                    <span key={index} style={{ marginRight: '.2rem' }}>
                      {item}
                    </span>
                  );
                })}

              {notificationMessages.type === 'remove' &&
                notificationMessages.text.split('.').map((item, index) => {
                  if (index === 1) {
                    return (
                      <span
                        key={index}
                        onClick={handleRemoveToastClick}
                        style={{
                          textDecoration: 'underline',
                          marginLeft: '.2rem',
                        }}
                      >
                        {item}
                      </span>
                    );
                  }
                  return <React.Fragment key={index}>{`${item}.`}</React.Fragment>;
                })}

              {(notificationMessages.type === 'default' || !notificationMessages.type) && notificationMessages.text}
            </div>

            {['remove', 'demoLogin'].includes(notificationMessages.type as string) && (
              <Icon onClick={handleCloseToastClick} className={styles.close_icon}>
                close
              </Icon>
            )}
          </div>,
          document.body,
        )}
    </>
  );
}

export default React.memo(Toast);
