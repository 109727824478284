import React from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderBlock } from 'shared/ui/headerBlock';
import { PopularTags } from 'shared/ui/popularTags';
import { RecentViewItem } from 'shared/ui/popularTags/model/recentViewItem';
import { Button, Icon, NewList } from 'shared/v12ui';
import { getRandomNumberRange } from 'shared/lib';
import { MainPageTabType } from 'shared/model/types/main/mainPageType';
import { SkeletonLayout } from 'entities/skeleton';
import { SystemResponse } from 'entities/general';
import { ServiceView } from 'features/main/mainServiceViewed';
import { ClearSearchHistory } from 'features/main/searchHistoryClear';
import { SearchResultItemList } from '../api/mainSearchApi';
import { handleCategoryPath } from 'pages/categories/model/handleCategoryPath';
import { useAppDispatch, useAppSelector } from 'shared/model';
import { useLocation, useNavigate } from 'react-router-dom';
import { setPathFromMain } from 'shared/model/slices/generals';
import styles from './searchContent.module.scss';
import { selectSearchQuery } from 'shared/model/slices/queries';

interface Props {
  popular?: RecentViewItem[];
  searchList?: SearchResultItemList[];
  searchValue: string;
  type: MainPageTabType;
  isLoading: boolean;
  recentViewList?: RecentViewItem[];
}

const SearchContent = React.memo(
  ({ searchList, popular, recentViewList, type, searchValue, isLoading = false }: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const searchQuery = useAppSelector(selectSearchQuery);

    const handleOnCreateOrderClick = () => {
      dispatch(setPathFromMain(location.pathname));
    };

    const handleOnSearchResultClick = (hasSpecialists: boolean, workId?: number) => {
      const data = { work_id: workId };
      handleCategoryPath(type, data, hasSpecialists, navigate, handleOnCreateOrderClick);
    };

    return (
      <>
        <div className={styles.content}>
          {!searchValue && !searchQuery && (
            <>
              {!!recentViewList?.length && (
                <ClearSearchHistory>
                  {(onClick) => (
                    <div style={{ gridGap: 0 }}>
                      <HeaderBlock
                        title={t('page.search_main.history')}
                        isLoading={isLoading}
                        style={{ marginBottom: '.4rem' }}
                      >
                        <HeaderBlock.Button
                          text={t('page.search_main.clear_button')}
                          onClick={(event) => onClick(event, undefined, type)}
                        />
                      </HeaderBlock>

                      {isLoading ? (
                        <SkeletonPopular />
                      ) : (
                        <PopularTags
                          type={type}
                          withoutHeightLimit
                          tags={recentViewList}
                          onRemoveClick={(event) => {
                            event.stopPropagation();
                            onClick(event);
                          }}
                        />
                      )}
                    </div>
                  )}
                </ClearSearchHistory>
              )}

              <div style={{ gridGap: !isLoading ? 0 : undefined }}>
                <HeaderBlock
                  title={t('page.search_main.popular')}
                  isLoading={isLoading}
                  style={{ marginBottom: '.4rem' }}
                />

                {isLoading ? <SkeletonPopular /> : <PopularTags type={type} withoutHeightLimit tags={popular} />}
              </div>
            </>
          )}

          {searchValue && (
            <ul>
              {isLoading ? (
                <SkeletonSearch />
              ) : (
                <>
                  {searchList?.map((serviceItem, index) => {
                    const viewBody = {
                      tab: type,
                      work_id: serviceItem.work_id,
                      is_search: true,
                      has_specialists: serviceItem.has_specialists,
                    };
                    return (
                      <ServiceView key={index} body={viewBody}>
                        {(onClick) => (
                          <NewList
                            title={serviceItem.title}
                            subtitle={serviceItem.subtitle ?? ''}
                            controlIcon={<Icon>keyboard_arrow_right</Icon>}
                            onClick={(event) => {
                              onClick(event);
                              handleOnSearchResultClick(serviceItem.has_specialists, serviceItem.work_id);
                            }}
                          />
                        )}
                      </ServiceView>
                    );
                  })}

                  {!searchList?.length && !searchQuery && (
                    <SystemResponse
                      title={t('page.search_main.empty_search_result.title')}
                      description={t('page.search_main.empty_search_result.description')}
                      icon={'search'}
                    />
                  )}
                </>
              )}
            </ul>
          )}
        </div>

        {type !== MainPageTabType.Ultra && (
          <div className={styles.footer}>
            <Button
              text={
                type === MainPageTabType.Service
                  ? t('page.search_main.create_button.order')
                  : t('page.search_main.create_button.vacancy')
              }
              hasSpace
            />
          </div>
        )}
      </>
    );
  },
);

export default SearchContent;

function SkeletonPopular() {
  return (
    <div className={styles.skeleton_popular}>
      {Array.from({ length: 6 }).map((_, index) => {
        return <SkeletonLayout key={index} height={36} width={getRandomNumberRange(90, 120)} borderRadius={30} />;
      })}
    </div>
  );
}

function SkeletonSearch() {
  return (
    <>
      {Array.from({ length: 8 }).map((_, index) => {
        return (
          <li key={index} className={styles.skeleton_item}>
            <SkeletonLayout height={21} theme='dark' borderRadius={6} />
            <SkeletonLayout width={20} height={20} theme='dark' borderRadius={4} />
          </li>
        );
      })}
    </>
  );
}
