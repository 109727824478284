import React         from "react";
import {useNavigate} from "react-router-dom";
import {imageUrl}    from "shared/lib";
import {Nullable}    from "shared/model";
import styles        from "./ServicePromoBlock.module.scss";


interface Props {
  digest: Nullable<string>;
}

export default function ServicePromoBlock({digest}: Props) {
  const navigate = useNavigate();

  const handleNavigateToUltra = () => navigate("/ultra");

  return <div className={styles.promo_block} onClick={handleNavigateToUltra}>
    {digest && <img src={imageUrl(digest)} alt={""} />}
  </div>
};