import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LabelItem } from 'shared/ui';
import { Nullable } from 'shared/model';

export interface WorkUnitItemType {
  id: Nullable<number>;
  name: string;
  is_selected: boolean;
}

export interface AddressesItem {
  uuid?: string;
  coord: number[];
  name: string;
  city_id?: number;
  city_name?: string;
}

export interface AddressItemType {
  address: string;
  longitude: number;
  latitude: number;
  entrance: Nullable<number>;
  apartment: Nullable<number>;
  city: {
    id: number;
    name: string;
  };
}

export interface ImageFromNativeItem {
  uuid?: string;
  type: string;
  base64?: string;
  percent?: number;
  isFailed?: boolean;
  digest?: string;
}

export interface OrderFirstStepData {
  description: string;
  isFast: boolean;
  date?: string;
  dateTab?: string;
  dateAndTime?: {
    date?: string;
    time?: string;
  };
  media: ImageFromNativeItem[];
  labels?: LabelItem[];
}

export interface OrderSecondStepData {
  priceUnit?: string;
  recommendedPrice: Nullable<{
    price?: string;
    work?: string;
  }>;
  price?: number;
  isAppPaymentWay?: boolean;
  workUnits: WorkUnitItemType[];
  price_limit?: {
    min: number;
    max: number;
  };
  labels?: LabelItem[];
}

export interface OrderThirdStepData {
  addresses: AddressesItem[];
  isAllowed: boolean;
  isClientAssistantEnabled: boolean;
  phone?: string;
  labels?: LabelItem[];
}

interface OrderFormState {
  currentCity?: { id?: number; name?: string };
  stepCount: number;
  firstStep: OrderFirstStepData;
  secondStep: OrderSecondStepData;
  thirdStep: OrderThirdStepData;
  analytics?: { [key: string]: unknown };
  descriptionError: string;
  priceError: string;
  phoneError: string;
  ticketId?: string;
  successResponse: boolean;
  openMediaTransfer: boolean;
  orderDataIsReceived: boolean;
  moveToSecondStep: boolean;
}

const initialState: OrderFormState = {
  currentCity: undefined,
  stepCount: 0,
  firstStep: {
    description: '',
    isFast: false,
    date: undefined,
    dateTab: undefined,
    dateAndTime: undefined,
    media: [],
    labels: [],
  },
  secondStep: {
    priceUnit: undefined,
    recommendedPrice: null,
    price: undefined,
    isAppPaymentWay: false,
    workUnits: [],
    price_limit: undefined,
    labels: [],
  },
  thirdStep: {
    addresses: [],
    isAllowed: false,
    phone: undefined,
    labels: [],
    isClientAssistantEnabled: false,
  },
  analytics: undefined,
  descriptionError: '',
  priceError: '',
  phoneError: '',
  ticketId: undefined,
  successResponse: false,
  openMediaTransfer: false,
  orderDataIsReceived: false,
  moveToSecondStep: false,
};

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setMoveToSecondStep(state, action: PayloadAction<boolean>) {
      state.moveToSecondStep = action.payload;
    },
    setOrderDescription(state, action: PayloadAction<string>) {
      state.firstStep.description = action.payload;
    },
    setOrderMedia(state, action: PayloadAction<ImageFromNativeItem[]>) {
      state.firstStep.media = action.payload;
    },
    setOrderFirstStepLabels(state, action: PayloadAction<LabelItem[]>) {
      state.firstStep.labels = action.payload;
    },
    setOrderDate(state, action: PayloadAction<string | undefined>) {
      state.firstStep.date = action.payload;
    },
    setOrderIsFast(state, action: PayloadAction<boolean>) {
      state.firstStep.isFast = action.payload;
    },
    setOrderDateAndTime(state, action: PayloadAction<{ date?: string; time?: string } | undefined>) {
      state.firstStep.dateAndTime = action.payload;
    },
    setOrderDateTab(state, action: PayloadAction<string | undefined>) {
      state.firstStep.dateTab = action.payload;
    },
    setOrderPrice(state, action: PayloadAction<number | undefined>) {
      state.secondStep.price = action.payload;
    },
    setOrderIsAppPaymentWay(state, action: PayloadAction<boolean>) {
      state.secondStep.isAppPaymentWay = action.payload;
    },
    setOrderSecondStepLabels(state, action: PayloadAction<LabelItem[]>) {
      state.secondStep.labels = action.payload;
    },
    setOrderPriceLimits(state, action: PayloadAction<{ min: number; max: number } | undefined>) {
      state.secondStep.price_limit = action.payload;
    },
    setOrderPriceUnit(state, action: PayloadAction<string | undefined>) {
      state.secondStep.priceUnit = action.payload;
    },
    setOrderWorkUnit(state, action: PayloadAction<WorkUnitItemType[]>) {
      state.secondStep.workUnits = action.payload;
    },
    setOrderRecommendedPrice(state, action: PayloadAction<Nullable<{ price?: string; work?: string }>>) {
      state.secondStep.recommendedPrice = action.payload;
    },
    setOrderIsAllowed(state, action: PayloadAction<boolean>) {
      state.thirdStep.isAllowed = action.payload;
    },
    setOrderPhone(state, action: PayloadAction<string | undefined>) {
      state.thirdStep.phone = action.payload;
    },
    setOrderThirdStepLabels(state, action: PayloadAction<LabelItem[]>) {
      state.thirdStep.labels = action.payload;
    },
    setOrderAddresses(state, action: PayloadAction<AddressesItem[]>) {
      state.thirdStep.addresses = action.payload;
    },
    setOrderIsClientAssistantEnabled(state, action: PayloadAction<boolean>) {
      state.thirdStep.isClientAssistantEnabled = action.payload;
    },
    updateStepCount(state, action: PayloadAction<Partial<OrderFormState['stepCount']>>) {
      state.stepCount = action.payload;
    },
    updateAnalytics(state, action: PayloadAction<Partial<OrderFormState['analytics']>>) {
      state.analytics = action.payload;
    },
    setCurrentCity(state, action: PayloadAction<Partial<OrderFormState['currentCity']>>) {
      state.currentCity = action.payload;
    },
    setDescriptionError(state, action: PayloadAction<string>) {
      state.descriptionError = action.payload;
    },
    setPriceError(state, action: PayloadAction<string>) {
      state.priceError = action.payload;
    },
    setPhoneError(state, action: PayloadAction<string>) {
      state.phoneError = action.payload;
    },
    setSuccessResponse(state, action: PayloadAction<boolean>) {
      state.successResponse = action.payload;
    },
    setOpenMediaTransfer(state, action: PayloadAction<boolean>) {
      state.openMediaTransfer = action.payload;
    },
    setOrderDataIsReceived(state, action: PayloadAction<boolean>) {
      state.orderDataIsReceived = action.payload;
    },
  },
});

export const {
  setMoveToSecondStep,
  setOrderDescription,
  setOrderMedia,
  setOrderFirstStepLabels,
  setOrderDate,
  setOrderIsFast,
  setOrderDateAndTime,
  setOrderDateTab,
  setOrderPrice,
  setOrderIsAppPaymentWay,
  setOrderSecondStepLabels,
  setOrderPriceLimits,
  setOrderPriceUnit,
  setOrderWorkUnit,
  setOrderRecommendedPrice,
  setOrderIsAllowed,
  setOrderPhone,
  setOrderThirdStepLabels,
  setOrderAddresses,
  setOrderIsClientAssistantEnabled,
  updateStepCount,
  updateAnalytics,
  setCurrentCity,
  setDescriptionError,
  setPriceError,
  setPhoneError,
  setSuccessResponse,
  setOpenMediaTransfer,
  setOrderDataIsReceived,
} = slice.actions;
export default slice.reducer;

export const selectCurrentCity = (state: { order: typeof initialState }) => state.order.currentCity;
export const selectStepCount = (state: { order: typeof initialState }) => state.order.stepCount;
export const selectDescriptionError = (state: { order: typeof initialState }) => state.order.descriptionError;
export const selectPriceError = (state: { order: typeof initialState }) => state.order.priceError;
export const selectPhoneError = (state: { order: typeof initialState }) => state.order.phoneError;
export const selectSuccessResponse = (state: { order: typeof initialState }) => state.order.successResponse;
export const selectOrderAnalytics = (state: { order: typeof initialState }) => state.order.analytics;
export const selectOpenMediaTransfer = (state: { order: typeof initialState }) => state.order.openMediaTransfer;
export const selectOrderDescription = (state: { order: typeof initialState }) => state.order.firstStep.description;
export const selectOrderMedia = (state: { order: typeof initialState }) => state.order.firstStep.media;
export const selectOrderFirstStepLabels = (state: { order: typeof initialState }) => state.order.firstStep.labels;
export const selectOrderDate = (state: { order: typeof initialState }) => state.order.firstStep.date;
export const selectOrderIsFast = (state: { order: typeof initialState }) => state.order.firstStep.isFast;
export const selectOrderDateAndTime = (state: { order: typeof initialState }) => state.order.firstStep.dateAndTime;
export const selectOrderDateTab = (state: { order: typeof initialState }) => state.order.firstStep.dateTab;
export const selectOrderPrice = (state: { order: typeof initialState }) => state.order.secondStep.price;
export const selectOrderIsAppPaymentWay = (state: { order: typeof initialState }) =>
  state.order.secondStep.isAppPaymentWay;
export const selectOrderSecondStepLabels = (state: { order: typeof initialState }) => state.order.secondStep.labels;
export const selectOrderPriceLimits = (state: { order: typeof initialState }) => state.order.secondStep.price_limit;
export const selectOrderPriceUnit = (state: { order: typeof initialState }) => state.order.secondStep.priceUnit;
export const selectOrderWorkUnits = (state: { order: typeof initialState }) => state.order.secondStep.workUnits;
export const selectOrderRecommendedPrice = (state: { order: typeof initialState }) =>
  state.order.secondStep.recommendedPrice;
export const selectOrderIsAllowed = (state: { order: typeof initialState }) => state.order.thirdStep.isAllowed;
export const selectOrderPhone = (state: { order: typeof initialState }) => state.order.thirdStep.phone;
export const selectOrderThirdStepLabels = (state: { order: typeof initialState }) => state.order.thirdStep.labels;
export const selectOrderAddresses = (state: { order: typeof initialState }) => state.order.thirdStep.addresses;
export const selectOrderIsClientAssistantEnabled = (state: { order: typeof initialState }) =>
  state.order.thirdStep.isClientAssistantEnabled;
export const selectOrderDataIsReceived = (state: { order: typeof initialState }) => state.order.orderDataIsReceived;
export const selectMoveToSecondStep = (state: { order: typeof initialState }) => state.order.moveToSecondStep;
