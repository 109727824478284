import {search, suggest}                 from "../lib";
import {SearchOptions, SearchResponse}   from "@yandex/ymaps3-types/imperative/search";
import {Config}                          from "@yandex/ymaps3-types/imperative/config";
import {SuggestOptions, SuggestResponse} from "@yandex/ymaps3-types/imperative/suggest";

export function yaMapSearch(options: SearchOptions, config?: Config | undefined): Promise<SearchResponse> {
  //@ts-ignore
  return search(options, config);
}

export function yaMapSuggest(options: SuggestOptions, config?: Config | undefined): Promise<SuggestResponse> {
  //@ts-ignore
  return suggest(options, config);
}

export function formatAddressName(text?: string) {
  if (!text) return;
  if (text.includes("улица")) {
    return text.replace("улица", "ул.");
  }
  if (text.includes("проспект")) {
    return text.replace("проспект", "п.");
  }
  return text;
}