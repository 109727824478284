import React from 'react';
import { useSetHeaders } from 'shared/lib';
import { Nullable, useAppDispatch, useAppSelector } from 'shared/model';
import { v4 as uuidv4 } from 'uuid';
import { getCurrentDate, getCurrentTime } from 'features/order/pickDate/model/getCurrentTime';
import { useFetchOrderDetailsQuery } from '../../api/orderDetailsApi';
import {
  selectOrderDataIsReceived,
  setCurrentCity,
  setOrderAddresses,
  setOrderDataIsReceived,
  setOrderDate,
  setOrderDateAndTime,
  setOrderDateTab,
  setOrderDescription,
  setOrderFirstStepLabels,
  setOrderIsAllowed,
  setOrderIsAppPaymentWay,
  setOrderIsFast,
  setOrderMedia,
  setOrderPhone,
  setOrderPrice,
  setOrderPriceLimits,
  setOrderPriceUnit,
  setOrderRecommendedPrice,
  setOrderSecondStepLabels,
  setOrderThirdStepLabels,
  setOrderWorkUnit,
  updateAnalytics,
} from '../slice/orderSlice';

export function useSetDataFromRequest({
  setTicketBonus,
}: {
  setTicketBonus: React.Dispatch<React.SetStateAction<Nullable<number>>>;
}) {
  const dispatch = useAppDispatch();
  const { isSuccess, ticketId, workId, isDemo } = useSetHeaders();
  const dataIsReceived = useAppSelector(selectOrderDataIsReceived);
  const { data: orderDetails } = useFetchOrderDetailsQuery(
    {
      ticket_id: ticketId ?? undefined,
      work_id: workId ?? undefined,
    },
    { skip: !isSuccess },
  );

  React.useEffect(() => {
    if (dataIsReceived) return;
    if (orderDetails) {
      const { content, analytics } = orderDetails;
      const { ticket, labels, price_limit, city } = content;

      const media =
        ticket.media?.map((item) => ({
          uuid: undefined,
          type: item.type === 'photo' ? 'img' : 'video',
          base64: undefined,
          percent: 100,
          isFailed: false,
          digest: item.digest,
        })) ?? [];

      const priceLimits = price_limit ? { min: price_limit.min, max: price_limit.max } : undefined;

      const addresses =
        ticket.addresses?.map((address) => ({
          coord: [+address.longitude, +address.latitude],
          name: address.address,
          city_id: address.city.id,
          city_name: address.city.name,
          uuid: uuidv4(),
        })) ?? [];

      dispatch(setOrderDescription(ticket.description));
      dispatch(setOrderMedia(media));
      dispatch(setOrderFirstStepLabels(labels.media ?? []));
      dispatch(setOrderDate(!ticket.time && ticket.date ? ticket.date : getCurrentTime()));
      dispatch(setOrderIsFast(ticket.is_fast ?? false));
      dispatch(setOrderDateAndTime(setDateAndTime(ticket.date, ticket.time)));
      dispatch(setOrderDateTab(setDateTab(ticket.is_fast ?? false, ticket.date, ticket.time)));

      dispatch(setOrderPrice(typeof ticket.price_to === 'number' ? ticket.price_to : undefined));
      dispatch(setOrderIsAppPaymentWay(ticket.is_app_payment_way));
      dispatch(setOrderSecondStepLabels(labels.app_payment ?? []));
      dispatch(setOrderPriceLimits(priceLimits));
      dispatch(setOrderPriceUnit(ticket.work_units?.find((item) => item.is_selected)?.name));
      dispatch(setOrderWorkUnit(ticket.work_units ?? []));
      dispatch(setOrderRecommendedPrice(ticket.recommended_price));

      dispatch(setOrderIsAllowed(ticket.is_allowed));
      dispatch(setOrderPhone(isDemo ? undefined : ticket.phone.slice(2)));
      dispatch(setOrderThirdStepLabels(labels.addresses ?? []));
      dispatch(setOrderAddresses(addresses));

      dispatch(setCurrentCity(city));
      dispatch(updateAnalytics(analytics));
      dispatch(setOrderDataIsReceived(true));

      if (content.first_ticket_bonus) {
        setTicketBonus(content.first_ticket_bonus);
      }
    }
  }, [orderDetails, dataIsReceived]);

  return { orderDetails, city: orderDetails?.content.city, ticketId, workId, isDemo };
}

function setDateAndTime(date?: string, time?: string) {
  if (date && time) {
    const currentTime = time.replace('-', ' - ');
    return { date: date, time: currentTime };
  }
  return undefined;
}

function setDateTab(isFast: boolean, date?: string, time?: string) {
  if (isFast) return 'fast';

  if (date && time) {
    if (new Date(date) >= new Date(getCurrentDate())) {
      return 'selectDate';
    }
  }

  if (date && new Date(date) >= new Date(getCurrentDate())) {
    const currentDate = new Date(date);
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  return undefined;
}
