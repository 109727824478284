import React, { useState } from "react";
import { ServiceMediaListItem } from "../../shared/ui/serviceMediaItem/model/serviceMediaListItem";
import { ServiceMediaItem } from "../../shared/ui/serviceMediaItem";
import { MainPageTabType } from "shared/model/types/main/mainPageType";
import styles from "./PersonalServicesList.module.scss";


interface Props {
  popularServices?: ServiceMediaListItem[];
  type: MainPageTabType;
  isWork?: boolean;
}

export default function PersonalServicesList({ popularServices, type, isWork = false }: Props) {
  const listRef = React.useRef<HTMLUListElement>(null);
  const [itemWidth, setItemWidth] = useState<number>();

  React.useLayoutEffect(() => {
    if (!listRef.current) return;
    const width = listRef.current.offsetWidth / 3 - 16;
    setItemWidth(width);
  }, []);

  return <ul ref={listRef} className={styles.list}>
    {popularServices?.map((popular, index) => {
      return <ServiceMediaItem
        key={`${popular.id}-${index}`}
        item={popular}
        width={itemWidth}
        type={type}
        isWork={isWork}
      />
    })}
  </ul>
};