import { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'shared/model';
import {
  selectRefetchComponentMainPages,
  selectRefetchMainPages,
  setRefetchComponentMainPages,
  setRefetchMainPages,
} from 'shared/model/slices/generals';
import { MainPageTabType } from 'shared/model/types/main/mainPageType';
import { useFetchCurrentMainPageQuery } from '../api/mainLayoutApi';

export default function useGetMainPageData(type: MainPageTabType) {
  const dispatch = useAppDispatch();
  const { service, vacancy, ultra } = useAppSelector(selectRefetchComponentMainPages);
  const { service: fullService, vacancy: fullVacancy, ultra: fullUltra } = useAppSelector(selectRefetchMainPages);

  const currentPanelFlag = () => {
    switch (type) {
      case MainPageTabType.Service:
        return service;
      case MainPageTabType.Vacancy:
        return vacancy;
      case MainPageTabType.Ultra:
        return ultra;
      default:
        return false;
    }
  };

  const {
    data: pageData,
    isLoading: pageDataIsLoading,
    isFetching,
    refetch,
  } = useFetchCurrentMainPageQuery({ tab: type }, { pollingInterval: 3000000 });

  const { data: actionPanel, isLoading: actionPanelIsLoading } = useFetchCurrentMainPageQuery(
    {
      tab: type,
      component: currentPanelFlag(),
    },
    { skip: !service || !pageData },
  );

  const data = useMemo(() => {
    return { ...pageData, ...actionPanel };
  }, [pageData, actionPanel]);

  useEffect(() => {
    if (!actionPanel || !type || !currentPanelFlag()) return;

    dispatch(
      setRefetchComponentMainPages({
        service: type === MainPageTabType.Service ? false : service,
        vacancy: type === MainPageTabType.Vacancy ? false : vacancy,
        ultra: type === MainPageTabType.Ultra ? false : ultra,
      }),
    );
  }, [actionPanel, type]);

  useEffect(() => {
    if (!fullService && !fullUltra && !fullVacancy) return;

    refetch();

    dispatch(
      setRefetchMainPages({
        service: type === MainPageTabType.Service ? false : fullService,
        vacancy: type === MainPageTabType.Vacancy ? false : fullVacancy,
        ultra: type === MainPageTabType.Ultra ? false : fullUltra,
      }),
    );
  }, [fullService, fullUltra, fullVacancy, type]);

  return { data, isLoading: pageDataIsLoading || isFetching, actionPanelIsLoading };
}
