import { api, EEndpointTags, RequestMethod } from "shared/api/api";
import { MainPageTabType } from "shared/model/types/main/mainPageType";


export interface LogServiceViewingRequestBody {
  work_id?: number;
  service_id?: number;
  has_specialists?: boolean;
  is_search?: boolean;
  tab: MainPageTabType;
}

const clearSearchHistoryApi = api.injectEndpoints({
  endpoints: (build) => ({
    clearHistory: build.mutation<boolean, { id?: number, tab?: MainPageTabType }>({
      query: ({ ...params }) => ({
        url: "app/main/search/history/clear",
        method: RequestMethod.PUT,
        body: params,
      }),
      invalidatesTags: [{ type: EEndpointTags.MAIN_SEARCH_TABS, id: "LIST" }],
    }),
  }),
});

export const { useClearHistoryMutation } = clearSearchHistoryApi;