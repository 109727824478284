export const order = {
  header:                       {
    title:    "Қызметке өтінім",
    subtitle: [
      "1-қадам 3-тен — сипаттама",
      "2-қадам 3-тен — төлем",
      "3-қадам 3-тен — байланыс",
    ],
  },
  labels:                       {
    description:       "Сипаттама",
    date_and_time:     "Қызмет қашан қажет",
    date:              "Күні",
    time:              "Уақыты",
    select_date:       "Күні мен уақытын таңдаңыз",
    media:             "Фото және видео",
    price:             "Сіздің бағаңыз",
    online_payment:    "Онлайн төлем",
    online_pay:        "Онлайн төлеймін",
    client_assistant:  "AI көмекшісі",
    to:                "дейін",
    communication_way: "Сізбен қалай байланысуға болады?",
    address:           "Мекенжай",
  },
  placeholders:                 {
    description:    "Не істеу қажет?",
    online_payment: "Қызметтерді төлеудің ең ыңғайлы, қауіпсіз және тиімді тәсілі. Толығырақ",
    client_assistant: "Сіз үшін мамандармен сөйлесіп, сізге ең жақсы таңдауды ұсынады. Толығырақ",
    address:        "қ. {{city}}, мекенжай көрсетілмеген",
  },
  hints:                        {
    description: "Сипаттаманы толығырақ көрсетсеңіз, әр маманмен мәліметтерді нақтылаудың қажеті болмайды",
    media:       "Фото немесе видео қосу арқылы мамандарға не істеу керегін көрсете аласыз",
    address:     "Мекенжайды көрсеткен жөн, бұл мамандарға жол мен уақытты жоспарлауға көмектеседі",
  },
  date_list:                    {
    fast:        "Шұғыл",
    today:       "Бүгін",
    tomorrow:    "Ертең",
    select_date: "Күні мен уақытын таңдау",
  },
  day_list:                     [
    "жс",
    "дс",
    "сс",
    "ср",
    "бс",
    "жм",
    "сб",
  ],
  payment_info:                 [
    "— Егер жұмыс аяқталған болса, жедел аударымдар жасаңыз",
    "— Алдын ала төлем жасап, оны жұмыс аяқталғаннан кейін растаңыз. Бұл бағаның өсуі мен қызмет сапасының төмендеуін болдырмайды",
    "— Онлайн төлемдерде Freedom Bank кешбэктері мен naimi бонустары жиі қолжетімді",
    "— Несие немесе бөліп төлеу, Kaspi, Freedom SuperApp және басқа да әдістермен төлеуге болады",
  ],
  client_assistant_info:                 [
    "— Ассистент уақытыңызды үнемдейді",
    "— Сіз үшін мамандардың ұсыныстарын зерттеп, келіссөздер жүргізеді",
    "— Назар аудару қажет болса, сізді шақырады",
    "— Маманды таңдау бойынша ұсыныстардың қорытындысын жасайды",
  ],
  communication_way:            {
    chat: "Чатта жазыңыз",
    call: "Маған қоңырау шалыңыз",
  },
  add_more_button:              "Тағы қосу",
  select_online_payment_button: "Онлайн төлемді таңдау",
  select_client_assistant_button: "Включить ассистента",
  create_button:                "Өтінім жасау",
  close_order:                  {
    title:              "Нақты шыққыңыз келеді ме?",
    subtext:            "Сіз сенімдісіз бе? Өтінім жасаңыз және ұсыныстарды көріңіз. Бұл тегін — сіз ештеңе жоғалтпайсыз!",
    subtext_with_bonus: {
      first_part:  "Алғашқы өтінімді жасағанда клиенттердің шығып кетуі біз үшін өкінішті, сондықтан сізге",
      second_part: "{{bonus}} бонус",
      third_part:  "онлайн төлем кезінде үнемдеу үшін берілді. Өтінім жасаңыз және ұсыныстарды көріңіз. Бұл тегін — сіз ештеңе жоғалтпайсыз!",
    },
  },
  first_step_description:       {
    update: "Сипаттаманы толықтыру",
    submit: "Сол күйінде қалдыру",
  },
  save_order:                   {
    remind_later:   "Кейін еске салу",
    set_permission: "Хабарламаларға рұқсат беру",
  },
  required_field:               "Міндетті толтырылатын жол",
  price_limit:                  {
    min: "Баға 100 ₸-ден төмен болмауы керек",
    max: "Баға {{sum}} ₸-ден жоғары болмауы керек",
  },
  phone_error:                  "Телефон нөмірін көрсетіңіз",
  create_order_button:          "Өтінім жасау",
}