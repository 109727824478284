import {BaseResponse, RequestMethod} from "shared/api";
import {api}                         from "shared/api/api";
import {Nullable}                    from "shared/model";
import {LabelItem}                   from "shared/ui";
import {AddressItemType}             from "../model/slice/orderSlice";


export interface AlertItemType {
  type?: string,
  is_closable?: boolean,
  style?: string,
  image?: string,
  buttons: {
    title: string;
    link: Nullable<string>;
    style: string;
  }[];
  image_info?: {
    width: number;
    height: number;
    mode: string;
    type: string;
  }
  title: string,
  text: string,
}

export interface OrderRecommendationResponse {
  alert: Nullable<AlertItemType>;
  ticket: Nullable<RecommendedOrder>;
}

export interface WorkUnitItemType {
  id: Nullable<number>;
  name: string;
  is_selected: boolean;
}

interface RecommendedOrder {
  recommended_price: Nullable<{
    price?: string;
    work?: string;
  }>;
  work_id: Nullable<number>;
  work_units: WorkUnitItemType[];
}

interface OrderTicketDetails extends RecommendedOrder {
  description: string;
  price_to: Nullable<number>;
  phone: string;
  is_app_payment_way: boolean;
  is_allowed: boolean;
  is_client_assistant_enabled: boolean;
  addresses?: {
    address: string;
    city: { id: number, name: string };
    latitude: string;
    longitude: string;
  }[];
  date?: string;
  is_fast?: boolean;
  media: { type: string, digest: string }[];
  time?: string;
}

export interface OrderDetails {
  city: { id: number, name: string };
  ticket: OrderTicketDetails,
  first_ticket_bonus: Nullable<number>;
  labels: {
    media?: LabelItem[];
    app_payment?: LabelItem[];
    addresses?: LabelItem[];
  };
  price_limit?: {
    min: number;
    max: number;
  },
}

interface SaveOrderResponse {
  is_vacancy: boolean;
  ticket_id: number;
  alert?: AlertItemType;
}

interface OrderCreateAddressItem extends Omit<AddressItemType, "city" | "apartment" | "entrance"> {
}

interface SaveOrderRequestBody {
  ticket_id?: number;
  work_id?: number;
  is_allowed: boolean;
  is_client_assistant_enabled: boolean;
  description: string;
  addresses?: OrderCreateAddressItem[];
  work_unit_id: Nullable<number>;
  price_to?: number;
  media?: {
    digest: string;
    type: string;
  }[];
  date?: string;
  time?: string;
  is_app_payment_way: boolean;
  is_fast: boolean;
}

interface CurrentCityResponse {
  city: {
    id: number;
    name: string;
    region_id: number;
    country_id: number;
  }
}

const orderDetailsApi = api.injectEndpoints({
  endpoints: (build) => ({
    fetchOrderDetails: build.query<BaseResponse<OrderDetails> | undefined, { ticket_id?: number, work_id?: number }>({
      query:             ({...params}) => ({
        url:    "app/order/service",
        method: RequestMethod.PUT,
        body:   params,
      }),
      transformResponse: (response: BaseResponse<OrderDetails>) => response ? response : undefined,
    }),

    fetchOrderRecommendation: build.mutation<BaseResponse<OrderRecommendationResponse> | undefined, {
      description: string,
      ticket_id?: number,
      work_id?: number,
      media?: { type: string, digest: string }[],
    }>({
      query:             ({...params}) => ({
        url:    "app/order/recommendation",
        method: RequestMethod.PUT,
        body:   params,
      }),
      transformResponse: (response: BaseResponse<OrderRecommendationResponse>) => response ? response : undefined,
    }),

    fetchCloseOrderInfo: build.mutation<boolean, void>({
      query:             () => ({
        url:    "app/order/close",
        method: RequestMethod.PUT,
      }),
      transformResponse: (response: BaseResponse<null>) => response.status,
    }),

    saveOrder:        build.mutation<BaseResponse<SaveOrderResponse> | undefined, SaveOrderRequestBody>({
      query:             ({...params}) => ({
        url:    "app/order/save",
        method: RequestMethod.PUT,
        body:   params,
      }),
      transformResponse: (response: BaseResponse<SaveOrderResponse>) => response,
    }),
    fetchCurrentCity: build.query<CurrentCityResponse | undefined, { latitude: number, longitude: number }>({
      query:             ({latitude, longitude}) => ({
        url: `app/reference/city?latitude=${latitude}&longitude=${longitude}`,
      }),
      transformResponse: (response: BaseResponse<CurrentCityResponse>) => response.status ? response.content : undefined,
    }),
  }),
});

export const {
  useFetchOrderDetailsQuery,
  useFetchCloseOrderInfoMutation,
  useFetchOrderRecommendationMutation,
  useSaveOrderMutation,
  useLazyFetchCurrentCityQuery,
} = orderDetailsApi;
