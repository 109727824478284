import React                                                                        from "react";
import {useNavigate}                                                                from "react-router-dom";
import {useTranslation}                                                             from "react-i18next";
import {
  useFetchCatalogueServicesListQuery,
  useSaveCatalogueServiceMutation,
}                                                                                   from "./api/purchaseServicesListApi";
import {SkeletonLayout}                                                             from "entities/skeleton";
import {NewPageLayout}                                                              from "entities/general";
import {onParentClick, setActionForNative, useNativeHandler, useSkeletonThrottling} from "shared/lib";
import {NativeActions, useAppSelector, selectPurchaseIsLoading}                     from "shared/model";
import {Button, Icon}                                                               from "shared/v12ui";
import ListItemRender                                                               from "./ui/ListItemRender";
import styles                                                                       from "./styles.module.scss";


const i18n_prefix = "page.specialist.my_category."
const PurchaseServicesList = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const checkboxListRefs = React.useRef<HTMLInputElement[]>([]);
  const purchaseInProgress = useAppSelector(selectPurchaseIsLoading);

  const {
    data:       services,
    isLoading:  servicesIsLoading,
    isFetching: servicesIsFetching,
    refetch:    refetchServices,
  } = useFetchCatalogueServicesListQuery();

  const {extendedLoading} = useSkeletonThrottling({isLoading: servicesIsLoading || servicesIsFetching});
  const [saveServices, {isLoading: saveServicesLoading, isSuccess}] = useSaveCatalogueServiceMutation();

  const [checkedServices, setCheckedServices] = React.useState<number[]>([]);

  const handleBackClick = () => navigate("/tariffs/catalogue");

  const handleParentClick = (event: React.MouseEvent<HTMLDivElement>, index: number) => onParentClick(event, index, checkboxListRefs);

  const handleServicesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newIds = new Set(checkedServices);
    if (event.target.checked) {
      newIds.add(+event.target.id);
    } else {
      newIds.delete(+event.target.id);
    }
    setCheckedServices([...newIds]);
  }

  const handleSaveServices = () => saveServices(checkedServices);

  const handleMoveToCategoriesClick = () => setActionForNative(NativeActions.OPEN_NEWSLINE_FILTERS);

  React.useEffect(() => {
    if (services?.content.services) {
      const newIds: number[] = [];
      services.content.services.map((service) => service.is_selected ? newIds.push(service.id) : service);
      setCheckedServices(newIds);
    }
  }, [services]);

  React.useEffect(() => {
    if (isSuccess) {
      navigate("/tariffs/catalogue");
    }
  }, [isSuccess, navigate]);

  useNativeHandler(null, NativeActions.BACK_TAP, () => {
    if (purchaseInProgress) return;
    return handleBackClick();
  });

  useNativeHandler<{ is_changed: boolean }>("tariffs", NativeActions.OPEN_NEWSLINE_FILTERS, data => {
    if (data.is_changed) {
      refetchServices();
    }
  });

  const footer = <>
    {extendedLoading ? <SkeletonLayout height={53} borderRadius={50} theme="dark" /> :
      services?.content.services && services.content.services.length > 0 && <div className={styles.footer}>
        <div>{t(`${i18n_prefix}footer`)}</div>

        <Button
          disabled={extendedLoading}
          loading={saveServicesLoading}
          text={t(`${i18n_prefix}button`)}
          onClick={handleSaveServices}
        />
      </div>}
  </>

  return <NewPageLayout
    headerTitle={t(`${i18n_prefix}header.title`)}
    headerSubtitle={t(`${i18n_prefix}header.subtitle`)}
    headerLeftIcon={<Icon iconColor="#2B2B2B" onClick={handleBackClick}>keyboard_arrow_left</Icon>}
    footer={footer}
    isLoading={extendedLoading}
  >
    <>
      {extendedLoading
        ? <>{[...Array(3)].map((_, index) => <SkeletonLayout containerClass="mgb-6" key={index} height={22} />)}</>

        : !services?.content.services.length ?
          <div className={styles.period_plug}>
            <div className={styles.plug_content}>
              <Icon size={40} className="text gray mgb-3">search</Icon>
              <div className={styles.plug_content_text}>
                <div className={styles.plug_content_title}>{t(`${i18n_prefix}plug.title`)}</div>
                <div className={styles.plug_content_description}>
                  {t(`${i18n_prefix}plug.subtitle`)}
                </div>
              </div>
              <Button
                isRounded
                text={t(`${i18n_prefix}plug.button`)}
                onClick={handleMoveToCategoriesClick}
              />
            </div>
          </div>

          : services?.content.services.map((service, index) =>
            <ListItemRender
              index={index}
              key={service.id}
              id={service.id}
              title={service.name}
              currentRef={checkboxListRefs}
              isChecked={checkedServices.includes(service.id)}
              onParentClick={handleParentClick}
              onChange={handleServicesChange}
            />,
          )}
    </>
  </NewPageLayout>
};

export default PurchaseServicesList;