import React                                                        from "react";
import {LogServiceViewingRequestBody, useLogServiceViewingMutation} from "./api/api";


interface Props {
  children: (onClick: (event: React.MouseEvent) => void) => React.ReactNode,
  body: LogServiceViewingRequestBody;
}

export default function ServiceView({body, children}: Props) {

  const [logViewing] = useLogServiceViewingMutation();

  const handleLogViewing = () => {
    logViewing(body);
  }

  return <>
    {children((_e) => handleLogViewing())}
  </>
};