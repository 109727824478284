import React                                                          from "react";
import {InApp, TextBlock}                                             from "shared/ui";
import {CloseRegistrationInApp}                                       from "components/CloseRegistrationInApp";
import {NativeActions, Nullable, useAppSelector}                      from "shared/model";
import {
  RegistrationMethodType,
  RegistrationType,
  useBiometricRecognitionMutation,
  useUploadManualPhotoMutation,
}                                                                     from "services/registration";
import {setActionForNative, useNativeHandler}                         from "shared/lib";
import {Button, NewList}                                              from "shared/v12ui";
import {selectMethodType, selectBiometricUrl, selectRegistrationType} from "pages/registration/model/registration";
import {useTranslation}                                               from "react-i18next";
import {useUploadNonResidentFacePhotoMutation}                        from "services/registration/registration";
import {NewPageLayout}                                                from "entities/general";
import {ImageLoadError, ImageLoading}                                 from "entities/registration";
import {useNavigate}                                                  from "react-router-dom";
import styles                                                         from "./styles.module.scss";


interface Props {
  onClick?: () => void;
}

export default function IdentityVerification({
  onClick = () => {
  },
}: Props) {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const registrationMethod = useAppSelector(selectRegistrationType);
  const biometricUrl = useAppSelector(selectBiometricUrl);
  const methodType = useAppSelector(selectMethodType);

  const [checkBiometricRecognition, {
    isSuccess,
    data,
    isLoading: recognitionLoading,
  }] = useBiometricRecognitionMutation();
  const [uploadManualPhoto, {
    data:      manualPhotoData,
    isSuccess: successManualPhoto,
    isLoading: uploadManualPhotoLoading,
  }] = useUploadManualPhotoMutation();
  const [uploadFacePhoto, {
    isSuccess: uploadFacePhotoSuccess,
    isLoading: facePhotoIsLoading,
  }] = useUploadNonResidentFacePhotoMutation();

  const loading = recognitionLoading || uploadManualPhotoLoading || facePhotoIsLoading;

  const [biometricTryAgain, setBiometricTryAgain] = React.useState(false);
  const [inProgressingView, setIsProgressingView] = React.useState(false);
  const [faceError, setFaceError] = React.useState(false);
  const [photo, setPhoto] = React.useState<Nullable<string>>(null);
  const [photoIsLoading, setPhotoIsLoading] = React.useState(false);
  const [uploadError, setUploadError] = React.useState(false);

  const [session, setSession] = React.useState<Nullable<string>>(null);

  useNativeHandler<{ session: string | null }>("registration", NativeActions.OPEN_BIOMETRIC, (data) => {
    if (data.session && data.session !== "null") {
      return setSession(data.session);
    }
    if (data.session === "" || data.session === "null") {
      return setFaceError(true);
    }
  });


  React.useEffect(() => {
    if (session === null) return;
    if (session === "null") return;
    if (session && session.length > 0 && registrationMethod) {
      checkBiometricRecognition({session: session, from: registrationMethod});
    }
  }, [session, registrationMethod, checkBiometricRecognition]);

  const handleOpenOnboarding = () => setActionForNative(NativeActions.OPEN_ONBOARDING);

  const handleOpenTickets = () => setActionForNative(NativeActions.OPEN_TICKETS);

  const handleOnClick = () => {
    setFaceError(false);
    setBiometricTryAgain(false);
    if (registrationMethod === RegistrationType.DOCUMENT) {
      setActionForNative(NativeActions.OPEN_BIOMETRIC, {url: biometricUrl});
    } else {
      onClick();
    }
  }

  useNativeHandler<{ digest: string }>("registration", NativeActions.MAKE_FACE_PHOTO, data => {
    setPhoto(data.digest);
  });

  useNativeHandler("registration", NativeActions.OPEN_UPLOAD_LOADER, () => {
    if (typeof photo === "string") return;
    setPhotoIsLoading(true);
  });

  useNativeHandler("registration", NativeActions.OPEN_UPLOAD_ERROR, () => {
    if (typeof photo === "string") return;
    setUploadError(true);
  });

  React.useEffect(() => {
    if (manualPhotoData?.status && successManualPhoto) {
      setPhotoIsLoading(false);
      setIsProgressingView(true);
    }
  }, [manualPhotoData, successManualPhoto]);

  React.useEffect(() => {
    if (uploadFacePhotoSuccess) {
      setPhotoIsLoading(false);
      setIsProgressingView(true);
    }
  }, [uploadFacePhotoSuccess]);

  React.useEffect(() => {
    if (!photo) return;
    if (registrationMethod === RegistrationType.NON_RESIDENT) {
      uploadFacePhoto({photo});
    }
    if (registrationMethod === RegistrationType.MANUAL) {
      uploadManualPhoto({photo});
    }
  }, [photo, registrationMethod, uploadFacePhoto, uploadManualPhoto]);

  React.useEffect(() => {
    if (isSuccess) {
      if (data?.content?.status === "success" && registrationMethod !== RegistrationType.TRANSFER) {
        return handleOpenOnboarding();
      }
      if (data?.content?.status === "success" && registrationMethod === RegistrationType.TRANSFER) {
        navigate("/transfer-complete");
        // return setIsProgressingView(true);
      }
      if (data?.content?.status === "in_processing") { // лента заявок
        return setIsProgressingView(true);
      }
      if (data?.errors?.profile === "biometric_try_again") {
        return setBiometricTryAgain(true); // личность не подтверждена
      }
    }
  }, [data, isSuccess, navigate, registrationMethod]);

  React.useEffect(() => {
    if (registrationMethod && methodType !== RegistrationMethodType.VERIFICATION) {
      setActionForNative(NativeActions.ANALYTICS, {
        event:      "specialist registration method opened",
        properties: {
          "registration method": registrationMethod,
          "registration step":   "2",
        },
      });
    }
  }, [registrationMethod, methodType]);

  const handleTryAgain = () => {
    setUploadError(false);
    setPhotoIsLoading(false);
  }

  return <>
    {uploadError && <ImageLoadError onClick={handleTryAgain} />}

    {photoIsLoading ? <ImageLoading /> : <>{!inProgressingView
      ? <>
        <NewPageLayout
          headerTitle={t("component.identity.header")}
          headerRightIcon={<CloseRegistrationInApp />}
          footer={<Button
            text={t("component.identity.confirm_button")}
            onClick={handleOnClick}
            loading={loading}
          />}
        >
          <div className={styles.content}>
            <div className={styles.content_illustration}>
              <img src={"/illustrations/face-id-2.svg"} alt="img" />
            </div>

            <NewList
              hasSpace={false}
              icon="validation"
              title={registrationMethod === RegistrationType.EGOV || registrationMethod === RegistrationType.DOCUMENT
              || registrationMethod === RegistrationType.TRANSFER
                ? t("component.identity.list.item_1")
                : t("component.identity.list_photo.item_1")}
            />

            <NewList
              hasSpace={false}
              icon="validation"
              title={registrationMethod === RegistrationType.EGOV || registrationMethod === RegistrationType.DOCUMENT
              || registrationMethod === RegistrationType.TRANSFER
                ? t("component.identity.list.item_2")
                : t("component.identity.list_photo.item_2")}
            />

            <NewList
              hasSpace={false}
              icon="validation"
              title={registrationMethod === RegistrationType.EGOV || registrationMethod === RegistrationType.DOCUMENT
              || registrationMethod === RegistrationType.TRANSFER
                ? t("component.identity.list.item_3")
                : t("component.identity.list_photo.item_3")}
            />

            <TextBlock description={t("component.identity.text_block")} />
          </div>
        </NewPageLayout>

        <InApp
          image="/illustrations/no-conversation-3.svg"
          headerText={t("in_app.biometric_error.title")}
          headerSubtext={t("in_app.biometric_error.description")}
          open={biometricTryAgain}
          onClose={() => setBiometricTryAgain(false)}
        >
          <Button onClick={handleOnClick} hasSpace text={t("in_app.biometric_error.buttons.try_again")} />
        </InApp>

        <InApp
          image="/illustrations/no-conversation-3.svg"
          headerText={t("in_app.biometric_error.title_face")}
          headerSubtext={t("in_app.biometric_error.description_face")}
          open={faceError}
          onClose={() => setFaceError(false)}
        >
          <Button onClick={handleOnClick} hasSpace text={t("in_app.biometric_error.buttons.try_again")} />
        </InApp>
      </>

      : <div className={styles.inProgressing_wrapper}>
        <div className={styles.inProgressing_icon_holder}>
          <div className={styles.inProgressing_icon}>watch</div>
        </div>

        <div className={styles.inProgressing_text}>
          <div className={styles.inProgressing_text_title}>
            {t("component.identity.verification.title")}
          </div>

          <div className={styles.inProgressing_text_subtitle}>
            {registrationMethod === RegistrationType.TRANSFER && t("component.identity.verification.transfer_subtitle")}
            {registrationMethod === RegistrationType.NON_RESIDENT && t("component.identity.verification.manual_subtitle")}
            {registrationMethod === RegistrationType.MANUAL && t("component.identity.verification.manual_subtitle")}
            {registrationMethod === RegistrationType.EGOV && t("component.identity.verification.subtitle")}
            {registrationMethod === RegistrationType.DOCUMENT && t("component.identity.verification.manual_subtitle")}
          </div>
        </div>

        <Button
          isRounded
          hasSpace
          text={t("component.identity.verification.button")}
          onClick={handleOpenTickets}
        />

      </div>
    }</>}
  </>
}