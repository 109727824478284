export const main = {
  demo_login_request: 'Аккаунтыңыз бар ма? Кіру',
  interest_suggest: 'Сізді қызықтыруы мүмкін',
  interest_suggest_ultra: 'Танымал қызметтер',
  recent_views: 'Сіз жақында қарадыңыз',
  categories_list: 'Қызметтер каталогы',
  categories_list_ultra: 'Барлық санаттар',
  faq: 'Көмек',
  faq_show_more: 'Барлығын көрсету',
  promo_block: {
    ultra: {
      title: 'Ultra-да қажетті қызметті таппадыңыз ба?',
      description: 'Өтінім жасаңыз және жеке шеберлерден ұсыныстар алыңыз',
      button: 'Құру',
    },
  },
};
