import {api, BaseResponse, RequestMethod} from "shared/api/api";

interface PurchaseCatalogueServiceItem {
  id: number;
  name: string;
  description: string;
  color: string;
}

interface PurchaseCatalogue {
  title: string;
  subtitle: string;
  description: string;
  price_per_addition?: number;
  button: {
    title: string;
    subtitle?: string;
    is_disabled: boolean;
  };
  services: PurchaseCatalogueServiceItem[];
}

const purchaseCatalogueListApi = api.injectEndpoints({
  endpoints: (build) => ({
    fetchPurchaseCatalogueList: build.query<BaseResponse<PurchaseCatalogue>, null>({
      query:             () => ({
        url:    "pro/balance/purchase/catalogue",
        method: RequestMethod.PUT,
      }),
      keepUnusedDataFor: 0,
    }),

    removeCatalogueService: build.mutation<BaseResponse<boolean>, number>({
      query: (id) => ({
        url:    "pro/balance/purchase/catalogue/services/delete",
        method: RequestMethod.PUT,
        body:   {service_id: id},
      }),
    }),

    catalogueServicesCartInit: build.mutation<BaseResponse<{ payment_id: string }>, void>({
      query: () => ({
        url:    "pro/balance/purchase/catalogue/cart/init",
        method: RequestMethod.PUT,
      }),
    }),
  }),
});

export const {
  useFetchPurchaseCatalogueListQuery,
  useRemoveCatalogueServiceMutation,
  useCatalogueServicesCartInitMutation,
} = purchaseCatalogueListApi;