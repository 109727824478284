import {api, BaseResponse, RequestMethod} from "shared/api/api";


interface PurchaseFavouriteCatalogueService {
  services: {
    id: number;
    name: string;
    is_selected: boolean;
  }[];
}

const purchaseServicesListApi = api.injectEndpoints({
  endpoints: (build) => ({
    fetchCatalogueServicesList: build.query<BaseResponse<PurchaseFavouriteCatalogueService>, void>({
      query:             () => ({
        url:    "pro/balance/purchase/catalogue/services",
        method: RequestMethod.PUT,
      }),
      keepUnusedDataFor: 0,
    }),

    saveCatalogueService: build.mutation<BaseResponse<boolean>, number[]>({
      query: (services) => ({
        url:    "pro/balance/purchase/catalogue/services/save",
        method: RequestMethod.PUT,
        body:   {services},
      }),
    }),
  }),
});

export const {useFetchCatalogueServicesListQuery, useSaveCatalogueServiceMutation} = purchaseServicesListApi;