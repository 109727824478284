import {isRejectedWithValue, type Middleware, type MiddlewareAPI} from "@reduxjs/toolkit";
import {setErrorMessage}                                          from "pages/registration/model/registration";
import {setActionForNative}                                       from "shared/lib";
import {NativeActions}                                            from "shared/model";
import i18n                                                       from "i18next";

export const rtkQueryErrorLogger: Middleware = ({dispatch}: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    console.warn("We got a rejected action!", action);
  }


  if (action.meta?.baseQueryMeta?.response?.status === 200 && action.payload === undefined) {
    dispatch(setErrorMessage("Произошла неизвестная ошибка. Попробуйте через 5 минут."));
  }

  if (action.payload && action?.payload?.message) {
    dispatch(setErrorMessage(action?.payload?.message));
  }

  if (!action.payload || typeof action.payload !== "object" || !("data" in action.payload)) return next(action);
  const {data} = action.payload as { data: { status: boolean, message: string } }
  const response = action.meta?.baseQueryMeta?.response;

  if (data && !data.status && data.message) {
    dispatch(setErrorMessage(data.message));
  }
  if (response?.status === 403) {
    setActionForNative(NativeActions.LOGOUT);
  }
  if (response.status === 404) {
    dispatch(setErrorMessage(i18n.t("errors.not_found")));
  }
  if (response.status >= 500) {
    dispatch(setErrorMessage(i18n.t("errors.server_error")));
  }
  return next(action)
}