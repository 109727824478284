import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import { Icon, Input } from 'shared/v12ui';
import { NavigationTabs } from 'shared/ui/navigationTabs';
import { NativeActions, useAppDispatch, useAppSelector } from 'shared/model';
import { setActionForNative, useDebounceValue, useNativeHandler } from 'shared/lib';
import { MainPageTabType } from 'shared/model/types/main/mainPageType';
import { SearchResultItemList, useFetchMainSearchTabsQuery, useSearchServicesMutation } from './api/mainSearchApi';
import SearchContent from './ui/SearchContent';
import mainStyle from 'shared/styles/mainWithHeader.module.scss';
import styles from './MainSearch.module.scss';
import { selectSearchQuery, setSearchQuery } from 'shared/model/slices/queries';

const EMPTY_SEARCH_LIST = {
  list: [],
  count: 0,
};

export default function MainSearch() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { data, isLoading } = useFetchMainSearchTabsQuery();
  const [search, { data: searchResult, isLoading: searchIsLoading }] = useSearchServicesMutation();
  const searchQuery = useAppSelector(selectSearchQuery);

  const [query, setQuery] = React.useState('');
  const debouncingValue = useDebounceValue(query, 1000) as string;

  const [searchServiceList, setSearchServiceList] = React.useState<{ list: SearchResultItemList[]; count: number }>(
    EMPTY_SEARCH_LIST,
  );
  const [searchVacancyList, setSearchVacancyList] = React.useState<{ list: SearchResultItemList[]; count: number }>(
    EMPTY_SEARCH_LIST,
  );
  const [searchUltraList, setSearchUltraList] = React.useState<{ list: SearchResultItemList[]; count: number }>(
    EMPTY_SEARCH_LIST,
  );

  const handleSetQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!!searchQuery) {
      dispatch(setSearchQuery(undefined));
    }
    setQuery(event.target.value);
  };

  const handleBackClick = () => setActionForNative(NativeActions.TERMINATE);

  const handleClearSearchClick = () => {
    setQuery('');
    dispatch(setSearchQuery(undefined));
  };

  const currentTabs = React.useMemo(() => {
    if (!data?.tabs) return [];

    return data.tabs.map((tabItem) => {
      const { url, title } = tabItem;

      if (
        url.includes(MainPageTabType.Vacancy) &&
        typeof searchVacancyList.count === 'number' &&
        query.trim() !== '' &&
        !!searchVacancyList.list.length
      ) {
        return {
          ...tabItem,
          title: `${title} (${searchVacancyList.count})`,
        };
      }

      if (
        url.includes(MainPageTabType.Ultra) &&
        typeof searchUltraList.count === 'number' &&
        query.trim() !== '' &&
        !!searchUltraList.list.length
      ) {
        return {
          ...tabItem,
          title: `${title} (${searchUltraList.count})`,
        };
      }

      if (
        url === '/search/' &&
        typeof searchServiceList.count === 'number' &&
        query.trim() !== '' &&
        !!searchServiceList.list.length
      ) {
        return {
          ...tabItem,
          title: `${title} (${searchServiceList.count})`,
        };
      }

      return tabItem;
    });
  }, [data?.tabs, searchVacancyList, searchUltraList, searchServiceList, query]);

  React.useEffect(() => {
    if (debouncingValue) {
      search(debouncingValue);
    }
    if (debouncingValue.trim() === '') {
      setSearchServiceList(EMPTY_SEARCH_LIST);
      setSearchVacancyList(EMPTY_SEARCH_LIST);
      setSearchUltraList(EMPTY_SEARCH_LIST);
    }
  }, [debouncingValue]);

  React.useEffect(() => {
    if (!!searchQuery) {
      setQuery(searchQuery);
    }
  }, [searchQuery]);

  React.useEffect(() => {
    const result = searchResult?.result;
    if (result?.service.items) {
      setSearchServiceList({ list: result.service.items, count: result.service.count });
    }
    if (result?.vacancy.items) {
      setSearchVacancyList({ list: result.vacancy.items, count: result.vacancy.count });
    }
    if (result?.ultra.items) {
      setSearchUltraList({ list: result.ultra.items, count: result.ultra.count });
    }
  }, [searchResult]);

  React.useEffect(() => {
    setActionForNative(NativeActions.SHOW_NAVBAR, { show: false });
  }, []);

  useNativeHandler(null, NativeActions.BACK_TAP, () => {
    handleBackClick();
  });

  return (
    <div className={mainStyle.main_wrapper}>
      <div className={mainStyle.main_header}>
        <div className={mainStyle.search_holder}>
          <div className={styles.search}>
            <Icon onClick={handleBackClick}>keyboard_arrow_left</Icon>

            <Input
              placeholder={t('common.search')}
              icon={'search'}
              hasSpace={false}
              clearable={query !== ''}
              value={query}
              onChange={handleSetQuery}
              disabled={isLoading}
              onClearClick={handleClearSearchClick}
            />
          </div>
        </div>

        <NavigationTabs tabs={currentTabs} isLoading={isLoading} withoutIcon />
      </div>

      <div className={styles.page_wrapper}>
        <Routes>
          <Route
            path={'/'}
            element={
              <SearchContent
                type={MainPageTabType.Service}
                popular={data?.popular.service}
                isLoading={isLoading || searchIsLoading}
                searchValue={debouncingValue}
                searchList={searchServiceList.list}
                recentViewList={data?.history.service}
              />
            }
          />
          <Route
            path={'/vacancy'}
            element={
              <SearchContent
                type={MainPageTabType.Vacancy}
                popular={data?.popular.vacancy}
                isLoading={isLoading || searchIsLoading}
                searchValue={debouncingValue}
                searchList={searchVacancyList.list}
                recentViewList={data?.history.vacancy}
              />
            }
          />
          <Route
            path={'/ultra'}
            element={
              <SearchContent
                type={MainPageTabType.Ultra}
                popular={data?.popular.ultra}
                isLoading={isLoading || searchIsLoading}
                searchValue={debouncingValue}
                searchList={searchUltraList.list}
              />
            }
          />
        </Routes>
      </div>
    </div>
  );
}
