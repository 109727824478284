import * as React                                                             from "react";
import {useEffect, useState}                                                  from "react";
import {useNavigate, useParams}                                               from "react-router-dom";
import {useTranslation}                                                       from "react-i18next";
import {useGetCreditPaymentsScheduleQuery}                                    from "./api/creditsScheduleApi";
import {Button, Icon, NewButtonColor, NewButtonType}                          from "shared/v12ui";
import {LoadingScreen, NewPageLayout}                                         from "entities/general";
import {SkeletonLayout}                                                       from "entities/skeleton";
import {
  useNativeHandler,
  formatNumberWithSpaces,
  useCheckPaymentStatus,
  useOpenPayments,
}                                                                             from "shared/lib";
import {NativeActions, useAppSelector, PurchaseType, selectPurchaseIsLoading} from "shared/model";
import {useCreditCatInit}                                                     from "features/specialist/creditCartInit";
import CreditScheduleItem                                                     from "./ui/CreditScheduleItem";
import styles                                                                 from "./styles.module.scss";


interface ButtonItem {
  id: number;
  type: "credit_prepayment" | "loan_expired";
  sum?: number;
}

const i18n_prefix = "page.specialist.credits_schedule."
const CreditsSchedule = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {id: paramId} = useParams();
  const id = paramId ? parseInt(paramId) : null;
  const purchaseInProgress = useAppSelector(selectPurchaseIsLoading);

  const {
    data:      scheduleList,
    isLoading: scheduleListIsLoading,
    // refetch,
  } = useGetCreditPaymentsScheduleQuery(id as number);
  const {creditCartInit, creditCartInitIsLoading, creditCartInitResponse} = useCreditCatInit();
  const [buttons, setButtons] = useState<ButtonItem[]>([]);

  const handleBackClick = () => navigate("/credits");

  const handlePayClick = (type: "credit_prepayment" | "loan_expired") => {
    if (!id) return;
    return creditCartInit({id, type});
  }

  useEffect(() => {
    if (scheduleList && scheduleList.content && scheduleList.content.buttons) {
      const buttons = scheduleList.content.buttons;

      const currentButtons = buttons.map((button) => {
        const url = new URL(button, "naimi://");
        const params = new URLSearchParams(url.search);
        const idParam = params.get("credit_id") ?? 0;
        const typeParam = params.get("type");
        const sum = params.get("sum");

        return {id: +idParam, type: typeParam, sum}
      });
      setButtons(currentButtons as any);
    }
  }, [scheduleList]);

  useOpenPayments({
    paymentId: creditCartInitResponse?.content.payment_id,
    type:      PurchaseType.CREDIT_PREPAYMENT,
  });

  useNativeHandler(null, NativeActions.BACK_TAP, () => {
    if (purchaseInProgress) return;
    return handleBackClick();
  });

  const {paymentStatusLoading} = useCheckPaymentStatus({refetch: handleBackClick});

  const footer = <div className={styles.footer}>
    {scheduleListIsLoading ? <Button text="" loading disabled />

      : buttons.map((button, index) => {
        switch (button.type) {
          case "credit_prepayment":
            if (button.sum) {
              return <Button
                key={index}
                text={t(`${i18n_prefix}buttons.credit_prepayment`)}
                secondLine={t(`${i18n_prefix}buttons.price`, {price: `${button.sum}`})}
                loading={creditCartInitIsLoading}
                onClick={() => handlePayClick("credit_prepayment")}
              />
            }
            return <Button
              key={index}
              text={t(`${i18n_prefix}buttons.credit_prepayment`)}
              buttonType={NewButtonType.WRAPPED}
              buttonColor={NewButtonColor.GRAY}
              loading={creditCartInitIsLoading}
              onClick={() => handlePayClick("credit_prepayment")}
            />
          case "loan_expired":
            return <Button
              key={index}
              text={t(`${i18n_prefix}buttons.loan_expired`)}
              loading={creditCartInitIsLoading}
              onClick={() => handlePayClick("loan_expired")}
            />
          default:
            return null;
        }
      })}
  </div>

  return <>
    {paymentStatusLoading && <LoadingScreen />}

    <NewPageLayout
      isLoading={scheduleListIsLoading}
      headerTitle={t(`${i18n_prefix}header`)}
      headerSubtitle={scheduleList?.content.subtitle}
      headerLeftIcon={<Icon className="text black" onClick={handleBackClick}>keyboard_arrow_left</Icon>}
      footer={footer}
    >
      <div className={styles.content}>
        {scheduleListIsLoading
          ? <>{[...Array(3)].map((_, index) => <SkeletonLayout key={index} height={104} />)}</>

          : scheduleList?.content?.payments.map((item) =>
            <CreditScheduleItem
              key={item.id}
              date={item.date}
              price={`${formatNumberWithSpaces(item.sum)} ₸` ?? ""}
              state={item.state}
            />)}
      </div>
    </NewPageLayout>
  </>
};

export default CreditsSchedule;