import React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Icon, NewList } from 'shared/v12ui';
import { InApp, Toast } from 'shared/ui';
import {
  CameraRequest,
  GalleryRequest,
  selectCameraPermission,
  selectGalleryPermission,
  toggleCameraInApp,
  toggleGalleryInApp,
} from 'shared/native/permissions';
import { setActionForNative, useNativeHandler } from 'shared/lib';
import { NativeActions, useAppDispatch, useAppSelector, addNotification, removeNotification } from 'shared/model';
import { ImageFromNativeItem, selectOpenMediaTransfer, setOpenMediaTransfer } from 'entities/order';
import { useRemoveMediaMutation } from '../api';
import { selectOrderMedia, setOrderMedia } from 'entities/order/model/slice/orderSlice';
import styles from './TransferringMedia.module.scss';

interface Props {
  limit: number;
  onRemoveMediaToastClick: (id?: string) => void;
}

const MEDIA_LIMIT = 5;
export default function TransferringMedia({ limit: propLimit, onRemoveMediaToastClick }: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const galleryPermission = useAppSelector(selectGalleryPermission);
  const cameraPermission = useAppSelector(selectCameraPermission);
  const isOpen = useAppSelector(selectOpenMediaTransfer);
  const media = useAppSelector(selectOrderMedia);
  const timeoutListener = React.useRef<NodeJS.Timeout | null>(null);

  const { control } = useForm<{ uploadedMedia: ImageFromNativeItem[] }>({
    defaultValues: {
      uploadedMedia: [],
    },
  });

  const { fields, remove, replace, append, update } = useFieldArray({ control, name: 'uploadedMedia' });

  const [removeMedia] = useRemoveMediaMutation();

  const [isVideo, setIsVideo] = React.useState(false);
  const [limit, setLimit] = React.useState(0);
  const [removedMedia, setRemovedMedia] = React.useState<ImageFromNativeItem[]>([]);

  const onClose = () => dispatch(setOpenMediaTransfer(false));

  const handleCloseMediaUpload = () => {
    onClose();
    dispatch(removeNotification());
    setRemovedMedia([]);
    replace([]);
    setLimit(0);
    setActionForNative(NativeActions.MEDIA_CLEAN);
    if (!!fields.length) {
      fields.forEach((media) => {
        if (media.digest) {
          removeMedia({ type: media.type === 'video' ? 'video' : 'image', digest: media.digest });
        }
      });
    }
  };

  const onRemove = (media: ImageFromNativeItem, index: number) => {
    timeoutListener.current = setTimeout(() => {
      if (!media.digest) {
        setActionForNative(NativeActions.MEDIA_ABORT, { uuid: media.uuid });
      } else {
        removeMedia({ type: media.type === 'video' ? 'video' : 'image', digest: media.digest });
      }
      remove(index);
      setRemovedMedia((prev) => prev.filter((item) => item.uuid !== media.uuid));
      if (timeoutListener.current) {
        clearTimeout(timeoutListener.current);
        timeoutListener.current = null;
      }
    }, 2750);
  };

  const handleRemoveMedia = (media: ImageFromNativeItem, index: number) => {
    setRemovedMedia((prev) => [...prev, { ...media, index }]);
    dispatch(addNotification({ text: 'Файл удалён. Отмена', type: 'remove', id: media.uuid ?? media.digest }));
    setLimit((prev) => prev - 1);

    if (timeoutListener.current === null) {
      onRemove(media, index);
    } else {
      clearTimeout(timeoutListener.current);
      timeoutListener.current = null;
      const objectInProcess = removedMedia[0];
      const removedIndex = fields.findIndex((item) => item.uuid === objectInProcess.uuid);
      remove(removedIndex);
      onRemove(media, index);
      setRemovedMedia((prev) => prev.filter((item) => item.uuid !== objectInProcess.uuid));
    }
  };

  const handleMediaSubmitClick = () => {
    if (timeoutListener.current) {
      clearTimeout(timeoutListener.current);
      timeoutListener.current = null;
      const removeObject = removedMedia[0];

      const newFields = fields.filter((item) => item.uuid !== removeObject.uuid);
      const currentFields = newFields.filter((item) => item.digest !== undefined);

      dispatch(setOrderMedia([...media, ...currentFields]));
    } else {
      const currentFields = fields.filter((item) => item.digest !== undefined);
      dispatch(setOrderMedia([...media, ...currentFields]));
    }
    replace([]);
    setRemovedMedia([]);
    setLimit(0);
    onClose();
    dispatch(removeNotification());
  };

  const handleRemoveToastClick = (id?: string) => {
    if (isOpen) {
      if (timeoutListener.current) {
        clearTimeout(timeoutListener.current);
        timeoutListener.current = null;
      }
      setLimit((prev) => prev + 1);
      const removeObject = removedMedia.find((item) => (item.uuid ?? item.digest) === id);

      if (removeObject) {
        setRemovedMedia((prev) => prev.filter((item) => item !== removeObject));
      }
    } else {
      onRemoveMediaToastClick(id);
    }
  };

  const handleOpenCameraClick = () => {
    setIsVideo(false);
    if (!cameraPermission) {
      return dispatch(toggleCameraInApp(true));
    } else {
      const currentLimit = MEDIA_LIMIT - limit;
      return setActionForNative(NativeActions.OPEN_BACK_CAMERA, {
        limit: currentLimit,
        is_video: false,
        has_video: true,
      });
    }
  };

  const handleOpenVideoClick = () => {
    setIsVideo(true);
    if (!cameraPermission) {
      return dispatch(toggleCameraInApp(true));
    } else {
      const currentLimit = MEDIA_LIMIT - limit;
      return setActionForNative(NativeActions.OPEN_BACK_CAMERA, {
        limit: currentLimit,
        is_video: true,
        has_video: true,
      });
    }
  };

  const handleOpenGalleryClick = () => {
    if (!galleryPermission) {
      return dispatch(toggleGalleryInApp(true));
    } else {
      const currentLimit = MEDIA_LIMIT - limit;
      return setActionForNative(NativeActions.OPEN_GALLERY, { limit: currentLimit, has_video: true });
    }
  };

  const handleUploadAgainClick = (uuid: string) => {
    setActionForNative(NativeActions.MEDIA_RETRY, { uuid });
  };

  const onUpdateMediaProgress = (data: { uuid: string; percent: number }) => {
    const currentIndex = fields.findIndex((item) => item.uuid === data.uuid);
    const currentObject = fields.find((item) => item.uuid === data.uuid);

    if (currentIndex !== -1 && currentObject) {
      update(currentIndex, { ...currentObject, percent: data.percent, isFailed: false });
    }
  };

  const onUpdateMediaFailed = (data: { uuid: string }) => {
    const currentIndex = fields.findIndex((item) => item.uuid === data.uuid);
    const currentObject = fields.find((item) => item.uuid === data.uuid);

    if (currentIndex !== -1 && currentObject) {
      update(currentIndex, { ...currentObject, isFailed: true, percent: 100 });
    }
  };

  const onUpdateMediaUploaded = (data: { uuid: string; digest: string }) => {
    const currentIndex = fields.findIndex((item) => item.uuid === data.uuid);
    const currentObject = fields.find((item) => item.uuid === data.uuid);

    if (currentIndex !== -1 && currentObject) {
      update(currentIndex, { ...currentObject, digest: data.digest, percent: 100, isFailed: false });
    }
  };

  const onUpdateMediaTransfer = (data: ImageFromNativeItem) => {
    setLimit((prev) => prev + 1);
    append({ ...data, isFailed: false, percent: 0 }, { shouldFocus: false });
  };

  useNativeHandler<ImageFromNativeItem>(null, NativeActions.MEDIA_TRANSFER, (data) => {
    if (data) {
      return onUpdateMediaTransfer(data);
    }
  });

  useNativeHandler<{ uuid: string; percent: number }>(null, NativeActions.MEDIA_PROGRESS, (data) => {
    if (data) {
      return onUpdateMediaProgress(data);
    }
  });

  useNativeHandler<{ uuid: string }>(null, NativeActions.MEDIA_FAILED, (data) => {
    if (data) {
      return onUpdateMediaFailed(data);
    }
  });

  useNativeHandler<{ uuid: string; digest: string }>(null, NativeActions.MEDIA_UPLOADED, (data) => {
    if (data) {
      return onUpdateMediaUploaded(data);
    }
  });

  React.useEffect(() => {
    if (isOpen) {
      setLimit(propLimit);
    }
  }, [propLimit, isOpen]);

  return (
    <>
      <InApp
        backdropClose={false}
        headerText={t('features.upload_media.transfer.title')}
        headerSubtext={t('features.upload_media.transfer.count', { currentCount: limit, maxCount: MEDIA_LIMIT })}
        disableDrag
        open={isOpen}
        onClose={handleCloseMediaUpload}
      >
        <div className={styles.inApp_content_wrapper}>
          {!!fields.length && !(fields.length === 1 && removedMedia.length === 1) && (
            <ul className={styles.inApp_media_list}>
              {fields
                .filter((item) => !removedMedia.some((removed) => item.uuid === removed.uuid))
                .map((field, fieldIndex) => {
                  return (
                    <li key={field.id}>
                      <img width={109} height={96} src={`data:image/png;base64,${field.base64}`} alt='' />

                      {typeof field.percent === 'number' && field.percent !== 100 && !field.isFailed && (
                        <div className={styles.media_percent_holder}>{`${field.percent}%`}</div>
                      )}

                      {field.type === 'video' && !field.isFailed && <div className={styles.video_icon} />}

                      {field.isFailed && (
                        <div
                          className={styles.upload_error_holder}
                          onClick={() => handleUploadAgainClick(field.uuid as string)}
                        >
                          <Icon className={styles.media_error}>upload</Icon>
                        </div>
                      )}

                      <button
                        type='button'
                        onClick={() => handleRemoveMedia(field, fieldIndex)}
                        className={styles.remove_media}
                      >
                        delete
                      </button>
                    </li>
                  );
                })}
            </ul>
          )}

          <NewList
            title={t('features.upload_media.transfer.list.make_photo')}
            icon={<Icon>photo</Icon>}
            controlIcon={<Icon>keyboard_arrow_right</Icon>}
            onClick={handleOpenCameraClick}
            disabled={limit === MEDIA_LIMIT}
            className='mgx-4'
          />

          <NewList
            title={t('features.upload_media.transfer.list.record_video')}
            icon={<Icon>camera</Icon>}
            controlIcon={<Icon>keyboard_arrow_right</Icon>}
            onClick={handleOpenVideoClick}
            disabled={limit === MEDIA_LIMIT}
            className='mgx-4'
          />

          <NewList
            title={t('features.upload_media.transfer.list.open_gallery')}
            icon={<Icon>image</Icon>}
            controlIcon={<Icon>keyboard_arrow_right</Icon>}
            onClick={handleOpenGalleryClick}
            disabled={limit === MEDIA_LIMIT}
            className='mgx-4'
          />

          <div className='mgx-4'>
            <Button
              text={t('common.ready')}
              hasSpace
              disabled={!!fields.length && fields.some((field) => field.percent !== 100 || !field.digest)}
              onClick={handleMediaSubmitClick}
            />
          </div>
        </div>
      </InApp>

      <CameraRequest limit={limit} isVideo={isVideo} hasVideo={true} />
      <GalleryRequest limit={limit} hasVideo={true} />
      <Toast bottomPosition={85} onRemoveToastClick={(id) => handleRemoveToastClick(id)} />
    </>
  );
}
