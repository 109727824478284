import React, { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Item } from 'react-photoswipe-gallery';
import { Icon } from 'shared/v12ui';
import { imageUrl, videoPreviewUrl } from 'shared/lib';
import { ImageResizeMode, Nullable } from 'shared/model';
import { ImageWithPreloader } from '../ImageWithPreloader';
import cn from 'classnames';
import styles from './GalleryMediaItem.module.scss';

interface Props {
  digest: string;
  type: string;
  reviewInfo?: {
    createdAt: Nullable<string>;
    price: Nullable<number>;
    rate: Nullable<number>;
  };
  hasOpen?: boolean;
  description?: Nullable<string>;
  size?: number;
  width?: number;
  mediaLength?: number;
}

interface ChildrenMediaFnProps {
  ref: React.MutableRefObject<HTMLElement>;
  open: (e: MouseEvent) => void;
}

export default function GalleryMediaItem({
  digest,
  type,
  hasOpen = true,
  reviewInfo,
  description,
  size,
  width,
  mediaLength,
}: Props) {
  const currentUrl =
    type === 'video'
      ? `${process.env.REACT_APP_UPLOAD_VIDEO_URL}/${digest}`
      : `${process.env.REACT_APP_UPLOAD_URL}/${digest}`;

  return (
    <Item
      original={currentUrl}
      thumbnail={
        type === 'video'
          ? `${process.env.REACT_APP_UPLOAD_VIDEO_URL}/${digest}/preview`
          : imageUrl(digest, 146, 146, ImageResizeMode.CROP)
      }
      width='1600'
      height='1600'
      alt=''
      content={
        <>
          {hasOpen && (
            <ImageWithPreloader reviewInfo={reviewInfo} description={description} src={currentUrl} alt='' type={type} />
          )}
        </>
      }
    >
      {({ ref, open }) => (
        <PreloaderImage
          childTest={{ ref, open }}
          digest={digest}
          type={type}
          hasOpen={hasOpen}
          size={size}
          width={width}
          mediaLength={mediaLength}
        />
      )}
    </Item>
  );
}

function PreloaderImage({
  hasOpen,
  digest,
  type,
  childTest,
  size,
  width,
  mediaLength,
}: {
  hasOpen: boolean;
  digest: string;
  type: string;
  childTest: ChildrenMediaFnProps;
  srsSet?: { width: number; height: number; media: string }[];
  size?: number;
  width?: number;
  mediaLength?: number;
}) {
  const { t } = useTranslation();
  const [loaded, setLoaded] = useState(false);
  const [onError, setOnError] = useState(false);

  return (
    <div className={styles.preload_wrapper} style={{ height: size, width: width ?? size }}>
      {type === 'video' && (
        <div className={styles.video_overlay} onClick={hasOpen ? childTest.open : () => {}}>
          {!onError && <div className={styles.bubble} />}
          {!onError && (
            <Icon size={16} iconColor='#ffffff'>
              play
            </Icon>
          )}
        </div>
      )}
      {onError ? (
        //@ts-ignore
        <div className={styles.error} ref={childTest.ref}>
          <Icon
            className={cn(styles.error_icon, { [styles.small]: width ? width && width < 100 : size && size < 100 })}
          >
            alert_circle
          </Icon>

          <div className={cn(styles.error_text, { [styles.small]: width ? width && width < 100 : size && size < 100 })}>
            <span>{t('common.media_error')}</span>
          </div>
        </div>
      ) : (
        <picture
          style={{ width: `${width ?? size}px`, height: `${size}px` }}
          ref={childTest.ref}
          onClick={hasOpen ? childTest.open : () => {}}
          className={`${styles.preview_image} ${!loaded ? styles.loaded : ''}`}
        >
          <img
            alt=''
            onLoad={() => setLoaded(true)}
            onError={() => setOnError(true)}
            className={type === 'video' ? styles.video : styles.image}
            loading='lazy'
            src={
              type === 'video'
                ? videoPreviewUrl(digest, size && size * 2, width ? width * 2 : size && size * 2, ImageResizeMode.CROP)
                : imageUrl(digest, size && size * 2, width ? width * 2 : size && size * 2, ImageResizeMode.CROP)
            }
          />
          {mediaLength && (
            <div onClick={childTest.open} className={styles.last_item_count}>{`+ ${mediaLength - 3}`}</div>
          )}
        </picture>
      )}
    </div>
  );
}
