import React from "react";
import cn from "classnames";
import styles from "./InApp.module.scss";
import Sheet from "react-modal-sheet";


interface Props {
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  contentDrag?: boolean;
  headerText?: string;
  headerSubtext?: string | React.ReactNode;
  backdropClose?: boolean;
  image?: string;
  disableDrag?: boolean;
}

const IMG_SIZE = 160;
const NO_TOUCH_ACTION_STYLE = "no_touch_action";

function InApp({
  open,
  backdropClose = true,
  onClose,
  children,
  headerText,
  headerSubtext,
  image,
  contentDrag,
  disableDrag = false,
}: Props) {
  const handleOnClose = () => {
    document.body.classList.remove(NO_TOUCH_ACTION_STYLE);
    onClose();
  }

  const handleBackdropClick = () => {
    if (backdropClose) {
      handleOnClose();
    }
  }

  React.useEffect(() => {
    if (open) {
      document.body.classList.add(NO_TOUCH_ACTION_STYLE);
    } else {
      document.body.classList.remove(NO_TOUCH_ACTION_STYLE);
    }
  }, [open]);

  return <Sheet
    detent="content-height"
    prefersReducedMotion={false}
    disableScrollLocking
    disableDrag={disableDrag}
    isOpen={open}
    onClose={handleOnClose}
  >
    <Sheet.Container>
      {!image && <Sheet.Header className={styles.sheet_header}>
        <div className={cn(styles.text_holder, { [styles.has_subtext]: !!headerSubtext })}>
          {headerText && <span className={styles.header_text}>{headerText}</span>}
          {headerSubtext && <span className={styles.header_subtext}>{headerSubtext}</span>}
        </div>
      </Sheet.Header>}

      <div className={styles.close_container} onClick={handleOnClose}>
        <div className={styles.inApp_header_close}>close</div>
      </div>

      <Sheet.Content className={styles.inApp_content} disableDrag={contentDrag}>
        {!image ? children : <div className={styles.image_type_wrapper}>
          <div className={styles.close_container} onClick={handleOnClose}>
            <div className={styles.inApp_header_close}>close</div>
          </div>

          <div className={styles.image_holder}>
            <img src={image} width={IMG_SIZE} height={IMG_SIZE} alt="" />
          </div>

          <div className={styles.content}>
            <div className={styles.context_text_holder}>
              {headerText && <span className={cn(styles.header_text, { "mgb-3": !!headerSubtext })}>{headerText}</span>}
              {headerSubtext &&
                <>
                  {typeof headerSubtext === "string"
                    ? <span className={styles.header_subtext}>{headerSubtext}</span>
                    : headerSubtext
                  }
                </>
              }
            </div>
            {children}
          </div>
        </div>}
      </Sheet.Content>
    </Sheet.Container>
    <Sheet.Backdrop onTap={handleBackdropClick} />
  </Sheet>
}

export default InApp;
