import {api, BaseResponse, RequestMethod} from "shared/api/api";


const creditCartInitApi = api.injectEndpoints({
  endpoints: (build) => ({
    creditCartInit: build.mutation<BaseResponse<{ payment_id: string }>, { id: number, type: string }>({
      query: ({id, type}) => ({
        url:    "pro/balance/credit/cart/init",
        method: RequestMethod.PUT,
        body:   {credit_id: id, type},
      }),
    }),
  }),
});

export const {useCreditCartInitMutation} = creditCartInitApi;