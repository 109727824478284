import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseResponse } from 'shared/api';
import { Button, NewButtonColor, NewButtonType } from 'shared/v12ui';
import { InApp } from 'shared/ui';
import { NativeActions, Nullable, useAppDispatch, useAppSelector } from 'shared/model';
import { setActionForNative, useNativeHandler, useToggleState } from 'shared/lib';
import { OrderRecommendationResponse } from '../../api/orderDetailsApi';
import {
  selectSuccessResponse,
  setDescriptionError,
  setMoveToSecondStep,
  setOrderRecommendedPrice,
  setOrderWorkUnit,
  setSuccessResponse,
  updateAnalytics,
  updateStepCount,
} from '../../model/slice/orderSlice';
import styles from './DescriptionInApp.module.scss';

interface Props {
  isEditOrder: boolean;
  recommendations?: BaseResponse<OrderRecommendationResponse>;
  resetRecommendation: () => void;
  onResetSpecialMediaFlag: () => void;
}

export default function DescriptionInApp({
  recommendations,
  isEditOrder,
  resetRecommendation,
  onResetSpecialMediaFlag,
}: Props) {
  const { t } = useTranslation();
  const alert = recommendations?.content?.alert;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const successResponse = useAppSelector(selectSuccessResponse);

  const handleMoveToSecondStep = () => {
    navigate('/order/second-step');
    dispatch(updateStepCount(1));
  };

  const [open, toggleOpen] = useToggleState();

  const handleCloseRecommendation = () => {
    toggleOpen();
    // handleMoveToSecondStep();
  };

  const handleInAppButtonClick = (link: Nullable<string>) => {
    if (link === null) {
      toggleOpen();
    }
    if (link === 'naimi://support/write') {
      toggleOpen();
      setActionForNative(NativeActions.OPEN_SUPPORT);
      return;
    }
  };

  const onInAppButtonWithSuccessResponseClick = () => {
    toggleOpen();
    dispatch(updateStepCount(1));
    dispatch(setSuccessResponse(true));
    navigate('/order/second-step');
    resetRecommendation();
  };

  const handleUpdateDescriptionClick = () => {
    toggleOpen();
    dispatch(setMoveToSecondStep(false));
    setActionForNative(NativeActions.INPUT_FOCUS, { name: 'description' });
    dispatch(setSuccessResponse(true));
    resetRecommendation();
  };

  React.useEffect(() => {
    if (!recommendations) return;
    onResetSpecialMediaFlag();
    if (recommendations.errors) {
      dispatch(setDescriptionError(recommendations.errors.description));
      return;
    }
    if (isEditOrder) return handleMoveToSecondStep();
    if (recommendations.content.alert) {
      if (!successResponse) {
        return toggleOpen();
      }
    }

    if (recommendations.content.ticket?.work_units) {
      dispatch(setOrderWorkUnit(recommendations.content.ticket?.work_units));
    }
    if (recommendations.content.ticket?.recommended_price) {
      dispatch(setOrderRecommendedPrice(recommendations.content.ticket?.recommended_price));
    }

    if (successResponse && !recommendations.errors) {
      return handleMoveToSecondStep();
    }
  }, [recommendations, successResponse]);

  React.useEffect(() => {
    if (recommendations?.analytics) {
      dispatch(updateAnalytics(recommendations.analytics));
    }
  }, [recommendations?.analytics]);

  React.useEffect(() => {
    if (alert === null) {
      handleMoveToSecondStep();
    }
  }, [alert]);

  useNativeHandler(null, NativeActions.BACK_TAP, () => {
    if (!open) return;
    return toggleOpen();
  });

  return (
    <>
      {alert && (
        <InApp
          image={alert?.image ? `/illustrations/${alert.image}.svg` : '/illustrations/product-manual-2.svg'}
          headerText={recommendations?.content?.alert?.title}
          headerSubtext={
            alert?.type ? (
              alert.text
            ) : (
              <div className={styles.inApp_subtext}>
                {recommendations?.content.alert?.text.split('\n').map((item, index) => {
                  return <span key={index}>{item}</span>;
                })}
              </div>
            )
          }
          open={!!alert && open}
          onClose={handleCloseRecommendation}
        >
          {alert?.buttons ? (
            <div className='inApp_buttons'>
              {alert.buttons.map((button, index) => {
                if (index === 0) {
                  return (
                    <Button
                      key={index}
                      buttonType={NewButtonType.WRAPPED}
                      buttonColor={NewButtonColor.GRAY}
                      text={button.title}
                      onClick={() => handleInAppButtonClick(button.link)}
                    />
                  );
                }
                return (
                  <Button
                    hasSpace
                    key={index}
                    text={button.title}
                    onClick={() => handleInAppButtonClick(button.link)}
                  />
                );
              })}
            </div>
          ) : (
            <div className='inApp_buttons'>
              <Button
                buttonType={NewButtonType.WRAPPED}
                buttonColor={NewButtonColor.GRAY}
                text={t('page.order.first_step_description.update')}
                onClick={handleUpdateDescriptionClick}
              />
              <Button
                hasSpace
                text={t('page.order.first_step_description.submit')}
                onClick={onInAppButtonWithSuccessResponseClick}
              />
            </div>
          )}
        </InApp>
      )}
    </>
  );
}
