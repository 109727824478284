import React from "react";
import { useClearHistoryMutation } from "./api/api";
import { MainPageTabType } from "shared/model/types/main/mainPageType";


interface Props {
  children: (onClick: (event: React.MouseEvent, id?: number, tab?: MainPageTabType) => void) => React.ReactNode,
}

export default function ClearSearchHistory({ children }: Props) {

  const [clearHistory] = useClearHistoryMutation();

  const handleClearHistoryClick = (id?: number, tab?: MainPageTabType) => {
    clearHistory({ id, tab });
  }

  return <>
    {children((_e, id, type) => handleClearHistoryClick(id, type))}
  </>
};