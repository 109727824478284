import { useLocation, useNavigate } from 'react-router-dom';
import { handleCategoryPath } from 'pages/categories/model/handleCategoryPath';
import { Icon } from 'shared/v12ui';
import { MainPageTabType } from 'shared/model/types/main/mainPageType';
import { LinkData } from 'shared/native/generateLink/generateNativeLink';
import { useAppDispatch } from 'shared/model';
import { setPathFromMain } from 'shared/model/slices/generals';
import { RecentViewItem } from './model/recentViewItem';
import styles from './PopularTags.module.scss';

interface Props {
  tags?: RecentViewItem[];
  withoutHeightLimit?: boolean;
  type?: MainPageTabType;
  onRemoveClick?: (event: React.MouseEvent<Element, MouseEvent>, id?: number | undefined) => void;
}

export default function PopularTags({ tags, type, onRemoveClick, withoutHeightLimit = false }: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const handleOnCreateOrderClick = () => {
    dispatch(setPathFromMain(location.pathname));
  };

  const handleOnTagClick = (data: LinkData, hasSpecialists: boolean) => {
    if (!type) return;
    handleCategoryPath(type, data, hasSpecialists, navigate, handleOnCreateOrderClick);
  };

  return (
    <ul className={styles.popular_tags_wrapper} style={{ maxHeight: withoutHeightLimit ? '100%' : '' }}>
      {tags?.map((item, index) => {
        const data = { service_id: item.service_id, work_id: item.work_id };

        return (
          <li
            key={item.id ?? item.service_id ?? index}
            className={styles.popular_tag_item}
            onClick={() => handleOnTagClick(data, item.has_specialists)}
          >
            {item.title}

            {onRemoveClick && (
              <Icon onClick={(event) => onRemoveClick(event, item.id)} className={styles.remove_icon}>
                close
              </Icon>
            )}
          </li>
        );
      })}
    </ul>
  );
}
