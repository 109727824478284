import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { imageUrl, setActionForNative, useIntersectionObserver, useNativeHandler } from 'shared/lib';
import { NavigationBar } from 'shared/ui/navigationBar';
import { MainPageTabType } from 'shared/model/types/main/mainPageType';
import { CategoriesListItem } from 'entities/categoryListItem';
import { CategoryListItem } from 'entities/categoryListItem/model/categoryListItem';
import { SkeletonLayout } from 'entities/skeleton';
import { useFetchCategoriesMutation } from './api/categoriesApi';
import { ServiceView } from 'features/main/mainServiceViewed';
import { handleCategoryPath } from './model/handleCategoryPath';
import { NativeActions, useAppDispatch } from 'shared/model';
import { setPathFromMain } from 'shared/model/slices/generals';
import styles from './categoriesPage.module.scss';

interface Props {
  categoryType: MainPageTabType;
}

export default function CategoriesPage({ categoryType }: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { id: paramId } = useParams();
  const id = paramId ? +paramId : null;

  const { measureRef, isIntersecting, observer } = useIntersectionObserver();
  const [getCategories, { data, isLoading }] = useFetchCategoriesMutation();

  const [onLoadImage, setOnLoadImage] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [categories, setCategories] = React.useState<CategoryListItem[]>([]);
  const [headerBlock, setHeaderBlock] = React.useState({
    title: '',
    digest: '',
  });

  const handleOnCreateOrderClick = () => {
    dispatch(setPathFromMain(location.pathname));
  };

  const handleOnBackClick = () => {
    setActionForNative(NativeActions.TERMINATE);
    // switch (categoryType) {
    //   case MainPageTabType.Service:
    //     return navigate('/');
    //   case MainPageTabType.Vacancy:
    //     return navigate('/vacancy');
    //   case MainPageTabType.Ultra:
    //     return navigate('/ultra');
    // }
    setActionForNative(NativeActions.TERMINATE);
    // switch (categoryType) {
    //   case MainPageTabType.Service:
    //     return navigate('/');
    //   case MainPageTabType.Vacancy:
    //     return navigate('/vacancy');
    //   case MainPageTabType.Ultra:
    //     return navigate('/ultra');
    // }
  };

  const handleFetchMore = () => {
    if (!id) return;
    if (!categories.length) return;
    if (data && !data.service.has_more) return;
    const currentPage = page + 1;
    setPage(currentPage);
    getCategories({ page: currentPage, category_id: id, tab: categoryType });
  };

  React.useEffect(() => {
    if (isIntersecting) {
      handleFetchMore();
      observer?.disconnect();
    }
  }, [isIntersecting]);

  React.useEffect(() => {
    if (!id) return;
    getCategories({ page: 1, category_id: id, tab: categoryType });
  }, [id]);

  React.useEffect(() => {
    if (data?.service.items) {
      setCategories((prev) => [...prev, ...data.service.items]);
    }
  }, [data, data?.service.has_more]);

  React.useEffect(() => {
    if (!data) return;
    if (!headerBlock.title) {
      setHeaderBlock({ title: data.service.title, digest: data.service.digest });
    }
  }, [headerBlock, data]);

  React.useEffect(() => {
    document.body.style.setProperty('padding-top', '0px', 'important');
  }, []);

  useNativeHandler(null, NativeActions.BACK_TAP, () => {
    handleOnBackClick();
  });

  React.useEffect(() => {
    document.body.style.setProperty('padding-top', '0px', 'important');
  }, []);

  useNativeHandler(null, NativeActions.BACK_TAP, () => {
    handleOnBackClick();
  });

  return (
    <div className={styles.wrapper}>
      {headerBlock.digest && (
        <>
          {!onLoadImage ? <div className={styles.on_load_image} /> : <div className={styles.preview_background} />}
          <img
            loading='eager'
            onLoad={() => setOnLoadImage(true)}
            src={imageUrl(headerBlock.digest, 165 * 4, 500)}
            alt=''
          />
        </>
      )}

      <NavigationBar title={headerBlock.title} onBackClick={handleOnBackClick} />

      <div className={styles.content}>
        <ul className={styles.list}>
          {isLoading
            ? Array.from({ length: 8 }).map((_, index) => {
                return (
                  <li key={index} className={styles.skeleton_item}>
                    <SkeletonLayout height={21} theme='dark' borderRadius={6} />
                    <SkeletonLayout width={20} height={20} theme='dark' borderRadius={4} />
                  </li>
                );
              })
            : categories?.map((listItem, index, array) => {
                const viewBody = {
                  tab: categoryType,
                  service_id: listItem.data?.service_id,
                  work_id: listItem.data?.work_id,
                  is_search: false,
                  has_specialists: listItem.has_specialists,
                };

                if (array.length === index + 3) {
                  return (
                    <ServiceView key={index} body={viewBody}>
                      {(onClick) => (
                        <CategoriesListItem
                          ref={measureRef}
                          onClick={(event) => {
                            onClick(event);
                            handleCategoryPath(
                              categoryType,
                              listItem.data,
                              listItem.has_specialists,
                              navigate,
                              handleOnCreateOrderClick,
                            );
                          }}
                          listItem={listItem}
                          type={categoryType}
                        />
                      )}
                    </ServiceView>
                  );
                }
                return (
                  <ServiceView key={index} body={viewBody}>
                    {(onClick) => (
                      <CategoriesListItem
                        onClick={(event) => {
                          onClick(event);
                          handleCategoryPath(
                            categoryType,
                            listItem.data,
                            listItem.has_specialists,
                            navigate,
                            handleOnCreateOrderClick,
                          );
                        }}
                        listItem={listItem}
                        type={categoryType}
                      />
                    )}
                  </ServiceView>
                );
              })}
        </ul>
      </div>
    </div>
  );
}
