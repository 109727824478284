import React                           from "react";
import {useTranslation}                from "react-i18next";
import {useNavigate, useParams}        from "react-router-dom";
import {
  CheckboxInput,
  GalleryMediaItem,
  GalleryWrapper,
  InApp,
}                                      from "shared/ui";
import {
  NativeActions,
  Nullable,
  setProfile,
  setProfileIsCashed,
  SpecialistProfilePhotosItem,
  useAppDispatch,
  useAppSelector,
  addNotification,
  AppMode,
  selectGalleryOpen,
  selectLocale,
}                                      from "shared/model";
import {
  onParentClick,
  useIntersectionObserver,
  useNativeHandler,
  useSetHeaders,
  useSkeletonThrottling,
}                                      from "shared/lib";
import {useProfileMediaDeleteMutation} from "shared/api";
import {
  Button,
  Tabs,
  Icon,
  NewButtonColor,
  NewButtonType,
}                                      from "shared/v12ui";
import {NewPageLayout, SystemResponse} from "entities/general";
import {SkeletonLayout, SkeletonTabs}  from "entities/skeleton";
import {fetchPhotos}                   from "./lib/fetchPhotos";
import styles                          from "./ProfileMedia.module.scss";


const i18n_prefix = "page.specialist.profile.media.list."
export default function ProfileMedia() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const {id: paramId} = useParams();
  const specialistId = paramId ? parseInt(paramId) : null;
  const locale = useAppSelector(selectLocale);
  const galleryIsOpen = useAppSelector(selectGalleryOpen);

  const liRef = React.useRef<HTMLLIElement>(null);
  const firstRenderRef = React.useRef(false);
  const checkboxListRefs = React.useRef<HTMLInputElement[]>([]);

  const {isSuccess, appMode} = useSetHeaders();
  const {measureRef, isIntersecting, observer} = useIntersectionObserver();
  const {getTabs, tabs, getMediaList, list, listIsLoading, tabsIsLoading} = fetchPhotos({appMode});
  const [removeMedia, {isSuccess: removeMediaIsSuccess}] = useProfileMediaDeleteMutation();

  const {extendedLoading} = useSkeletonThrottling({isLoading: listIsLoading});

  const [page, setPage] = React.useState(1);
  const [width, setWidth] = React.useState(0);
  const [mediaList, setMediaList] = React.useState<SpecialistProfilePhotosItem[]>([]);
  const [tabId, setTabId] = React.useState<Nullable<number>>(null);
  const [tabCount, setTabCount] = React.useState(14);
  const [hasEdit, setHasEdit] = React.useState(false);
  const [openRemoveInApp, setOpenRemoveInApp] = React.useState(false);
  const [selectedMediaIds, setSelectedMediaIds] = React.useState<number[]>([]);

  const handleSelectMedia = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newIds = new Set(selectedMediaIds);
    if (event.target.checked) {
      newIds.add(+event.target.value);
    } else {
      newIds.delete(+event.target.value);
    }
    setSelectedMediaIds([...newIds]);
  }

  const handleSelectAllMediaClick = () => {
    const ids = mediaList.map((_, index) => index);
    if (!selectedMediaIds.length) {
      setSelectedMediaIds(ids);
    } else {
      setSelectedMediaIds([]);
    }
  }

  const toggleHasEdit = () => {
    setHasEdit(prev => !prev);
    setSelectedMediaIds([]);
  }

  const handleToggleRemoveInApp = () => setOpenRemoveInApp(prev => !prev);

  const handleRemoveMediaClick = () => {
    const photos = mediaList.filter((_, mediaIndex) => selectedMediaIds.includes(mediaIndex)).map((item) => item.digest);
    removeMedia({photos});
  }

  const handleFetchMore = () => {
    if (!mediaList.length) return;
    if (list && !list.has_more) return;
    const currentPage = page + 1;
    setPage(currentPage);
    getMediaList({specialist_id: specialistId, page: currentPage, tab_id: tabId, limit: 30});
  }

  const handleTabClick = (id: Nullable<number>, count: number) => {
    if (tabId === id) return;
    setTabId(id);
    setMediaList([]);
    setPage(1);
    setTabCount(count);
    getMediaList({specialist_id: specialistId, tab_id: id, page: 1, limit: 30});
    if (id !== 1) {
      setHasEdit(false);
    }
  }

  const handleBackClick = () => {
    navigate(`/profile${paramId ? `/${paramId}` : ""}`);
  }

  const handleNavigateToAddFiles = () => navigate("/profile/media/add");

  const handleParentClick = (event: React.MouseEvent<HTMLDivElement>, index: number) => onParentClick(event, index, checkboxListRefs);

  const removeSelectedMediaDescription = locale === "ru"
    ? t(`${i18n_prefix}remove.description.value.keyWithCount`, {count: selectedMediaIds.length})
    : selectedMediaIds.length === 1
      ? t(`${i18n_prefix}remove.description.one`)
      : t(`${i18n_prefix}remove.description.many`, {count: selectedMediaIds.length});

  React.useEffect(() => {
    if (list?.photos) {
      setMediaList(prev => ([...prev, ...list.photos]));
    }
  }, [list?.photos, list?.has_more]);

  React.useEffect(() => {
    if (isSuccess && page === 1) {
      getMediaList({specialist_id: specialistId, page, limit: 30, tab_id: tabId});
    }
  }, [isSuccess, page, tabId]);

  React.useEffect(() => {
    if (width !== 0) return;
    const element = liRef.current;
    if (element && mediaList.length) {
      setWidth(element.offsetWidth);
    }
  }, [mediaList, width]);

  React.useEffect(() => {
    if (isIntersecting) {
      handleFetchMore();
      observer?.disconnect();
    }
  }, [isIntersecting]);

  React.useEffect(() => {
    if (isSuccess) {
      getTabs({specialist_id: specialistId});
    }
  }, [isSuccess, specialistId]);

  React.useEffect(() => {
    if (!firstRenderRef.current && !extendedLoading) {
      firstRenderRef.current = true;
    }
  }, [extendedLoading]);

  React.useEffect(() => {
    if (removeMediaIsSuccess) {
      toggleHasEdit();
      dispatch(addNotification({text: t(`${i18n_prefix}remove.success_remove`)}));
      setMediaList([]);
      getTabs({specialist_id: specialistId});
      getMediaList({specialist_id: specialistId, page: 1, tab_id: tabId, limit: 30});
      handleToggleRemoveInApp();
      dispatch(setProfile(null));
      dispatch(setProfileIsCashed(false));
    }
  }, [removeMediaIsSuccess, specialistId]);

  useNativeHandler(null, NativeActions.BACK_TAP, () => {
    if (galleryIsOpen) return;
    return handleBackClick();
  });

  return <NewPageLayout
    headerTitle={!hasEdit ? t(`${i18n_prefix}header`) : t(`${i18n_prefix}remove.select_media`)}
    footer={!hasEdit ? appMode === AppMode.SPECIALIST ? <>
          <Button
            text={t(`${i18n_prefix}button`)}
            onClick={handleNavigateToAddFiles}
            disabled={!firstRenderRef.current ? extendedLoading : tabsIsLoading}
          />
        </>
        : undefined
      : <div className={styles.edit_footer}>
        <div>
          <Button
            isRounded
            text={!selectedMediaIds.length ? t(`${i18n_prefix}remove.select_all`) : t(`${i18n_prefix}remove.cancel`)}
            onClick={handleSelectAllMediaClick}
          />
        </div>

        <div>
          <Button
            isRounded
            buttonType={NewButtonType.WRAPPED}
            buttonColor={NewButtonColor.ERROR}
            disabled={!selectedMediaIds.length}
            onClick={handleToggleRemoveInApp}
            text={selectedMediaIds.length <= 1
              ? t(`${i18n_prefix}remove.delete.one`)
              : t(`${i18n_prefix}remove.delete.many`, {count: selectedMediaIds.length})}
          />
        </div>
      </div>
    }
    toastPosition={89}
    headerLeftIcon={!hasEdit ?
      <Icon className="text black" onClick={handleBackClick}>keyboard_arrow_left</Icon> : undefined}
    headerRightIcon={(tabId === 1 && appMode === AppMode.SPECIALIST)
      ? <Icon className="text black" onClick={toggleHasEdit}>{hasEdit ? "close" : "validation"}</Icon>
      : undefined}
    isLoading={!firstRenderRef.current ? extendedLoading : tabsIsLoading}
  >
    <>
      {(!firstRenderRef.current ? (extendedLoading && page === 1) : tabsIsLoading)
        ? <SkeletonTabs className="mgb-6" />
        : <Tabs
          list={tabs?.tabs ?? []}
          onSetCurrentTab={(value, count) => handleTabClick(value as Nullable<number>, count as number)}
          currentTab={tabId}
          className={styles.tabs}
        />}

      {!extendedLoading && !mediaList.length && tabId === 1 && appMode === AppMode.SPECIALIST &&
        <SystemResponse
          title={t(`${i18n_prefix}empty.title`)}
          description={t(`${i18n_prefix}empty.description`)}
          icon="image"
        />}

      {!extendedLoading && !mediaList.length && tabId === 2 && <div>
        {appMode === AppMode.CLIENT &&
          <span className={styles.empty_text}>{t(`${i18n_prefix}empty.client_review_media`)}</span>}
        {appMode === AppMode.SPECIALIST &&
          <span className={styles.empty_text}>{t(`${i18n_prefix}empty.specialist_review_media`)}</span>}
      </div>}

      <GalleryWrapper>
        <ul className={styles.wrapper}>
          {(extendedLoading && page === 1)
            ? Array.from({length: tabCount === 0 ? 1 : tabCount}, (_, index) => <li key={index} ref={liRef}>
              <SkeletonLayout borderRadius={8} width="100%" height="100%" containerClass={styles.skeleton_photo} />
            </li>)

            : mediaList?.map((photo, index, array) => {
              if (array.length === index + 1) {
                return <li key={index} ref={measureRef}>
                  <GalleryMediaItem
                    hasOpen={!hasEdit}
                    size={width}
                    digest={photo.digest}
                    type={photo.type}
                    reviewInfo={{createdAt: photo.created_at, rate: photo.rate, price: photo.price}}
                    description={photo.description}
                  />
                  {hasEdit && <div className={styles.media_edit} onClick={(event) => handleParentClick(event, index)}>
                    <CheckboxInput
                      isNew
                      name="media"
                      ref={(element) => {
                        if (element) {
                          checkboxListRefs.current[index] = element
                        }
                      }}
                      id={`${index}`}
                      value={index}
                      checkboxSize="small"
                      checked={selectedMediaIds.includes(index)}
                      onChange={handleSelectMedia}
                    />
                  </div>}
                </li>
              }
              return <li key={index} ref={liRef}>
                <GalleryMediaItem
                  size={width}
                  hasOpen={!hasEdit}
                  digest={photo.digest}
                  type={photo.type}
                  reviewInfo={{createdAt: photo.created_at, rate: photo.rate, price: photo.price}}
                  description={photo.description}
                />
                {hasEdit && <div className={styles.media_edit} onClick={(event) => handleParentClick(event, index)}>
                  <CheckboxInput
                    isNew
                    ref={(element) => {
                      if (element) {
                        checkboxListRefs.current[index] = element
                      }
                    }}
                    name="media"
                    value={index}
                    id={`${index}`}
                    checkboxSize="small"
                    checked={selectedMediaIds.includes(index)}
                    onChange={handleSelectMedia}
                  />
                </div>}
              </li>
            })}
        </ul>
      </GalleryWrapper>
    </>

    <InApp
      open={openRemoveInApp}
      onClose={handleToggleRemoveInApp}
      image="/illustrations/delete-4.svg"
      headerText={selectedMediaIds.length === 1
        ? t(`${i18n_prefix}remove.title.one`)
        : t(`${i18n_prefix}remove.title.many`)}
      headerSubtext={removeSelectedMediaDescription}
    >
      <div className={styles.innApp_buttons}>
        <Button
          text={t("common.cancel")}
          buttonColor={NewButtonColor.GRAY}
          buttonType={NewButtonType.WRAPPED}
          onClick={handleToggleRemoveInApp}
        />

        <Button
          hasSpace
          text={t("common.delete")}
          buttonColor={NewButtonColor.ERROR}
          onClick={handleRemoveMediaClick}
        />
      </div>
    </InApp>
  </NewPageLayout>
};