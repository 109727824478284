import { useTranslation } from 'react-i18next';
import { NewAccordion } from 'shared/ui/newAccordion';
import { HeaderBlock } from 'shared/ui/headerBlock';
import { PopularTags } from 'shared/ui/popularTags';
import { ActionPanel } from 'shared/ui/actionPanel';
import { MainPageTabType } from 'shared/model/types/main/mainPageType';
import { ServiceCatalogue } from 'widgets/serviceCatalogue';
import { PersonalServicesList } from 'widgets/personalServicesList';
import ServicePromoBlock from './ui/promoBlock/ServicePromoBlock';
import AdFoxService from './ui/adfox/adFoxService';
import { setActionForNative } from 'shared/lib';
import { NativeActions } from 'shared/model';
import { ActionPanelItem } from 'shared/ui/actionPanel/model/actionPanelItem';
import { useNavigate } from 'react-router-dom';
import { generateNativeLink } from 'shared/native/generateLink/generateNativeLink';
import useGetMainPageData from 'entities/main/model/useGetMainPageData';

export default function ServicesPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data, isLoading, actionPanelIsLoading } = useGetMainPageData(MainPageTabType.Service);

  const handleOpenGidClick = () => setActionForNative(NativeActions.OPEN_GID);

  const handleOnActionCardClick = (item: ActionPanelItem) => {
    //TODO remove ето если все будет работать
    // if (item.type === PanelItemType.Create_Order) {
    //   dispatch(setPathFromMain(location.pathname));
    // }
    generateNativeLink(item.type, item?.data, navigate);
  };

  return (
    <>
      {data && !data?.ad_disabled && <AdFoxService />}

      <div>
        <ActionPanel
          items={data?.action_panel}
          isLoading={isLoading || actionPanelIsLoading}
          onActionCardClick={(item) => handleOnActionCardClick(item)}
        />
      </div>

      {data?.interest_suggest && (
        <div>
          <HeaderBlock title={t('page.main.interest_suggest')} />
          <PersonalServicesList popularServices={data?.interest_suggest} type={MainPageTabType.Service} isWork />
        </div>
      )}

      {!!data?.recent_views?.length && (
        <div style={{ gridGap: 0 }}>
          <HeaderBlock title={t('page.main.recent_views')} style={{ marginBottom: '.4rem' }} />
          <PopularTags tags={data?.recent_views} type={MainPageTabType.Service} />
        </div>
      )}

      <div>
        <HeaderBlock title={t('page.main.categories_list')} isLoading={isLoading} />
        <ServiceCatalogue services={data?.categories} type={MainPageTabType.Service} isLoading={isLoading} />
      </div>

      {data?.promoblock?.is_show && (
        <div>
          <ServicePromoBlock digest={data.promoblock.digest} />
        </div>
      )}

      <div>
        <HeaderBlock title={t('page.main.faq')} isLoading={isLoading}>
          <HeaderBlock.Button text={t('page.main.faq_show_more')} onClick={handleOpenGidClick} />
        </HeaderBlock>

        <NewAccordion faq={data?.questions} isLoading={isLoading} />
      </div>
    </>
  );
}
