import React from 'react';
import { createPortal } from 'react-dom';
import { LngLat, YMapLocationRequest } from '@yandex/ymaps3-types';
import { CitySelect } from 'features/global/citySelect';
import { Icon } from 'shared/v12ui';
import { AddressesItem } from 'entities/order';
import { yaMapSearch } from './model/yaMapHelpers';
import { customLayer } from './model/yaMapCustomSchemeLayer';
import GeneralMark from './ui/marks/GeneralMark';
import MarkWithNumber from './ui/marks/MarkWithNumber';
import SearchList from './ui/searchList/SearchList';
import {
  YMap,
  YMapDefaultSchemeLayer,
  reactify,
  YMapDefaultFeaturesLayer,
  YMapListener,
  YMapMarker,
  YMapControl,
} from './lib';
import { useAppDispatch } from 'shared/model';
import styles from './YaMap.module.scss';
import { setOrderAddresses } from 'entities/order/model/slice/orderSlice';

interface Props {
  updatedIndex?: string;
  addressName?: string;
  addresses: AddressesItem[];
  currentCity?: { id?: number; name?: string };
  onSetAddressName: (value?: string) => void;
  location: YMapLocationRequest;
  onSetLocation: React.Dispatch<React.SetStateAction<YMapLocationRequest>>;
  onClose: () => void;
}

function YaMap({
  updatedIndex,
  addressName,
  location,
  addresses,
  currentCity,
  onSetAddressName,
  onSetLocation,
  onClose,
}: Props) {
  const dispatch = useAppDispatch();
  const debounceTimeout = React.useRef<NodeJS.Timeout | null>(null);
  const [searchInProcess, setSearchInProcess] = React.useState(false);

  const [suggestValue, setSuggestValue] = React.useState('');
  const [openCitySelect, setOpenCitySelect] = React.useState(false);
  const [openSearch, setOpenSearch] = React.useState(true);

  const handleMapClose = () => {
    const newAddresses = addresses.filter((item) => item.name !== '');
    dispatch(setOrderAddresses(newAddresses));
    onClose();
  };

  const handleToggleCitySelect = () => setOpenCitySelect((prev) => !prev);

  const handleCitySelectClick = () => handleToggleCitySelect();

  return (
    <>
      {createPortal(
        <div className={styles.wrapper}>
          <div className={styles.shadow_holder}>
            <div className={styles.header_content_holder}>
              <button className={styles.close_button} type='button' onClick={handleMapClose}>
                close
              </button>
              <div className={styles.city_select} onClick={handleCitySelectClick}>
                <span className={styles.city_select_text}>{`г. ${currentCity?.name}`}</span>
                <Icon size={16} iconColor='#828291'>
                  keyboard_arrow_down
                </Icon>
              </div>
            </div>
          </div>

          {reactify && (
            <YMap mode='vector' location={reactify.useDefault(location, [location])} copyrights={false}>
              <YMapDefaultFeaturesLayer />
              <YMapDefaultSchemeLayer customization={customLayer} />
              <YMapControl>
                <YMapListener
                  onUpdate={(event) => {
                    if (openSearch) return;
                    setSearchInProcess(true);
                    if (!event.mapInAction) {
                      if (debounceTimeout.current) {
                        clearTimeout(debounceTimeout.current);
                      }
                      debounceTimeout.current = setTimeout(() => {
                        yaMapSearch({ text: `${event.location.center}`, limit: 1 })
                          .then((res) => {
                            setSuggestValue(res[0]?.properties?.name ?? 'Астана');
                            setSearchInProcess(false);
                          })
                          .catch((error) => console.warn('ошибка search', error))
                          .finally(() => {
                            setSearchInProcess(false);
                          });
                      }, 700);
                    }
                  }}
                />
              </YMapControl>

              {addresses.map((address, index) => {
                return (
                  <YMapMarker
                    key={index}
                    coordinates={reactify.useDefault(address.coord as LngLat, [addresses])}
                    draggable={false}
                  >
                    <section
                      className={styles.marker_holder}
                      style={{ opacity: address.uuid === updatedIndex ? 0 : 1 }}
                    >
                      <MarkWithNumber count={index + 1} />
                    </section>
                  </YMapMarker>
                );
              })}

              <div className={styles.centered_mark}>
                {!addresses.length ? (
                  <GeneralMark />
                ) : (
                  <MarkWithNumber
                    count={
                      updatedIndex
                        ? addresses.findIndex((item) => item.uuid === updatedIndex) + 1
                        : addresses.length + 1
                    }
                  />
                )}
              </div>

              <SearchList
                currentCity={currentCity}
                searchInProcess={searchInProcess}
                onSetAddressName={onSetAddressName}
                openSearch={openSearch}
                suggestValue={suggestValue}
                addresses={addresses}
                addressName={addressName}
                updatedIndex={updatedIndex}
                onMapClose={onClose}
                onSetOpenSearch={setOpenSearch}
                onSetSuggestValue={setSuggestValue}
                onSetLocation={onSetLocation}
              />
            </YMap>
          )}

          <CitySelect
            city={currentCity}
            open={openCitySelect}
            onClose={handleToggleCitySelect}
            onSetLocation={onSetLocation}
            setSuggestValue={setSuggestValue}
          />
        </div>,
        document.body,
      )}
    </>
  );
}

export default YaMap;
