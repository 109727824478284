import React                           from "react";
import {createPortal}                  from "react-dom";
import {useTranslation}                from "react-i18next";
import {NewPageLayout, SystemResponse} from "entities/general";
import {Button, Icon, Input, NewList}  from "shared/v12ui";
import {InApp, RadioInput}             from "shared/ui";
import {Nullable, useAppDispatch}      from "shared/model";
import {useDebounceValue}              from "shared/lib";
import {
  useLazyFetchCitiesByRegionListQuery,
  useLazyFetchCountryListQuery,
  useFetchRegionListQuery,
}                                      from "./api";
import styles                          from "./CitySelect.module.scss";
import {setCurrentCity}                from "../../../entities/order";
import {LngLat, YMapLocationRequest}   from "@yandex/ymaps3-types";
import {yaMapSearch}                   from "../../../widgets/yaMap";


interface Props {
  open: boolean;
  onClose: () => void;
  city?: { id?: number, name?: string };
  onSetLocation: React.Dispatch<React.SetStateAction<YMapLocationRequest>>;
  setSuggestValue: (value: string) => void;
}

function CitySelect({city, open, onClose, onSetLocation, setSuggestValue}: Props) {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();

  const [openCitiesList, setOpenCitiesList] = React.useState(false);
  const [openCountryList, setOpenCountryList] = React.useState(false);
  const [currentCity, setCity] = React.useState<{ id?: number, name?: string } | undefined>(undefined);
  const [countryId, setCountryId] = React.useState<Nullable<number>>(null);
  const [query, setQuery] = React.useState("");

  const debouncingValue = useDebounceValue(query, 1000) as string;

  const {data} = useFetchRegionListQuery({id: city?.id});
  const [fetchCities, {data: region}] = useLazyFetchCitiesByRegionListQuery();
  const [fetchCountryList, {data: countryList}] = useLazyFetchCountryListQuery();

  const handleFetchCitiesClick = (id: number) => {
    fetchCities({id}).then((res) => res && setOpenCitiesList(true));
  }

  const handleCloseCitySelectInApp = () => {
    setOpenCitiesList(false);
  }

  const handleCloseCountrySelectInApp = () => setOpenCountryList(false);

  const handleListClick = (id: number, name: string) => setCity({id, name});

  const handleSelectCityClick = (id: number, name: string) => setCity({id, name});

  const handleSetQuery = (event: React.ChangeEvent<HTMLInputElement>) => setQuery(event.target.value);

  const handleFetchCountriesClick = () => {
    fetchCountryList().then((res) => res && setOpenCountryList(true));
  }

  const handleSubmitSelectRegionCityClick = () => {
    setOpenCitiesList(false);
    onConfirmClick();
  }

  const onConfirmClick = () => {
    dispatch(setCurrentCity(currentCity));
    setSuggestValue("");
    yaMapSearch({text: `Казахстан, ${currentCity?.name}`, limit: 1}).then((res) => {
      onSetLocation({center: res[0].geometry?.coordinates as LngLat, zoom: 15});
    }).catch((error) => console.warn("ошибка search", error));
    onClose();
  };

  React.useEffect(() => {
    if (data || city) {
      const selectedCity = data?.regions.find((region) => region.is_selected);
      const currentCountry = data?.country?.id;
      setCity(city ?? selectedCity);
      setCountryId(currentCountry ?? null);
    }
  }, [data, city]);

  React.useEffect(() => {
    if (debouncingValue) {
      fetchCities({query: debouncingValue});
    }
  }, [debouncingValue]);

  if (!open) return null;

  return <>
    {createPortal(<div className={styles.wrapper}>
      <NewPageLayout
        headerTitle={t("features.city_select.title")}
        headerRightIcon={<Icon onClick={onClose} className="text black">close</Icon>}
        headerLeftIcon={<div className={styles.country_select} onClick={handleFetchCountriesClick}>
          <img alt="" src="/images/country_kz_2.svg" />
        </div>}
        footer={<Button onClick={onConfirmClick} text="Готово" />}
        pageHeaderChildren={<Input
          hasSpace={false}
          placeholder={t("features.city_select.search_placeholder")}
          icon="search"
          value={query}
          onChange={handleSetQuery}
        />}
      >
        <div className={styles.content}>
          {debouncingValue
            && !region?.cities.length
            && <SystemResponse
              icon="search"
              title={t("features.city_select.empty_search.title")}
              description={t("features.city_select.empty_search.description")}
            />}
          <ul>
            {debouncingValue
              ? region?.cities.map(({id, name}) => {
                return <li key={id}>
                  <NewList
                    title={name}
                    onClick={() => handleListClick(id, name)}
                    controlIcon={<RadioInput
                      isNew
                      name="city_id"
                      label=""
                      value={id}
                      checked={id === currentCity?.id}
                      onChange={() => handleSelectCityClick(id, name)}
                    />}
                  />
                </li>
              })
              : data?.regions.map(({id, name, region_id}, index) => {
                return <li key={`${id}-${index}`}>
                  <NewList
                    title={name}
                    onClick={() => {
                      if (region_id) {
                        return handleListClick(id, name);
                      }
                      return handleFetchCitiesClick(id);
                    }}
                    controlIcon={!region_id
                      ? <Icon>keyboard_arrow_right</Icon>
                      : <RadioInput
                        isNew
                        name="city_id"
                        label=""
                        value={id}
                        checked={id === currentCity?.id}
                        onChange={() => handleSelectCityClick(id, name)}
                      />}
                  />
                </li>
              })}
          </ul>
          <InApp
            headerText={t("features.city_select.title")}
            open={openCitiesList}
            onClose={handleCloseCitySelectInApp}
          >
            <div className={styles.inApp_content_holder}>
              {region?.cities.map(({id, name}) => {
                return <NewList
                  key={id}
                  title={name}
                  onClick={() => handleListClick(id, name)}
                  controlIcon={<RadioInput
                    isNew
                    label=""
                    name="city_id"
                    value={id}
                    checked={id === currentCity?.id}
                    onChange={() => handleSelectCityClick(id, name)}
                  />}
                />
              })}
              <Button text={t("common.ready")} hasSpace onClick={handleSubmitSelectRegionCityClick} />
            </div>
          </InApp>

          <InApp
            headerText={t("features.city_select.select_country")}
            open={openCountryList}
            onClose={handleCloseCountrySelectInApp}
          >
            <div className={styles.inApp_content_holder}>
              {countryList?.countries.map(({id, name}) => {
                return <NewList
                  key={id}
                  title={name}
                  onClick={() => setCountryId(id)}
                  controlIcon={<RadioInput
                    isNew
                    label=""
                    name="city_id"
                    value={id}
                    checked={id === countryId}
                    onChange={(event) => setCountryId(+event.target.value)}
                  />}
                />
              })}

              <Button text={t("common.ready")} hasSpace onClick={handleCloseCountrySelectInApp} />
            </div>
          </InApp>
        </div>
      </NewPageLayout>
    </div>, document.body)}
  </>
}

export default React.memo(CitySelect);