import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {Nullable}                  from "shared/model";

export enum EEndpointTags {
  SPECIALIST_SERVICES_PRICES = "SpecialistServicesPrices",
  SPECIALIST_WORK_PRICES = "SpecialistWorkPrices",
  MAIN_SEARCH_TABS = "MainSearchTabs",
}

export interface BaseResponse<T, D = undefined> {
  status: boolean,
  content: T,
  errors?: { [key: string]: string },
  message?: string;
  analytics?: { [key: string]: string | number },
  ui?: D
}

export interface SpecialistProfileUi {
  contact: boolean;
  input: boolean;
  is_connected: boolean;
  is_blocked: boolean;
}

export enum RequestMethod {
  PUT = "PUT",
  POST = "POST",
  GET = "GET",
  PATH = "PATH",
  DELETE = "DELETE",
}

export interface ReferenceItem {
  id: number;
  title: string;
}

const bq = fetchBaseQuery({
  baseUrl:        `${process.env.REACT_APP_API_BASE_URL}`,
  prepareHeaders: (headers, {getState}) => {

    const session = (getState() as { queries: { session: Nullable<string> } }).queries.session;
    const version = (getState() as { queries: { appVersion: Nullable<string> } }).queries.appVersion;
    const platform = (getState() as { queries: { appPlatform: Nullable<string> } }).queries.appPlatform;
    const adId = (getState() as { queries: { adId: Nullable<string> } }).queries.adId;
    const appCity = (getState() as { queries: { appCity: Nullable<number> } }).queries.appCity;
    const locale = (getState() as { queries: { locale: string } }).queries.locale;

    if (session) {
      headers.set("Api-Session", `${session}`)
    }
    if (locale) {
      headers.set("App-Local", locale)
    }
    if (version) {
      headers.set("App-Version", version)
    }
    if (platform) {
      headers.set("App-Platform", platform);
    }
    if (adId) {
      headers.set("App-Ad-Id", adId);
    }
    if (appCity) {
      headers.set("App-City", `${appCity}`)
    }
    headers.set("Content-Type", "application/json");

    return headers;
  },
});

export const api = createApi({
  async baseQuery(...args) {
    return bq(...args);
  },
  tagTypes:  Object.entries(EEndpointTags).map(([_key, value]) => value),
  endpoints: () => ({}),
});