export const permissions = {
  camera:      {
    text:    "Доступ к камере",
    subtext: "Чтобы вы могли сделать фото или записать видео, требуется разрешить доступ",
  },
  gallery:     {
    text:    "Доступ к галерее",
    subtext: "Чтобы вы могли загрузить фото и видео из галереи, требуется разрешить доступ",
  },
  geolocation: {
    text:          "Доступ к местоположению",
    subtext:       "Приложение использует ваше местоположение, чтобы определить ваш город и показывать актуальный контент, точно указывать адрес при создании заявки.",
    cancel_button: "Спросить в следующий раз",
  },
}