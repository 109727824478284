import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Input, NewList } from 'shared/v12ui';
import { CheckboxInput, InApp, RadioInput } from 'shared/ui';
import { NativeActions, useAppDispatch, useAppSelector } from 'shared/model';
import { useSetOrderAnalytics } from 'shared/model/analytics';
import { useNativeHandler, useToggleState } from 'shared/lib';
import { selectOrderAnalytics, selectPriceError, setPriceError, updateStepCount } from 'entities/order';
import styles from './SecondStep.module.scss';
import {
  selectOrderIsAppPaymentWay,
  selectOrderPrice,
  selectOrderPriceUnit,
  selectOrderRecommendedPrice,
  selectOrderSecondStepLabels,
  selectOrderWorkUnits,
  setOrderIsAppPaymentWay,
  setOrderPrice,
  setOrderPriceUnit,
} from 'entities/order/model/slice/orderSlice';

export default function OrderSecondStepPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const recommendedPrice = useAppSelector(selectOrderRecommendedPrice);
  const priceUnit = useAppSelector(selectOrderPriceUnit);
  const labels = useAppSelector(selectOrderSecondStepLabels);
  const isAppPaymentWay = useAppSelector(selectOrderIsAppPaymentWay);
  const price = useAppSelector(selectOrderPrice);
  const workUnits = useAppSelector(selectOrderWorkUnits);
  const priceError = useAppSelector(selectPriceError);
  const analytics = useAppSelector(selectOrderAnalytics);

  useSetOrderAnalytics('2', analytics);

  const [selectUnitInAppIsOpen, toggleSelectUnit] = useToggleState();
  const [openPaymentInfoInAppIsOpen, togglePaymentInfo] = useToggleState();

  const handleOnBeforeInput = (event: React.FormEvent<HTMLInputElement> & { data: string }) => {
    if (event.data === '.' || event.data === ',' || event.data === '-') {
      event.preventDefault();
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setOrderPrice(+event.target.value));
    dispatch(setPriceError(''));
  };

  const handlePaymentsInfoButtonClick = () => {
    dispatch(setOrderIsAppPaymentWay(true));
    togglePaymentInfo();
  };

  const handleChangeAppPaymentWay = () => {
    dispatch(setOrderIsAppPaymentWay(!isAppPaymentWay));
  };

  const handleChangePriceUnit = (name: string) => dispatch(setOrderPriceUnit(name));

  const handleBackClick = () => {
    navigate('/order');
    dispatch(updateStepCount(0));
  };

  const headerSubtext = (
    <div className={styles.payment_info_subtext}>
      {Array.from({ length: 4 }).map((_, index) => {
        return <span key={index}>{t(`page.order.payment_info.${index}`)}</span>;
      })}
    </div>
  );

  useNativeHandler(null, NativeActions.BACK_TAP, () => {
    if (openPaymentInfoInAppIsOpen) {
      return togglePaymentInfo();
    }
    if (selectUnitInAppIsOpen) {
      return toggleSelectUnit();
    }
    if (!openPaymentInfoInAppIsOpen && !selectUnitInAppIsOpen) {
      return handleBackClick();
    }
  });

  return (
    <>
      <Input
        type='tel'
        inputMode='numeric'
        placeholder='0'
        dropDownValue={priceUnit}
        onDropDownClick={toggleSelectUnit}
        label={t('page.order.labels.price')}
        leftText={t('page.order.labels.to')}
        onBeforeInput={handleOnBeforeInput}
        name={'price'}
        value={price}
        onChange={handleInputChange}
        errorMessage={priceError}
        subtext={
          recommendedPrice && (
            <div className={styles.price_subtext}>
              <span>{recommendedPrice.work}:</span> <span>{recommendedPrice.price}</span>
            </div>
          )
        }
      >
        <Input.Dropdown />
      </Input>

      <NewList
        hasSubtitleClick
        title={t('page.order.labels.online_pay')}
        subtitle={t('page.order.placeholders.online_payment')}
        labels={labels}
        onClick={handleChangeAppPaymentWay}
        controlIcon={<CheckboxInput isNew checked={isAppPaymentWay ?? false} onChange={handleChangeAppPaymentWay} />}
        onSubtitleClick={(event) => {
          event.stopPropagation();
          togglePaymentInfo();
        }}
      />

      <InApp open={selectUnitInAppIsOpen} onClose={toggleSelectUnit}>
        <div className={styles.units_holder}>
          {workUnits?.map((item) => {
            return (
              <RadioInput
                isNew
                id={`${item.id}`}
                key={`${item.id}`}
                value={item.name}
                label={item.name}
                checked={item.name === priceUnit}
                onChange={() => handleChangePriceUnit(item.name)}
              />
            );
          })}
        </div>
      </InApp>

      <InApp
        open={openPaymentInfoInAppIsOpen}
        onClose={togglePaymentInfo}
        image='/illustrations/payment-with-card-2.svg'
        headerText={t('page.order.labels.online_payment')}
        headerSubtext={headerSubtext}
      >
        <Button hasSpace text={t('page.order.select_online_payment_button')} onClick={handlePaymentsInfoButtonClick} />
      </InApp>
    </>
  );
}
