import {
  AppMode,
  selectAppMode,
  selectAppPlatform,
  selectAppVersion,
  selectIsDemo,
  selectSession,
  selectTicketId,
  setAdId,
  setApiSession,
  setAppCity,
  setAppMode,
  setAppPlatform,
  setAppVersion,
  setEntryPoint,
  setIsDemo,
  setLocale,
  setSdkVersion,
  setTicketId,
  setTicketIsDuplicated,
  useAppDispatch,
  useAppSelector,
} from "shared/model";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { selectWorkId, setSearchQuery, setWorkId } from "../../model/slices/queries";

interface Props {
  isRequired?: boolean;
}

export const useSetHeaders = ({ isRequired = true }: Props = {}) => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const sessionValue = searchParams.get("session");
  const platformValue = searchParams.get("platform");
  const versionValue = searchParams.get("version");
  const localeValue = searchParams.get("locale");
  const appCityValue = searchParams.get("city");
  const adidValue = searchParams.get("adid");
  const sdkValue = searchParams.get("sdk");
  const entryPoint = searchParams.get("entrypoint");
  const appMode = searchParams.get("app_mode");
  const ticketId = searchParams.get("ticket_id");
  const isDemo = searchParams.get("is_demo");
  const workId = searchParams.get("work_id");
  const isDuplicated = searchParams.get("is_duplicated");
  const searchQuery = searchParams.get("query");

  const currentSession = useAppSelector(selectSession);
  const currentAppVersion = useAppSelector(selectAppVersion);
  const currentAppPlatform = useAppSelector(selectAppPlatform);
  const currentAppMode = useAppSelector(selectAppMode);
  const currentTicketId = useAppSelector(selectTicketId);
  const currentIsDemo = useAppSelector(selectIsDemo);
  const currentWorkId = useAppSelector(selectWorkId);

  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    if (!isRequired) return;
    if (adidValue) {
      dispatch(setAdId(adidValue));
    }
    if (entryPoint) {
      dispatch(setEntryPoint(entryPoint));
    }
    if (sdkValue) {
      dispatch(setSdkVersion(sdkValue));
    }
    if (sessionValue) {
      dispatch(setApiSession(sessionValue));
    }
    if (platformValue) {
      dispatch(setAppPlatform(platformValue));
    }
    if (versionValue) {
      dispatch(setAppVersion(versionValue));
    }
    if (localeValue) {
      dispatch(setLocale(localeValue));
    }
    if (appMode) {
      dispatch(setAppMode(appMode as AppMode));
    }
    if (appCityValue) {
      const cityValue = +appCityValue;
      dispatch(setAppCity(cityValue));
    }
    if (ticketId && ticketId !== "") {
      dispatch(setTicketId(+ticketId));
    }
    if (searchQuery && searchQuery !== "") {
      dispatch(setSearchQuery(searchQuery));
    }
    if (isDemo) {
      if (isDemo === "true") {
        dispatch(setIsDemo(true));
      }
      if (isDemo === "false") {
        dispatch(setIsDemo(false));
      }
    }
    if (isDuplicated) {
      if (isDuplicated === "true") {
        dispatch(setTicketIsDuplicated(true));
      }
      if (isDuplicated === "false") {
        dispatch(setTicketIsDuplicated(false));
      }
    }
    if (workId) {
      dispatch(setWorkId(+workId));
    }
  }, [sessionValue, platformValue, versionValue, localeValue, appCityValue, dispatch, adidValue, sdkValue, isRequired, entryPoint, appMode, isDemo, workId, ticketId]);

  useEffect(() => {
    if (currentSession && currentAppPlatform && currentAppVersion) {
      setIsSuccess(true);
    }
  }, [currentSession, currentAppVersion, currentAppPlatform]);

  return {
    isSuccess,
    appMode: currentAppMode,
    entryPoint,
    ticketId: currentTicketId,
    isDemo: currentIsDemo,
    workId: currentWorkId,
  }
}