import React      from "react";
import styles     from "./Icon.module.scss";
import {IconType} from "./model/iconType";
import cn         from "classnames";


interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  size?: number;
  iconColor?: string;
  type?: IconType;
  isRemoveToastIcon?: boolean;
  roundedIconColor?: "default" | "success" | "error" | "black";
}

function Icon({
  type = IconType.DEFAULT,
  roundedIconColor = "default",
  isRemoveToastIcon = false,
  iconColor,
  className,
  size,
  ...rest
}: Props) {
  return <>
    {type === IconType.DEFAULT
      ? <span
        style={{color: iconColor ?? "", fontSize: size ? `${size}px` : ""}}
        className={cn(styles.icon, className)}
        {...rest}
      >
        {rest.children}
    </span>
      : <div className={cn(styles.rounded_wrapper,
        className,
        {[styles.remove_toast]: isRemoveToastIcon},
        {[styles[roundedIconColor]]: roundedIconColor},
      )}>
        <span style={{color: iconColor ?? ""}} className={styles.rounded_icon}>
          {rest.children}
        </span>
      </div>
    }
  </>
}

export default React.memo(Icon);