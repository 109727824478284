import React             from "react";
import * as Sentry       from "@sentry/react";
import {withTranslation} from "react-i18next";
import {SystemResponse}  from "../../entities";
import {Button}          from "../v12ui";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError() {
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error, {extra: errorInfo});
  }

  render() {
    const {t} = this.props;
    const {onClick} = this.props;
    if (this.state.hasError) {
      return <SystemResponse
        title={t("error_boundary.title")}
        description={t("error_boundary.description")}
        icon="alert_triangle"
      >
        <Button text={t("error_boundary.button")} isRounded onClick={onClick} />
      </SystemResponse>;
    }

    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);