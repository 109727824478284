import {BaseResponse, RequestMethod} from "shared/api";
import {api}                         from "shared/api/api";
import {Nullable}                    from "shared/model";


export interface CashLoansDetails {
  details: Nullable<{
    title: string;
    description: string;
    button: {
      title: string;
      subtitle: string;
    };
    options: string[];
  }>;
  analytics: { [key: string]: any };
}

interface CashLoansInitResponse {
  credit_id: Nullable<number>;
  message: string;
  status: boolean;
  analytics: { [key: string]: any };
}

interface CashLoansIssueResponse {
  status: boolean;
  message: string;
}

const cashLoansApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSupportProgramInfo: build.mutation<BaseResponse<CashLoansDetails>, void>({
      query: () => ({
        url:    "pro/balance/credit/support-program/details",
        method: RequestMethod.PUT,
      }),
    }),

    initSupportProgram: build.mutation<BaseResponse<CashLoansInitResponse>, void>({
      query: () => ({
        url:    "pro/balance/credit/support-program/init",
        method: RequestMethod.PUT,
      }),
    }),

    supportProgramIssue: build.mutation<BaseResponse<CashLoansIssueResponse>, { credit_id: number, card_id: number }>({
      query: ({...params}) => ({
        url:    "pro/balance/credit/support-program/issue",
        method: RequestMethod.PUT,
        body:   params,
      }),
    }),
  }),
});

export const {
  useGetSupportProgramInfoMutation,
  useInitSupportProgramMutation,
  useSupportProgramIssueMutation,
} = cashLoansApi;