import {api, BaseResponse, RequestMethod} from "shared/api/api";
import {AppMode, Nullable}                from "shared/model";

interface CreditsList {
  id: number;
  title: string;
  subtitle: string;
  description: string;
  color: string;
  buttons: string[];
}

interface CreditsResponse {
  credits: CreditsList[];
  tabs: {
    id: Nullable<number | string>;
    name: string;
    is_selected: boolean;
  }[];
}

const creditsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCreditsList: build.query<BaseResponse<CreditsResponse>, { id?: number, appMode: AppMode }>({
      query:             ({id, appMode}) => ({
        url:    appMode === AppMode.SPECIALIST ? "pro/balance/credit" : "app/balance/credit",
        method: RequestMethod.PUT,
        body:   {tab_id: id},
      }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {useGetCreditsListQuery} = creditsApi;