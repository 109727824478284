import { NavigateFunction } from 'react-router-dom';
import { MainPageTabType } from 'shared/model/types/main/mainPageType';
import { generateNativeLink, LinkData } from 'shared/native/generateLink/generateNativeLink';
import { PanelItemType } from 'shared/ui/actionPanel/model/actionPanelItem';

export function handleCategoryPath(
  type: MainPageTabType,
  data: LinkData,
  hasSpecialists: boolean,
  navigate?: NavigateFunction,
  onCreateOrderClick?: () => void,
) {
  const getCurrentType = () => {
    switch (type) {
      case MainPageTabType.Service:
        if (!hasSpecialists) {
          onCreateOrderClick && onCreateOrderClick();
          return PanelItemType.Create_Order;
        }
        return PanelItemType.SpecialistsList;
      case MainPageTabType.Vacancy:
        if (!hasSpecialists) {
          return PanelItemType.Create_Vacancy;
        }
        return PanelItemType.SpecialistsList;
      case MainPageTabType.Ultra:
        return PanelItemType.Create_Guarantee;
    }
  };
  return generateNativeLink(getCurrentType(), data, navigate);
}
