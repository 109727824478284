export const search_main = {
  history: 'История',
  clear_button: 'Очистить',
  popular: 'Популярное',
  empty_search_result: {
    title: 'Ничего не найдено',
    description:
      'Попробуйте изменить поисковый запрос или проверьте на наличие опечаток или создайте заявку и опишите своими словами',
  },
  create_button: {
    order: 'Создать заявку',
    vacancy: 'Создать вакансию',
  },
};
