import React  from "react";
import {Icon} from "../../v12ui";
import styles from "./navigationBar.module.scss";

interface Props {
  title?: string;
  onBackClick: () => void;
}

export default function NavigationBar({title, onBackClick}: Props) {
  return <div className={styles.wrapper}>
    <Icon onClick={onBackClick}>keyboard_arrow_left</Icon>
    <div className={styles.title}>{title}</div>
  </div>
};
