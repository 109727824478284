import {api, RequestMethod} from "shared/api/api";
import {MainPageTabType}    from "shared/model/types/main/mainPageType";


export interface LogServiceViewingRequestBody {
  work_id?: number;
  service_id?: number;
  has_specialists?: boolean;
  is_search?: boolean;
  tab: MainPageTabType;
}

const mainServiceViewedApi = api.injectEndpoints({
  endpoints: (build) => ({
    logServiceViewing: build.mutation<boolean, LogServiceViewingRequestBody>({
      query: ({...params}) => ({
        url:    "app/main/service/viewed",
        method: RequestMethod.PUT,
        body:   params,
      }),
    }),
  }),
});

export const {useLogServiceViewingMutation} = mainServiceViewedApi;