import {BaseResponse, RequestMethod} from "shared/api";
import {api}                         from "shared/api/api";
import {AppMode}                     from "shared/model";


interface InvitationInfoResponse {
  bar: {
    current: number;
    total: number;
    title: string;
    description: string;
  };
  cards: {
    title: string;
    description: string;
  }[];
  faqs: {
    answer: string;
    question: string;
  }[];
  promocode: string;
  sharing: string;
}

const referralApi = api.injectEndpoints({
  endpoints: (build) => ({
    fetchInvitationInfo: build.mutation<BaseResponse<InvitationInfoResponse>, { appMode: AppMode }>({
      query: ({appMode}) => ({
        url:    `${appMode === AppMode.SPECIALIST ? "pro" : "app"}/promocode/details`,
        method: RequestMethod.PUT,
      }),
    }),
  }),
});

export const {useFetchInvitationInfoMutation} = referralApi;