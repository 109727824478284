import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { Nullable } from 'shared/model';

interface NotificationItem {
  icon?: string;
  text: string;
  type?: 'remove' | 'demoLogin' | 'default';
  id?: string;
}

interface RefetchMainPagesFlags {
  service: boolean;
  vacancy: boolean;
  ultra: boolean;
}

const initialState: {
  notifications: Nullable<NotificationItem>;
  galleryOpen: boolean;
  currentCity: Nullable<{ id: number; name: string }>;
  pathFromMain: Nullable<string>;
  loginNotificationIsReceived?: boolean;
  refetchComponentMainPages: RefetchMainPagesFlags;
  refetchMainPages: RefetchMainPagesFlags;
} = {
  notifications: null,
  galleryOpen: false,
  currentCity: null,
  pathFromMain: null,
  loginNotificationIsReceived: false,
  refetchComponentMainPages: {
    service: false,
    vacancy: false,
    ultra: false,
  },
  refetchMainPages: {
    service: false,
    vacancy: false,
    ultra: false,
  },
};

const slice = createSlice({
  name: 'generals',
  initialState,
  reducers: {
    addNotification(state, action: PayloadAction<Nullable<NotificationItem>>) {
      state.notifications = action.payload;
    },
    setGalleryOpen(state, action: PayloadAction<boolean>) {
      state.galleryOpen = action.payload;
    },
    removeNotification(state) {
      state.notifications = null;
    },
    setCurrentCity(state, action: PayloadAction<Nullable<{ id: number; name: string }>>) {
      state.currentCity = action.payload;
    },
    setPathFromMain(state, action: PayloadAction<Nullable<string>>) {
      state.pathFromMain = action.payload;
    },
    setLoginNotificationIsReceived(state, action: PayloadAction<boolean>) {
      state.loginNotificationIsReceived = action.payload;
    },
    setRefetchComponentMainPages(state, action: PayloadAction<RefetchMainPagesFlags>) {
      state.refetchComponentMainPages = action.payload;
    },
    setRefetchMainPages(state, action: PayloadAction<RefetchMainPagesFlags>) {
      state.refetchMainPages = action.payload;
    },
  },
});

export const {
  addNotification,
  removeNotification,
  setGalleryOpen,
  setCurrentCity,
  setPathFromMain,
  setLoginNotificationIsReceived,
  setRefetchComponentMainPages,
  setRefetchMainPages,
} = slice.actions;
export default slice.reducer;

export const selectNotificationList = (state: { generals: typeof initialState }) => state.generals.notifications;
export const selectGalleryOpen = (state: { generals: typeof initialState }) => state.generals.galleryOpen;
export const selectCurrentCity = (state: { generals: typeof initialState }) => state.generals.currentCity;
export const selectPathFromMain = (state: { generals: typeof initialState }) => state.generals.pathFromMain;
export const selectLoginNotificationIsReceived = (state: { generals: typeof initialState }) =>
  state.generals.loginNotificationIsReceived;
export const selectRefetchComponentMainPages = (state: { generals: typeof initialState }) =>
  state.generals.refetchComponentMainPages;
export const selectRefetchMainPages = (state: { generals: typeof initialState }) => state.generals.refetchMainPages;
