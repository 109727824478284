import React            from "react";
import {LabelItem}      from "./model/labelItemType";
import {LabelColorType} from "./model/labelColorType";
import cn               from "classnames";
import styles           from "./styles.module.scss";


export interface Props {
  labels: LabelItem[];
  clasNames?: string;
  direction?: "default" | "column";
}

export default function Label({labels, direction = "default", clasNames}: Props) {
  return <div className={cn(styles.wrapper, clasNames)}>
    {labels.map((label, index) => <div
      key={index}
      className={cn(styles.label_wrapper, {[`${styles[label.style as LabelColorType]}`]: LabelColorType})}
    >
      {label.icon && <div className={styles.label_icon}>{label.icon}</div>}

      <span
        className={cn(styles.text, {[`${styles[label.style as LabelColorType]}`]: LabelColorType})}>{label.text ?? label.title}</span>

      {label.rightIcon && <div className={styles.label_icon}>{label.rightIcon}</div>}
    </div>)}
  </div>
}