import { profile } from '../pages/specialist/profile/i18n/ru';
import { client_profile } from 'pages/client/profile/i18n/ru';
import { order } from 'entities/order/i18n/ru';
import { upload_media } from 'features/global/uploadMedia/i18n/ru';
import { permissions } from 'shared/native/permissions/i18n/ru';
import { city_select } from 'features/global/citySelect/i18n/ru';
import { map } from 'widgets/yaMap/i18n/ru';
import { main } from './main/main_ru';
import { search_main } from './search_main/search_main_ru';

const ru = {
  page: {
    registration: {
      header: 'Регистрация специалиста',
      footer: 'Ответы на все вопросы по регистрации и переносу анкеты вы можете найти в',
      egov: {
        header: {
          description: 'Через eGov mobile',
        },
        form: {
          text: 'Укажите ИИН и номер телефона для получения данных из базы eGov mobile. Это помогает бороться с повторными регистрациями заблокированных анкет (мошенников, посредников и т.д.)',
          ssn_label: 'ИИН',
          ssn_placeholder: 'Введите свой ИИН',
          ssn_subtext: '12-значный код на удостоверении',
          phone_label: 'Номер телефона',
        },
        otp: {
          header: 'Введите код из СМС',
          description_chunk_1:
            'Вводя полученный смс код, вы даёте свое согласие на сбор и обработку ваших персональных данных, а также на предоставление информации и получение отчета из кредитного бюро и вы ознакомлены и согласны с',
          description_chunk_2: 'Пользовательским соглашением',
          timer: 'Повторно через {{count}} сек.',
          end_time: 'Запросить снова',
          error_code: 'Неверный код',
        },
      },
      transfer: {
        header: {
          title: 'Перенос анкеты',
          description: 'У меня уже есть анкета в naimi',
        },
        form: {
          ssn_label: 'ИИН',
          ssn_placeholder: 'Введите свой ИИН',
          ssn_subtext: '12-значный код на удостоверении',
        },
        complete: {
          title: 'Анкета перенесена',
          subtitle:
            'Ваша анкета успешно перенесена на новый номер. Для получения оплаты через приложение привяжите банковскую карту заново',
          button: 'Лента заявок',
        },
      },
      non_resident: {
        header: {
          description: 'Для нерезидента',
        },
        text: {
          chunk_1: 'Если у вас нет ИИН получите его в ближайшем ЦОНе. Подробную инструкцию можно посмотреть',
          chunk_2: 'здесь',
        },
      },
      manual_registration: {
        header: {
          description: 'Ручная регистрация',
        },
        text: 'ИИН никто не увидит, по нему система не допускает к повторной регистрации заблокированных специалистов',
      },
      document: {
        header: {
          title: 'Регистрация специалиста',
          description: 'По удостоверению',
          description_non_resident: 'Для нерезидента',
        },
        list: {
          item_1: 'Используйте фото хорошего качества',
          item_2: 'Убедитесь, что четко видно дату рождения, ФИО и ИИН',
          item_3: 'На фото должна быть только лицевая сторона удостоверения',
          non_resident_item_1: 'На фото должен быть только документ вашей страны',
          non_resident_item_2: 'Убедитесь, что вокруг вас хорошее освещение',
          non_resident_item_3: 'Используйте фото хорошего качества',
          non_resident_item_4: 'Документ должен располагаться горизонтально',
        },
      },
      freedom: {
        header: {
          title: 'Создать анкету?',
        },
        form: {
          ssn: 'ИИН',
          birth_day: 'Дата рождения',
          text: 'Вы уже прошли проверку личности при открытии банковской карты Freedom Finance. Если подтвердите правильность ваших данных - анкета будет создана. Ваши ИИН и дата рождения не будут отображаться в анкете',
          buttons: {
            not_me: 'Нет, это не я',
            create: 'Да, создать',
          },
        },
      },
    },
    verification: {
      header: 'Проверка личности',
    },
    specialist: {
      tariffs: {
        header: 'Тариф',
        faq: {
          text: 'Популярные вопросы',
          button: 'Ещё',
        },
        notification: 'Автопродление активировано',
        notification_unfreeze: 'Тариф разморожен',
        info: {
          freeze: {
            title: {
              value: {
                keyWithCount_zero: '',
                keyWithCount_one: 'Заморозить тариф на {{count}} день?',
                keyWithCount_few: 'Заморозить тариф на {{count}} дня?',
                keyWithCount_many: 'Заморозить тариф на {{count}} дней?',
              },
            },
            description: {
              part_1: {
                value: {
                  keyWithCount_zero: '',
                  keyWithCount_one: 'У вас осталось {{count}} день тарифа,',
                  keyWithCount_few: 'У вас осталось {{count}} дня тарифа,',
                  keyWithCount_many: 'У вас осталось {{count}} дней тарифа,',
                },
              },
              part_2: 'они будут заморожены до {{date}}. Разморозить тариф можно в любой удобный момент',
            },
          },
          unfreeze: {
            title: 'Разморозить тариф?',
            description: {
              value: {
                keyWithCount_zero: '',
                keyWithCount_one:
                  'При подтверждении мы активируем ваш тариф на {{count}} день, и вы сможете откликаться на заявки',
                keyWithCount_few:
                  'При подтверждении мы активируем ваш тариф на {{count}} дня, и вы сможете откликаться на заявки',
                keyWithCount_many:
                  'При подтверждении мы активируем ваш тариф на {{count}} дней, и вы сможете откликаться на заявки',
              },
            },
          },
          buttons: {
            freeze: 'Заморозить',
            unfreeze: 'Разморозить',
            pay: 'К оплате {{price}} ₸',
          },
        },
      },
      purchase_catalogue: {
        header: 'Анкета в каталоге',
        footer:
          'Анкета бесплатно размещается в категории после 5 откликов в ней. Если анкета не поднимается в течение 7 дней, она скрывается из каталога',
        notification: 'Анкета будет размещена на 1 месте в ближайшее время',
        delete_catalogue_item: 'Анкета удалена из каталога',
        add_category: {
          title: 'Добавить категорию',
          subtitle: 'Для размещения в каталоге на 1 месте',
          description: '+ {{price}} ₸ / шт',
        },
        delete_category: {
          title: 'Удалить анкету из каталога?',
          subtitle:
            'Ваша анкета будет удалена из выбранной категории. Клиенты не смогут связываться с вами из каталога',
          buttons: {
            cancel: 'Отменить',
            delete: 'Удалить',
          },
        },
      },
      my_category: {
        header: {
          title: 'Мои категории',
          subtitle: 'Добавить анкету в каталог',
        },
        plug: {
          title: 'Выберите категории',
          subtitle: 'Чтобы разместить анкету в каталоге, выберите интересующие вас категории услуг',
          button: 'Выбрать категории',
        },
        footer:
          'Анкета бесплатно размещается в категории после 5 откликов в ней. Если анкета не поднимается в течение недели, она скрывается из каталога',
        button: 'Готово',
      },
      tariff_periods: {
        header: {
          title: 'Купить тариф',
          subtitle: 'Выбрать срок',
        },
        plug: {
          title: 'Выберите категории',
          subtitle: 'Чтобы выбрать подходящий срок тарифа, выберите интересующие вас категории заказов и вакансий',
          button: 'Выбрать категории',
        },
        footer:
          'Ежедневно цена рассчитывается индивидуально для вас, но на тарифе с автопродлением цена остается неизменной, и оплата производится только с банковской карты',
      },
      tariff_options: {
        auto_renewal_prompt: 'Выберите другой срок, который доступен без автопродления',
        buttons: {
          save: 'Сохранить',
          pay: 'Оплатить',
          price: 'К оплате {{price}} ₸',
        },
      },
      credits: {
        header: 'Рассрочки и кредиты',
        plug: {
          title: 'Пусто',
          subtitle: 'У вас нет рассрочек и кредитов',
          button: 'Купить тариф',
        },
        footer: 'Информация по займам в банках не синхронизирована и носит только ознакомительный характер',
      },
      credits_schedule: {
        header: 'График платежей',
        buttons: {
          credit_prepayment: 'Погасить досрочно',
          loan_expired: 'Оплатить',
          price: 'К оплате {{price}}  ₸',
        },
      },
      profile,
    },
    client: {
      profile: client_profile,
    },
    state: {
      auto_renewal: {
        title: 'Автопродление',
        button: 'Понятно',
        list: {
          row_1: 'Автопродление фиксирует цену на момент покупки',
          row_2: 'Цена на тариф без автопродления обновляется ежедневно, с автопродлением экономия до 50%',
          row_3: 'Оплата проходит автоматически с банковской карты, не нужно ничего подключать вручную',
        },
      },
      auto_renewal_cancel: {
        header: 'Отмена автопродления',
        notification: 'Автопродление отключено',
        buttons: {
          cancel: 'Нет, я передумал',
          submit: 'Отменить автопродление',
        },
        confirm_cancel: {
          title: 'Вы точно хотите отменить автопродление?',
          buttons: {
            cancel: 'Оставить автопродление',
            submit: 'Все равно отменить',
          },
        },
      },
    },
    reviews: {
      title: 'Отзывы',
      not_reviews:
        'У специалиста еще нет отзывов по этой услуге, но вы можете ознакомится с отзывами клиентов по другим работам, которые выполнил этот специалист',
      rating: {
        value: {
          keyWithCount_zero: '',
          keyWithCount_one: '{{count}} оценка',
          keyWithCount_few: '{{count}} оценки',
          keyWithCount_many: '{{count}} оценок',
        },
      },
      sort: {
        title: 'Сортировка',
        date_desc: 'От новых к старым',
        date_asc: 'От старых к новым',
        rate_desc: 'От высоких к низким оценкам',
        rate_asc: 'От низких к высоким оценкам',
      },
      not_approved:
        'Отзыв еще не проверен модератором. После проверки тут появится комментарий клиента о выполненной работе.',
      not_approved_label: 'На модерации',
    },
    customers: {
      cash_loans: {
        button: 'Подать заявку',
        loading_screen: {
          check: {
            title: 'Идет проверка',
            description: 'Подождите, идут внутренние проверки сервиса',
          },
          loan_process: {
            title: 'Идет оформление',
            description: 'Подождите, оформление в обработке',
          },
        },
        state: {
          denied: {
            title: 'Отказано в выдаче',
            button: 'Попробовать снова',
          },
          select_card: {
            title: 'Получено одобрение',
            button: 'Выбрать карту',
          },
          success: {
            title: 'Оформлено',
            button: 'Лента заявок',
          },
          error: {
            title: 'Ошибка',
            button: 'Служба поддержки',
          },
          empty_categories: {
            title: 'Выберите категории',
            description: 'Чтобы продолжить, выберите интересующие вас категории услуг',
            button: 'Выбрать категории',
          },
        },
      },
      invitation: {
        title: 'Пригласить друзей',
        copy_button: 'Нажмите, чтобы скопировать',
        submit_button: 'Отправить промокод',
        copy_toast: 'Промокод скопирован',
        bonuses: 'Бонусы по приглашениям',
        questions: 'Популярные вопросы',
      },
    },
    order,
    main,
    search_main,
  },
  component: {
    identity: {
      header: 'Проверка личности',
      list: {
        item_1: 'Убедитесь, что вокруг вас хорошее освещение',
        item_2: 'Снимите очки и другие вещи, что могут закрывать лицо',
        item_3: 'Поместите лицо в область овала',
      },
      list_photo: {
        item_1: 'Сделайте фото, чтобы было хорошо видно ваше лицо',
        item_2: 'Убедитесь, что вокруг вас хорошее освещение',
        item_3: 'Снимите очки, маски и другие предметы, закрывающие части лица',
      },
      text_block:
        'Видео направлено на защиту от посредничества, подмены личности и использования чужих документов при регистрации. Фото, полученное при проверке, будет установлено как аватарка в вашей анкете, вы сможете изменить его в любое время после регистрации',
      confirm_button: 'Пройти видео проверку',
      verification: {
        title: 'Ожидайте проверку',
        transfer_subtitle:
          'Запрос на перенос анкеты на новый номер в обработке. Мы проверим данные, чтобы вы могли работать с нового номера',
        manual_subtitle: 'Проверка личности будет пройдена вручную нашими менеджерами. Время ожидания до 24 часов',
        subtitle:
          'Система не смогла распознать ваше лицо. Проверка личности будет пройдена вручную нашими менеджерами. Время ожидания до 24 часов',
        button: 'Лента заявок',
        freedom: 'Вы будете зарегистрированы после успешного открытия карты. Время ожидания до 24 часов',
      },
    },
    manual: {
      form: {
        name_label: 'Имя',
        name_placeholder: 'Введите свое имя',
        lastname_label: 'Фамилия',
        lastname_placeholder: 'Введите свою фамилию',
        gender_label: 'Пол',
        gender_placeholder: 'Ваш пол',
        birth_date_label: 'Дата рождения',
        birth_date_placeholder: 'дд.мм.гггг',
        ssn_label: 'ИИН',
        ssn_placeholder: 'Введите свой ИИН',
      },
    },
    cropper: {
      header: {
        title: 'Обрезать фото',
      },
      text: 'Убедитесь, что документ в центре выделенной области. Поместите только лицевую сторону документа, где видно ваше лицо',
      scale: 'Масштаб',
      button: 'Обрезать',
    },
  },
  in_app: {
    biometric_error: {
      title: 'Личность не подтверждена',
      title_face: 'Лицо не распознано',
      description: 'Вы не прошли проверку личности. Проверьте освещение и попробуйте еще раз',
      description_face: 'Причины: плохое освещение, плохое качество видео, расположение лица относительно камеры',
      buttons: {
        try_again: 'Попробовать снова',
        manual: 'Пройти ручную регистрацию',
      },
    },
    document_error: {
      title: 'Документ не распознан',
      description_1: 'Причины: плохое освещение, масштабы фото, не видно ключевую информацию',
      description_2: 'Не удается считать данные с документа. Пройдите ручную регистрацию',
      buttons: {
        try_again: 'Попробовать снова',
        manual: 'Ввести вручную',
      },
    },
    ssn_error: {
      title: 'ИИН не найден',
      description: 'Введенный вами ИИН не найден в базе. Возможно вы совершили опечатку, попробуйте еще раз',
      button: 'Попробовать снова',
    },
    birth_day: {
      header: 'Дата рождения',
    },
    gender: {
      header: 'Укажите пол',
      male: 'Мужской',
      female: 'Женский',
    },
    ssn_exist: {
      title: 'Такой ИИН уже есть',
      description: 'Пользователь с таким ИИН уже есть в нашей базе. Вам нужно перенести свою анкету на этот номер',
      button: 'Перенести анкету',
    },
    upload_photo_error: {
      title: 'Не удается загрузить фото',
      description: 'Попробуйте снова загрузить фото',
      button: 'Попробовать снова',
    },
    close_registration: {
      title: 'Прервать регистрацию?',
      description:
        'Если вы сейчас прервете регистрацию, при повторной попытке придется начать все сначала и заполнять данные, где это необходимо',
    },
    close_verification: {
      title: 'Прервать проверку личности?',
      description:
        'Если вы сейчас прервете проверку личности, при повторной попытке придется начать все сначала и заполнять данные, где это необходимо',
    },
    wrong_number: {
      title: 'Неверный номер телефона',
      description:
        'Введенный номер телефона не совпадает с номером, привязанным к вашему ИИН в базе eGov mobile. Укажите номер телефона из eGov mobile или пройдите регистрацию другим способом',
      button: {
        try_again: 'Ввести другой номер телефона',
        cancel: 'Выбрать другой способ',
      },
    },
    ssn_blocked: {
      title: 'Отказано в регистрации',
      description:
        'Регистрация по данному ИИН невозможна. По внутренним проверкам сервиса вам было отказано в регистрации',
      button: 'Понятно',
    },
  },
  elements: {
    image_loading: {
      title: 'Загрузка фотографии',
      description: 'Подождите, мы загружаем вашу фотографию на наш сервер',
    },
    image_load_error: {
      title: 'Не удалось загрузить фото',
      description: 'Попробуйте снова загрузить фото',
      button: 'Попробовать снова',
    },
  },
  state: {
    guest: 'Новичок',
    waiting_payment: 'Оплата в обработке, информация появится в ближайшее время',
    payed: 'Оплачено',
    not_payed: 'Не оплачено',
    pay_error: 'Ошибка оплаты',
  },
  card_buttons: {
    credits_schedule: 'График платежей',
    credit_prepayment: 'Погасить досрочно',
    loan_expired: 'Оплатить',
    edit: 'Изменить',
    freeze: 'Заморозить',
    unfreeze: 'Разморозить',
    partner_site: 'Сайт партнера',
  },
  lost_connection: {
    title: 'Ошибка соединения',
    description: 'Убедитесь, что ваше устройство подключено к интернету',
  },
  features: {
    upload_media,
    permissions,
    city_select,
  },
  widgets: {
    map,
  },
  common: {
    search: 'Поиск',
    select: 'Выбрать',
    exit: 'Выйти',
    continue: 'Продолжить',
    allow: 'Разрешить',
    next: 'Далее',
    cancel: 'Отмена',
    edit: 'Изменить',
    save: 'Сохранить',
    delete: 'Удалить',
    ready: 'Готово',
    close: 'Закрыть',
    interrupt: 'Прервать',
    check_loading: 'Проверяем код',
    how_it_works: 'Как это работает?',
    media_error: 'Файл недоступен',
    toggle: {
      more: 'Ещё',
      collapse: 'Свернуть',
    },
  },
  errors: {
    not_found: 'Ваш запрос не найден, пожалуйста, повторите попытку заново',
    server_error: 'Что-то пошло не так. Попробуйте повторить действие через несколько минут',
  },
  error_boundary: {
    title: 'Что-то пошло не так',
    description: 'Произошла неизвестная ошибка, попробуйте позже',
    button: 'Закрыть',
  },
};

export default ru;
