import React from 'react';
import { useTranslation } from 'react-i18next';
import { InApp } from 'shared/ui';
import { Button, Tabs } from 'shared/v12ui';
import { Nullable, useAppDispatch, useAppSelector } from 'shared/model';
import { DateListType } from './index';
import { filterTimeSlots, formatWeekList } from './model/formatList';
import { getCurrentTime } from './model/getCurrentTime';
import styles from './PickOrderDate.module.scss';
import {
  selectOrderDateTab,
  setOrderDate,
  setOrderDateAndTime,
  setOrderDateTab,
} from 'entities/order/model/slice/orderSlice';

interface Props {
  open: boolean;
  onClose: () => void;
  onUpdateDateList: React.Dispatch<React.SetStateAction<DateListType[]>>;
  dateAndTime?: { date?: string; time?: string };
}

function PickOrderDate({ open, dateAndTime, onClose, onUpdateDateList }: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const dateTab = useAppSelector(selectOrderDateTab);

  const [date, setDate] = React.useState(formatWeekList(t)[0]?.id ?? '');
  const [time, setTime] = React.useState(filterTimeSlots(date)[0]?.id ?? '');

  React.useEffect(() => {
    if (dateAndTime && dateAndTime.date) {
      setDate(dateAndTime?.date as string);
    }
    if (dateAndTime && dateAndTime.time) {
      setTime(dateAndTime?.time as string);
    }
  }, [dateAndTime]);

  const onSetDate = (date?: Nullable<string | number>) => {
    setDate(date as string);
    setTime(filterTimeSlots(date as string)[0].id);
  };

  const onSetTime = (time?: Nullable<string | number>) => {
    setTime(time as string);
  };

  const handleSelectTimeClick = () => {
    dispatch(setOrderDateAndTime({ date, time }));

    const confirmDate = dateAndTime?.date;
    const currentDate = new Date(confirmDate as string);
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');


    const dateString = `${day}.${month}`;

    onUpdateDateList((prev) =>
      prev.map((item) =>
        item.id === 'selectDate'
          ? {
              ...item,
              text: `${dateString} | ${dateAndTime?.time}`,
            }
          : item,
      ),
    );

    onClose();
  };

  const closeSelectDateAndTime = () => {
    const date = getCurrentTime();
    dispatch(setOrderDateTab(date));
    if (dateTab === 'selectDate') {
      dispatch(setOrderDate(getCurrentTime()));
    }
    onClose();
  };

  return (
    <>
      <InApp onClose={closeSelectDateAndTime} open={open} headerText={t('page.order.labels.select_date')}>
        <div className={styles.wrapper}>
          <Tabs
            type='additional'
            header={t('page.order.labels.date')}
            currentTab={date}
            list={formatWeekList(t)}
            onSetCurrentTab={onSetDate}
          />

          <Tabs
            header={t('page.order.labels.time')}
            list={filterTimeSlots(date)}
            currentTab={time}
            onSetCurrentTab={onSetTime}
          />

          <Button hasSpace text={t('common.select')} onClick={handleSelectTimeClick} />
        </div>
      </InApp>
    </>
  );
}

export default PickOrderDate;
