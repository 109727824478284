import React                    from "react";
import {useTranslation}         from "react-i18next";
import {NewPageLayout}          from "entities/general/newPageLayout";
import {SkeletonLayout}         from "entities/skeleton";
import {Button, Icon}           from "shared/v12ui";
import {
  useNativeHandler,
  copyToClipboard,
  setActionForNative,
}                               from "shared/lib";
import {
  NativeActions,
  useAppDispatch,
  addNotification,
  useAppSelector,
  selectAppPlatform,
}                               from "shared/model";
import {useAnalytics}           from "./lib";
import {useFetchInvitationInfo} from "./lib/useFetchInvitationInfo";
import BonusesInfoList          from "./ui/infoList/BonusesInfoList";
import ReferralIndicator        from "./ui/referralIndicator/ReferralIndicator";
import QuestionsList            from "./ui/questionsList/QuestionsList";
import styles                   from "./Referral.module.scss";


const i18n_prefix = "page.customers.invitation."
export default function Referral() {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const platform = useAppSelector(selectAppPlatform)

  const handleShareClick = () => setActionForNative(NativeActions.OPEN_SHARE, {text: invitationInfo?.content.sharing});

  const {invitationInfo, extendedLoading} = useFetchInvitationInfo();
  useAnalytics();

  const handleOnCloseClick = () => setActionForNative(NativeActions.TERMINATE);

  const handlePromoCodeClick = () => {
    const promoCode = invitationInfo?.content.promocode ?? "";
    copyToClipboard(promoCode);
    dispatch(addNotification({text: t(`${i18n_prefix}copy_toast`)}));
  }

  useNativeHandler(null, NativeActions.BACK_TAP, () => {
    if (platform === "android") {
      return handleOnCloseClick();
    }
  });

  return <NewPageLayout
    headerTitle={t(`${i18n_prefix}title`)}
    headerRightIcon={<Icon iconColor="#2B2B2B" onClick={handleOnCloseClick}>close</Icon>}
    toastPosition={89}
    isLoading={extendedLoading}
    footer={<div className={styles.footer_wrapper}>
      {extendedLoading
        ? <SkeletonLayout height={62} />
        : (
          <div className={styles.copy_code_wrapper} onClick={handlePromoCodeClick}>
            <span>{invitationInfo?.content.promocode}</span>
            <span>{t(`${i18n_prefix}copy_button`)}</span>
          </div>
        )}

      {extendedLoading
        ? <SkeletonLayout height={53} borderRadius={50} />
        : <Button text={t(`${i18n_prefix}submit_button`)} onClick={handleShareClick} />}
    </div>}
  >
    <ReferralIndicator loading={extendedLoading} bar={invitationInfo?.content.bar} />

    <BonusesInfoList loading={extendedLoading} cardsList={invitationInfo?.content.cards} />

    <QuestionsList loading={extendedLoading} faqs={invitationInfo?.content.faqs} />
  </NewPageLayout>
};