import {useEffect}                                       from "react";
import {setActionForNative}                              from "shared/lib";
import {NativeActions, useAppSelector, selectEntryPoint} from "shared/model";


export const useAnalytics = () => {
  const entryPoint = useAppSelector(selectEntryPoint);

  useEffect(() => {
    const entry = entryPoint && entryPoint !== "" ? entryPoint : "none";
    setActionForNative(NativeActions.ANALYTICS, {
      event:      "client referral open",
      properties: {
        "entry point": entry,
      },
    });
  }, [entryPoint]);
}