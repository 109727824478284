import React from 'react';
import { imageUrl } from 'shared/lib';
import { Icon } from 'shared/v12ui';
import cn from 'classnames';
import styles from './Stories.module.scss';
import { createPortal } from 'react-dom';

const durationPerStep = 5000;

interface Props {
  items: string[];
  onClose: () => void;
}

export default function Stories({ items, onClose }: Props) {
  const storiesWrapperRef = React.useRef<HTMLUListElement>(null);

  const touchStartX = React.useRef<number | null>(null);
  const touchEndX = React.useRef<number | null>(null);
  const intervalRef = React.useRef<NodeJS.Timeout | null>(null);
  const touchStartY = React.useRef<number | null>(null);
  const touchEndY = React.useRef<number | null>(null);

  const [progress, setProgress] = React.useState<number[]>(Array(items.length).fill(0));
  const [currentStoryIndex, setCurrentStoryIndex] = React.useState(0);
  const [currentProgressIndex, setCurrentProgressIndex] = React.useState(0);
  const [isTouching, setIsTouching] = React.useState(false);
  const [onLoadImage, setOnLoadImage] = React.useState<boolean>(false);

  const clearStates = () => {
    setCurrentStoryIndex(0);
    setCurrentProgressIndex(0);
    setIsTouching(false);
    setProgress(Array(items.length).fill(0));
  };

  const handleNext = () => {
    setProgress((prev) => {
      const updatedProgress = [...prev];
      if (currentProgressIndex < items.length) {
        updatedProgress[currentProgressIndex] = 100;
      }
      return updatedProgress;
    });

    if (currentProgressIndex < items.length - 1) {
      setCurrentProgressIndex((prev) => prev + 1);
    }
  };

  const handlePrev = () => {
    setProgress((prev) => {
      const updatedProgress = [...prev];
      if (currentProgressIndex > 0) {
        updatedProgress[currentProgressIndex] = 0;
        updatedProgress[currentProgressIndex - 1] = 0;
      }
      return updatedProgress;
    });

    if (currentProgressIndex > 0) {
      setCurrentProgressIndex((prev) => prev - 1);
    }
  };

  const handleTouchStart = (event: React.TouchEvent) => {
    if (event.touches[0].pageY) {
      touchStartY.current = event.touches[0].clientY;
    }

    if (event.touches[0].pageX > 20 && event.touches[0].pageX < window.innerWidth - 20) {
      touchStartX.current = event.touches[0].clientX;
      setIsTouching(true);
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    } else {
      event.preventDefault();
    }
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    touchEndX.current = e.touches[0].clientX;
    touchEndY.current = e.touches[0].clientY;
  };

  const handleOnListClick = (event: React.MouseEvent<HTMLUListElement>) => {
    const content = storiesWrapperRef.current?.offsetWidth;
    if (content) {
      if (event.clientX > content / 2) {
        handleNext();
        if (currentProgressIndex + 1 === items.length) return;
        setCurrentStoryIndex((prev) => prev + 1);
      } else {
        handlePrev();
        if (currentStoryIndex === 0) return;
        setCurrentStoryIndex((prev) => prev - 1);
      }
    }
  };

  const handleTouchEnd = () => {
    if (touchStartY.current !== null && touchEndY.current !== null) {
      const diff = touchEndY.current - touchStartY.current;

      if (diff > 100) {
        onClose();
      }
    }

    if (touchStartX.current !== null && touchEndX.current !== null) {
      const diff = touchStartX.current - touchEndX.current;

      if (diff > 50 && currentStoryIndex < items.length - 1) {
        setCurrentStoryIndex((prev) => prev + 1);
        handleNext();
      } else if (diff < -50 && currentStoryIndex > 0) {
        setCurrentStoryIndex((prev) => prev - 1);
        handlePrev();
      }
    }

    touchEndY.current = null;
    touchStartY.current = null;
    touchStartX.current = null;
    touchEndX.current = null;
    setIsTouching(false);
  };

  React.useEffect(() => {
    setProgress(Array(items.length).fill(0));
  }, [items.length]);

  React.useEffect(() => {
    if (!onLoadImage) return;
    if (currentProgressIndex === items.length) {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
      return;
    }

    if (!intervalRef.current) {
      intervalRef.current = setInterval(() => {
        if (!isTouching) {
          setProgress((prev) => {
            const updatedProgress = [...prev];
            if (updatedProgress[currentProgressIndex] < 100) {
              updatedProgress[currentProgressIndex] += 1;
            }
            return updatedProgress;
          });
        }
      }, durationPerStep / 100);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [currentProgressIndex, durationPerStep, items.length, isTouching, onLoadImage]);

  React.useEffect(() => {
    if (progress[currentProgressIndex] >= 100 && currentProgressIndex < items.length) {
      setCurrentProgressIndex((prev) => prev + 1);
      setCurrentStoryIndex((prev) => prev + 1);
    }
    if (currentStoryIndex === items.length - 1 && progress[currentStoryIndex] === 100) {
      onClose();
      clearStates();
    }
  }, [progress, currentProgressIndex, items.length]);

  return (
    <>
      {createPortal(
        <div className={styles.wrapper}>
          <div className={styles.progress_bar_wrapper}>
            <ul className={styles.progress_holder}>
              {Array.from({ length: items.length }).map((_, index) => (
                <li key={index} style={{ '--progress': `${progress[index]}%` } as React.CSSProperties}>
                  <span
                    style={{
                      width: `${progress[index]}%`,
                      height: '100%',
                      display: 'block',
                      backgroundColor: '#FFF',
                      transition: 'width 0.1s linear',
                    }}
                  />
                </li>
              ))}
            </ul>
            <div className={styles.close_button}>
              <Icon className={styles.close_button} onClick={onClose}>
                close
              </Icon>
            </div>
          </div>

          <ul
            ref={storiesWrapperRef}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            onClick={handleOnListClick}
            style={{ transform: `translateX(${-currentStoryIndex * 100}%)` }}
            className={styles.content}
          >
            {items.map((item, index) => (
              <li className={cn(styles.item, { [styles.first_item]: index === 0 })} key={index}>
                <img loading='eager' onLoad={() => setOnLoadImage(true)} src={imageUrl(item)} alt='' />
              </li>
            ))}
          </ul>
        </div>,
        document.body,
      )}
    </>
  );
}
