export function getCurrentDate() {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export function getTomorrowDay() {
  const date = new Date();
  date.setDate(date.getDate() + 1);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export const getCurrentTime = () => {
  const currentDate = new Date();
  const currentDateHours = currentDate.getHours();

  if (currentDateHours < 21) {
    return getCurrentDate();
  } else {
    return getTomorrowDay();
  }
};
