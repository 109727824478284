import React, { forwardRef, LiHTMLAttributes } from "react";
import { Icon } from "shared/v12ui";
import { Label } from "shared/ui";
import { MainPageTabType } from "shared/model/types/main/mainPageType";
import { CategoryListItem } from "./model/categoryListItem";
import cn from "classnames";
import styles from "./categoriesListItem.module.scss";


interface Props extends LiHTMLAttributes<HTMLLIElement> {
  listItem?: CategoryListItem;
  type: MainPageTabType;
}

const CategoriesListItem = forwardRef<HTMLLIElement, Props>(({ listItem, type, ...restProps }: Props, ref) => {
  return <li ref={ref} className={styles.list_item} {...restProps}>
    <div className={styles.item_content}>
      {!!listItem?.labels?.length && <Label labels={listItem.labels} />}

      <span className={styles.item_content_title}>{listItem?.title}</span>

      {listItem?.subtitle && <span className={cn(styles.item_content_subtitle, { [`${styles[type]}`]: type })}>
        {listItem.subtitle}
      </span>}

    </div>
    <Icon>keyboard_arrow_right</Icon>
  </li>
});

export default CategoriesListItem;
