import { api, BaseResponse, EEndpointTags, RequestMethod } from "shared/api/api";
import { Nullable } from "shared/model";
import { NavigationTabItem } from "shared/ui/navigationTabs/model/navigationTabItem";
import { RecentViewItem } from "shared/ui/popularTags/model/recentViewItem";

export interface SearchResultItemList {
  title: string;
  subtitle: Nullable<string>;
  has_specialists: boolean;
  work_id: number;
}

interface MainSearchTabsResponse {
  history: {
    service: RecentViewItem[];
    vacancy: RecentViewItem[];
    ultra: RecentViewItem[];
  };
  popular: {
    service: RecentViewItem[];
    vacancy: RecentViewItem[];
    ultra: RecentViewItem[];
  };
  tabs: NavigationTabItem[];
}

export interface SearchResultItem {
  count: number;
  items: SearchResultItemList[];
}

interface MainSearchResponse {
  result: {
    service: SearchResultItem;
    ultra: SearchResultItem;
    vacancy: SearchResultItem;
  }
}

const mainSearchApi = api.injectEndpoints({
  endpoints: (build) => ({
    fetchMainSearchTabs: build.query<MainSearchTabsResponse | undefined, void>({
      query: () => ({
        url: "app/main/search/tabs",
        method: RequestMethod.PUT,
      }),
      transformResponse: (res: BaseResponse<MainSearchTabsResponse>) => res.status ? res.content : undefined,
      providesTags: [{ type: EEndpointTags.MAIN_SEARCH_TABS, id: "LIST" }]
    }),

    searchServices: build.mutation<MainSearchResponse | undefined, string>({
      query: (query) => ({
        url: "app/main/search",
        method: RequestMethod.PUT,
        body: { query },
      }),
      transformResponse: (response: BaseResponse<MainSearchResponse>) => response.status ? response.content : undefined,
    }),
  }),
});

export const { useFetchMainSearchTabsQuery, useSearchServicesMutation } = mainSearchApi;