import React                  from "react";
import {ServiceMediaItem}     from "shared/ui/serviceMediaItem";
import {ServiceMediaListItem} from "shared/ui/serviceMediaItem/model/serviceMediaListItem";
import {getRandomNumberRange} from "shared/lib";
import {MainPageTabType}      from "shared/model/types/main/mainPageType";
import {SkeletonLayout}       from "entities/skeleton";
import styles                 from "./ServiceCatalogue.module.scss";


interface Props {
  services?: ServiceMediaListItem[];
  type: MainPageTabType;
  isLoading?: boolean;
}

export default function ServiceCatalogue({services, type, isLoading}: Props) {
  return <ul className={styles.services_list}>
    {isLoading ? Array.from({length: 12}).map((_, index) => {
        return <li key={index}>
          <SkeletonLayout borderRadius={8} height={96} />
          <SkeletonLayout
            borderRadius={6}
            height={16}
            theme={"dark"}
            containerClass={"mgt-1"}
            width={getRandomNumberRange(40, 80)}
          />
        </li>
      })
      : services?.map((service) => {
        return <ServiceMediaItem key={service.id} item={service} type={type} />
      })}
  </ul>
};