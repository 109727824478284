export const search_main = {
  history: 'Тарих',
  clear_button: 'Тазарту',
  popular: 'Танымал',
  empty_search_result: {
    title: 'Ештеңе табылмады',
    description:
      'Іздеу сұранысын өзгертіп көріңіз немесе қателерді тексеріңіз, немесе өтінім жасап, өз сөзіңізбен сипаттаңыз',
  },
  create_button: {
    order: 'Өтінім жасау',
    vacancy: 'Вакансия құру',
  },
};
